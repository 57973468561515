import { connect } from 'react-redux';
import Sales from './Sales';
import { getSalesList, approveSaleById, rejectSaleById, getSalesStatus } from '../../ducks/sale';
import { getBankAccountList } from '../../ducks/bankAccount';
import { getTeamList } from '../../ducks/team';

const mapStateToProps = (state) => {
    return {
        session: state.session,
        sale: state.sale,
        bankAccount: state.bankAccount,
        team: state.team,
    };
};

const SalesContainer = connect(mapStateToProps, {
    getSalesList,
    approveSaleById,
    rejectSaleById,
    getSalesStatus,
    getBankAccountList,
    getTeamList,
})(Sales);
export default SalesContainer;
