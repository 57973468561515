import React, { Component } from 'react';
import { Layout, Menu, Badge } from 'antd';
import { connect } from 'react-redux';
import {
    UserAddOutlined,
    LinkOutlined,
    LogoutOutlined,
    PhoneOutlined,
    UsergroupAddOutlined,
    DollarCircleOutlined,
    AccountBookOutlined,
    BankOutlined,
    NotificationOutlined,
    ClockCircleOutlined,
    FormOutlined,
    UnorderedListOutlined,
    QuestionCircleOutlined,
} from '@ant-design/icons';
import { Logo } from './index.style';

import { Utility, Constant } from '../../helpers';
import { sessionExpiredAction } from '../../ducks/session';
const { Content, Sider } = Layout;
class Landing extends Component {
    state = {
        menuItems: [],
        sideMenuBroken: false,
        collapseMenu: false,
    };

    constructor(props) {
        super(props);
        this.menuItems = [
            {
                name: 'Report',
                icon: <AccountBookOutlined />,
                path: 'report',
                permission: [Constant.USER_PERMISSIONS.manage_report],
            },
            {
                name: 'Announcement',
                icon: <NotificationOutlined />,
                path: 'announcement',
                permission: [Constant.USER_PERMISSIONS.manage_announcement],
            },
            {
                name: 'Clock In',
                icon: <ClockCircleOutlined />,
                path: 'clock-in',
                permission: [Constant.USER_PERMISSIONS.manage_user_clock],
            },
            {
                name: 'Bank Mgmt',
                icon: <BankOutlined />,
                path: 'bank',
                permission: [Constant.USER_PERMISSIONS.manage_bank_account],
            },
            {
                name: 'Sales',
                icon: <DollarCircleOutlined />,
                path: 'sale',
                permission: [
                    Constant.USER_PERMISSIONS.manage_sales,
                    Constant.USER_PERMISSIONS.create_sales,
                ],
                notification: this.props.session.permissions.includes(
                    Constant.USER_PERMISSIONS.verify_sale
                ),
            },
            {
                name: 'Ads',
                icon: <UnorderedListOutlined />,
                path: 'ads',
                permission: [
                    Constant.USER_PERMISSIONS.manage_sales,
                    Constant.USER_PERMISSIONS.create_sales,
                ],
                // notification: this.props.session.permissions.includes(
                //     Constant.USER_PERMISSIONS.verify_sale
                // ),
            },
            {
                name: 'Enquiry',
                icon: <QuestionCircleOutlined />,
                path: 'enquiry',
                permission: [
                    Constant.USER_PERMISSIONS.manage_enquiry,
                    Constant.USER_PERMISSIONS.create_enquiry,
                ],
                // notification: this.props.session.permissions.includes(
                //     Constant.USER_PERMISSIONS.verify_sale
                // ),
            },
            {
                name: 'CS Report',
                icon: <FormOutlined />,
                path: 'cs-report',
                permission: [
                    Constant.USER_PERMISSIONS.manage_sales,
                    Constant.USER_PERMISSIONS.create_sales,
                ],
            },
            {
                name: 'Team Mgmt',
                icon: <UsergroupAddOutlined />,
                path: 'team',
                permission: [
                    Constant.USER_PERMISSIONS.manage_team,
                    Constant.USER_PERMISSIONS.view_teams,
                ],
            },
            {
                name: 'Agent Mgmt',
                icon: <UserAddOutlined />,
                path: 'agent',
                permission: [Constant.USER_PERMISSIONS.manage_agent],
            },
            {
                name: 'Member Mgmt',
                icon: <UserAddOutlined />,
                path: 'member',
                permission: [
                    Constant.USER_PERMISSIONS.manage_team_member,
                    Constant.USER_PERMISSIONS.view_members,
                ],
            },
            {
                name: 'Accountant Mgmt',
                icon: <UserAddOutlined />,
                path: 'accountant',
                permission: [Constant.USER_PERMISSIONS.manage_accountant],
            },
            {
                name: 'Ads Manager Mgmt',
                icon: <UserAddOutlined />,
                path: 'ads_manager',
                permission: [Constant.USER_PERMISSIONS.manage_accountant],
            },
            {
                name: 'VIP Link',
                icon: <LinkOutlined />,
                path: 'vip-link',
                permission: [Constant.USER_PERMISSIONS.manage_link],
            },
            {
                name: 'Phone Records',
                icon: <PhoneOutlined />,
                path: 'phone-records',
                permission: [Constant.USER_PERMISSIONS.view_link_numbers],
            },
        ];
    }
    componentDidMount = () => {
        this.getMenuItems();
    };
    getMenuItems = async () => {
        await this.props.getProfile();
        const permissions = this.props.session.permissions;
        await this.props.getSalesStatus();

        const filterMenuItems = this.menuItems.filter((item) => {
            if (item.permission.length === 0) {
                return true;
            }
            return item.permission.some((permission) => permissions.includes(permission));
        });

        this.setState({
            menuItems: filterMenuItems,
        });
    };
    onMenuitemClick = (item) => {
        if (item.key === 'logout') {
            this.props.sessionExpiredAction();
            this.props.history.push('/signin');
            return;
        }
        this.props.history.replace(`/${item.key}`);
        if (this.state.sideMenuBroken) {
            this.setState({ collapseMenu: true });
        }
    };
    render() {
        return (
            <Layout>
                <Sider
                    width={200}
                    collapsed={this.state.collapseMenu}
                    onCollapse={(collapsed) => this.setState({ collapseMenu: collapsed })}
                    collapsedWidth="0"
                    breakpoint="lg"
                    style={{
                        height: '100vh',
                    }}
                    onBreakpoint={(broken) => this.setState({ sideMenuBroken: broken })}
                >
                    <Logo>
                        <img
                            src={process.env.PUBLIC_URL + '/images/logo.png'}
                            alt="logo"
                            width={this.state.sideMenuBroken ? 50 : 100}
                        />
                    </Logo>
                    <Menu
                        theme="dark"
                        mode="inline"
                        selectedKeys={[Utility.getItem('selectedMenu')]}
                        onClick={this.onMenuitemClick}
                    >
                        {this.state.menuItems.map((item) => {
                            let notificationCount = 0;
                            if (Utility.isAccountant()) {
                                notificationCount =
                                    Object.values(this.props.sale.status).length > 0 &&
                                    this.props.sale.status.approved
                                        ? this.props.sale.status.approved.number_of_sales
                                        : 0;
                            } else {
                                notificationCount =
                                    Object.values(this.props.sale.status).length > 0 &&
                                    this.props.sale.status.pending
                                        ? this.props.sale.status.pending.number_of_sales
                                        : 0;
                            }

                            return (
                                <Menu.Item key={item.path} icon={item.icon}>
                                    {item.notification ? (
                                        <span>
                                            {item.name}{' '}
                                            <Badge count={notificationCount} offset={[75, 0]} />
                                        </span>
                                    ) : (
                                        item.name
                                    )}
                                </Menu.Item>
                            );
                        })}
                        <Menu.Item key="logout" icon={<LogoutOutlined />}>
                            Sign Out
                        </Menu.Item>
                    </Menu>
                </Sider>
                <Content
                    style={{
                        overflow: 'auto',
                        backgroundColor: 'white',
                        height: '100vh',
                    }}
                >
                    <div
                        style={{
                            padding: 24,
                            textAlign: 'center',
                        }}
                    >
                        {this.props.children}
                    </div>
                </Content>
            </Layout>
        );
    }
}

export default connect(null, { sessionExpiredAction })(Landing);
