import React, { Component } from 'react';
import { Table, Tag, Space, Button, notification } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import { Constant } from '../../helpers';
import { Container, ButtonContainer, IconButton, NameLabel } from './index.style';

class MemberManagement extends Component {
    state = {
        loading: false,
        data: [],
        pagination: {
            current: 1,
            total: 0,
            showSizeChanger: false,
        },
    };
    constructor(props) {
        super(props);
        this.div = React.createRef();
        this.columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: 'First Name',
                dataIndex: 'firstName',
                key: 'firstName',
                render: (text) => <NameLabel>{text}</NameLabel>,
            },
            {
                title: 'Last Name',
                dataIndex: 'lastName',
                key: 'lastName',
                render: (text) => <NameLabel>{text}</NameLabel>,
            },
            {
                title: 'Phone Number',
                dataIndex: 'phoneNumber',
                key: 'phoneNumber',
            },
            {
                title: 'Team',
                dataIndex: 'team',
                key: 'team',
                render: (text) => <NameLabel>{text ? text.name : '-'}</NameLabel>,
            },
            {
                title: 'Username',
                dataIndex: 'username',
                key: 'username',
                render: (text) => <b>{text}</b>,
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (text) => (
                    <Space size="middle">
                        {text === 'active' ? (
                            <Tag color="#87d068">
                                <b>{text.toUpperCase()}</b>
                            </Tag>
                        ) : (
                            <Tag color="#f50">
                                <b>{text.toUpperCase()}</b>
                            </Tag>
                        )}
                    </Space>
                ),
            },
            {
                title: 'Action',
                key: 'action',
                render: (record) => {
                    return (
                        <Space size="middle">
                            {this.props.session.permissions.includes(
                                Constant.USER_PERMISSIONS.manage_team_member
                            ) && (
                                <IconButton
                                    onClick={() => this.onEditButtonPressed({ id: record.id })}
                                >
                                    <EditOutlined /> Edit
                                </IconButton>
                            )}
                        </Space>
                    );
                },
            },
        ];
    }
    componentDidMount = async () => {
        this.div.current.scrollIntoView({ behavior: 'smooth' });
        this.setState({ loading: true });
        await this.props.getUserList({ user_type: Constant.USER_TYPE.member });
    };
    componentDidUpdate = (prevProps) => {
        if (this.props.user.error && this.props.user.error !== prevProps.user.error) {
            this.openNotificationWithIcon({
                type: 'error',
                title: this.props.user.error.frontend_type,
                message: this.props.user.error.message,
            });
            this.setState({
                isLoading: this.props.user.isLoading,
            });
        }
        if (prevProps.user.list !== this.props.user.list) {
            this.setState({
                data: this.props.user.list,
                loading: false,
                pagination: {
                    ...this.state.pagination,
                    current: this.props.user.pagination.page,
                    total: this.props.user.pagination.row_count,
                },
            });
        }
    };
    openNotificationWithIcon = ({ type, title, message }) => {
        notification[type]({
            message: title,
            description: message,
        });
    };
    onChangeTable = async ({ nextPage }) => {
        this.setState({ loading: true });
        await this.props.getUserList({
            page_number: nextPage,
            user_type: Constant.USER_TYPE.member,
        });
    };
    onChangePasswordPressed = ({ id }) => {
        this.props.history.push(`/member/edit/${id}`, {
            changePassword: true,
        });
    };
    onEditButtonPressed = ({ id }) => {
        this.props.history.push(`/member/edit/${id}`);
    };
    onCreateNewMemberPressed = () => {
        this.props.history.push('/member/create');
    };
    render() {
        return (
            <Container ref={this.div}>
                <ButtonContainer>
                    <Button type="primary" size={'large'} onClick={this.onCreateNewMemberPressed}>
                        <b>CREATE NEW MEMBER</b>
                    </Button>
                </ButtonContainer>
                <Table
                    loading={this.state.loading}
                    rowKey={(record) => record.id}
                    columns={this.columns}
                    dataSource={this.state.data}
                    scroll={{ x: 500 }}
                    pagination={this.state.pagination}
                    onChange={(event) => this.onChangeTable({ nextPage: event.current })}
                />
            </Container>
        );
    }
}

export default MemberManagement;
