import styled from 'styled-components';
export const Container = styled.div`
    flex: 1;
`;
export const FormContainer = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-left: 20%;
    padding-right: 20%;
`;
export const ButtonContainer = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-start;
    padding-bottom: 50px;
`;
export const ButtonsContainer = styled.div`
    flex: 1;
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: space-between;
`;
export const DateContainer = styled.div`
    flex: 1;
    display: flex;
    align-self: flex-start;
    justify-content: center;
    align-items: flex-start;
`;
export const ProfitLabel = styled.p`
    font-size: 18pt;
    font-weight: bold;
    text-align: center;
`;
export const ValueLabel = styled.p`
    font-size: 21pt;
    font-weight: bold;
    text-align: center;
    color: ${(props) => (props.EOD < 0 ? '#f50' : '#87d068')};
`;
export const WarningLabel = styled.p`
    font-size: 12pt;
    font-weight: bold;
    color: #f50;
    text-align: left;
`;
