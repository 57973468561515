import { connect } from 'react-redux';
import AnnouncementManagement from './AnnouncementManagement';
import { getAnnouncementList } from '../../ducks/announcement';

const mapStateToProps = (state) => {
    return {
        session: state.session,
        announcement: state.announcement,
    };
};

const AnnouncementManagementContainer = connect(mapStateToProps, {
    getAnnouncementList,
})(AnnouncementManagement);
export default AnnouncementManagementContainer;
