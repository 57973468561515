import { NetworkError, HttpErrorHandler } from './service';
import { VipAPIService } from '../services';
import Bank from '../models/bank';
import BankAccount from '../models/bankAccount';

export default class BankAccountService {
    static async getBankList({ page_size, page_number }) {
        const [error, response] = await VipAPIService.shared.getBankList({
            page_size,
            page_number,
        });
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return {
                results: response.data.results.map((bank) => new Bank(bank)),
                pagination: response.data.pagination,
            };
        }
    }
    static async getBankAccountList({ page_size, page_number }) {
        const [error, response] = await VipAPIService.shared.getBankAccountList({
            page_size,
            page_number,
        });
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return {
                results: response.data.results.map((bankAccount) => new BankAccount(bankAccount)),
                pagination: response.data.pagination,
            };
        }
    }
    static async deleteBankAccountById({ id }) {
        const [error, response] = await VipAPIService.shared.deleteBankAccountById({
            id,
        });
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return {};
        }
    }
    static async getBankAccountById({ id }) {
        const [error, response] = await VipAPIService.shared.getBankAccountById({
            id,
        });
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return new BankAccount(response.data);
        }
    }
    static async updateBankAccount({
        id,
        bank_id,
        name,
        account_number,
        amount_limit,
        percentage,
    }) {
        const [error, response] = await VipAPIService.shared.updateBankAccount({
            id,
            bank_id,
            name,
            account_number,
            amount_limit,
            percentage,
        });
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return new BankAccount(response.data);
        }
    }
    static async createBankAccount({ bank_id, name, account_number, amount_limit, percentage }) {
        const [error, response] = await VipAPIService.shared.createBankAccount({
            bank_id,
            name,
            account_number,
            amount_limit,
            percentage,
        });
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return new BankAccount(response.data);
        }
    }
}
