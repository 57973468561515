export default class VipLink {
    constructor(vipLink) {
        this.id = vipLink.id;
        this.count = vipLink.count;
        this.name = vipLink.name;
        this.numbers = vipLink.numbers;
        this.message = vipLink.message;
        this.status = vipLink.status;
        this.createdBy = vipLink.created_by_user;
        this.type = vipLink.type;
        this.code = vipLink.code;
    }
}
