import CustomerReportService from '../services/customerReportService';
// Actions
const GET_CUSTOMER_REPORT_LIST_REQUEST = 'vip/customerReport/GET_CUSTOMER_REPORT_LIST_REQUEST';
const GET_CUSTOMER_REPORT_LIST_SUCCESS = 'vip/customerReport/GET_CUSTOMER_REPORT_LIST_SUCCESS';
const GET_CUSTOMER_REPORT_LIST_FAIL = 'vip/customerReport/GET_CUSTOMER_REPORT_LIST_FAIL';
const GET_CUSTOMER_REPORT_REQUEST = 'vip/customerReport/GET_CUSTOMER_REPORT_REQUEST';
const GET_CUSTOMER_REPORT_SUCCESS = 'vip/customerReport/GET_CUSTOMER_REPORT_SUCCESS';
const GET_CUSTOMER_REPORT_FAIL = 'vip/customerReport/GET_CUSTOMER_REPORT_FAIL';
const DELETE_CUSTOMER_REPORT_REQUEST = 'vip/customerReport/DELETE_CUSTOMER_REPORT_REQUEST';
const DELETE_CUSTOMER_REPORT_SUCCESS = 'vip/customerReport/DELETE_CUSTOMER_REPORT_SUCCESS';
const DELETE_CUSTOMER_REPORT_FAIL = 'vip/customerReport/DELETE_CUSTOMER_REPORT_FAIL';
const POST_CREATE_CUSTOMER_REPORT_REQUEST =
    'vip/customerReport/POST_CREATE_CUSTOMER_REPORT_REQUEST';
const POST_CREATE_CUSTOMER_REPORT_SUCCESS =
    'vip/customerReport/POST_CREATE_CUSTOMER_REPORT_SUCCESS';
const POST_CREATE_CUSTOMER_REPORT_FAIL = 'vip/customerReport/POST_CREATE_CUSTOMER_REPORT_FAIL';
const PATCH_EDIT_CUSTOMER_REPORT_REQUEST = 'vip/customerReport/PATCH_EDIT_CUSTOMER_REPORT_REQUEST';
const PATCH_EDIT_CUSTOMER_REPORT_SUCCESS = 'vip/customerReport/PATCH_EDIT_CUSTOMER_REPORT_SUCCESS';
const PATCH_EDIT_CUSTOMER_REPORT_FAIL = 'vip/customerReport/PATCH_EDIT_CUSTOMER_REPORT_FAIL';
const SESSION_TOKEN_EXPIRED = 'vip/session/SESSION_TOKEN_EXPIRED';

// Reducer
const INITIAL_STATE = {
    error: null,
    isLoading: false,
    current: null,
    list: [],
    pagination: null,
};

export default function reducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SESSION_TOKEN_EXPIRED: {
            return INITIAL_STATE;
        }
        case GET_CUSTOMER_REPORT_LIST_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case GET_CUSTOMER_REPORT_LIST_SUCCESS: {
            return {
                ...state,
                list: action.payload.results,
                pagination: action.payload.pagination,
                isLoading: false,
                error: null,
            };
        }
        case GET_CUSTOMER_REPORT_LIST_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case GET_CUSTOMER_REPORT_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case GET_CUSTOMER_REPORT_SUCCESS: {
            return {
                ...state,
                current: action.payload,
                isLoading: false,
                error: null,
            };
        }
        case GET_CUSTOMER_REPORT_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case DELETE_CUSTOMER_REPORT_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case DELETE_CUSTOMER_REPORT_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: null,
            };
        }
        case DELETE_CUSTOMER_REPORT_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case POST_CREATE_CUSTOMER_REPORT_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case POST_CREATE_CUSTOMER_REPORT_SUCCESS: {
            return {
                ...state,
                current: action.payload,
                isLoading: false,
                error: null,
            };
        }
        case POST_CREATE_CUSTOMER_REPORT_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case PATCH_EDIT_CUSTOMER_REPORT_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case PATCH_EDIT_CUSTOMER_REPORT_SUCCESS: {
            return {
                ...state,
                current: action.payload,
                isLoading: false,
                error: null,
            };
        }
        case PATCH_EDIT_CUSTOMER_REPORT_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        default:
            return state;
    }
}

// Action Creators
export function getCustomerReportListRequest() {
    return {
        type: GET_CUSTOMER_REPORT_LIST_REQUEST,
    };
}
export function getCustomerReportListSuccess(payload) {
    return {
        type: GET_CUSTOMER_REPORT_LIST_SUCCESS,
        payload,
    };
}
export function getCustomerReportListFail(error) {
    return {
        type: GET_CUSTOMER_REPORT_LIST_FAIL,
        error,
    };
}
export function getCustomerReportRequest() {
    return {
        type: GET_CUSTOMER_REPORT_REQUEST,
    };
}
export function getCustomerReportSuccess(payload) {
    return {
        type: GET_CUSTOMER_REPORT_SUCCESS,
        payload,
    };
}
export function getCustomerReportFail(error) {
    return {
        type: GET_CUSTOMER_REPORT_FAIL,
        error,
    };
}
export function deleteCustomerReportRequest() {
    return {
        type: DELETE_CUSTOMER_REPORT_REQUEST,
    };
}
export function deleteCustomerReportSuccess() {
    return {
        type: DELETE_CUSTOMER_REPORT_SUCCESS,
    };
}
export function deleteCustomerReportFail(error) {
    return {
        type: DELETE_CUSTOMER_REPORT_FAIL,
        error,
    };
}
export function updateCustomerReportRequest() {
    return {
        type: PATCH_EDIT_CUSTOMER_REPORT_REQUEST,
    };
}
export function updateCustomerReportSuccess(payload) {
    return {
        type: PATCH_EDIT_CUSTOMER_REPORT_SUCCESS,
        payload,
    };
}
export function updateCustomerReportFail(error) {
    return {
        type: PATCH_EDIT_CUSTOMER_REPORT_FAIL,
        error,
    };
}
export function createCustomerReportRequest() {
    return {
        type: POST_CREATE_CUSTOMER_REPORT_REQUEST,
    };
}
export function createCustomerReportSuccess(payload) {
    return {
        type: POST_CREATE_CUSTOMER_REPORT_SUCCESS,
        payload,
    };
}
export function createCustomerReportFail(error) {
    return {
        type: POST_CREATE_CUSTOMER_REPORT_FAIL,
        error,
    };
}
// side effects, only as applicable
// e.g. thunks, epics, etc
export const getCustomerReportList = ({
    page_size,
    page_number,
    team_id,
    member_id,
} = {}) => async (dispatch) => {
    dispatch(getCustomerReportListRequest());
    try {
        const list = await CustomerReportService.getCustomerReportList({
            page_size,
            page_number,
            team_id,
            member_id,
        });
        dispatch(getCustomerReportListSuccess(list));
    } catch (error) {
        dispatch(getCustomerReportListFail(error));
        throw error;
    }
};
export const createCustomerReport = ({ code, step, status, reason }) => async (dispatch) => {
    dispatch(createCustomerReportRequest());
    try {
        const result = await CustomerReportService.createCustomerReport({
            code,
            step,
            status,
            reason,
        });
        dispatch(createCustomerReportSuccess(result));
    } catch (error) {
        dispatch(createCustomerReportFail(error));
        throw error;
    }
};
export const getCustomerReportById = ({ id }) => async (dispatch) => {
    dispatch(getCustomerReportRequest());
    try {
        const result = await CustomerReportService.getCustomerReportById({
            id,
        });
        dispatch(getCustomerReportSuccess(result));
    } catch (error) {
        dispatch(getCustomerReportFail(error));
        throw error;
    }
};
export const updateCustomerReport = ({ id, code, step, status, reason }) => async (dispatch) => {
    dispatch(updateCustomerReportRequest());
    try {
        const result = await CustomerReportService.updateCustomerReport({
            id,
            code,
            step,
            status,
            reason,
        });
        dispatch(updateCustomerReportSuccess(result));
    } catch (error) {
        dispatch(updateCustomerReportFail(error));
        throw error;
    }
};
export const deleteCustomerReportById = ({ id }) => async (dispatch) => {
    dispatch(deleteCustomerReportRequest());
    try {
        await CustomerReportService.deleteCustomerReportById({
            id,
        });
        dispatch(deleteCustomerReportSuccess());
    } catch (error) {
        dispatch(deleteCustomerReportFail(error));
        throw error;
    }
};
