import { connect } from 'react-redux';
import Report from './Report';
import { getSalesList } from '../../ducks/sale';
import { getTeamList } from '../../ducks/team';
import { getUserList } from '../../ducks/user';
import { getBankList } from '../../ducks/bankAccount';

const mapStateToProps = (state) => {
    return {
        session: state.session,
        sale: state.sale,
        team: state.team,
        user: state.user,
        bankAccount: state.bankAccount,
    };
};

const ReportContainer = connect(mapStateToProps, {
    getSalesList,
    getTeamList,
    getUserList,
    getBankList,
})(Report);
export default ReportContainer;
