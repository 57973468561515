import { NetworkError, HttpErrorHandler } from './service';
import { VipAPIService } from '../services';
import Team from '../models/team';

export default class TeamService {
    static async getTeamList({ page_size, page_number }) {
        const [error, response] = await VipAPIService.shared.getTeamList({
            page_size,
            page_number,
        });
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return {
                results: response.data.results.map((vipLink) => new Team(vipLink)),
                pagination: response.data.pagination,
            };
        }
    }
    static async deleteTeamById({ id }) {
        const [error, response] = await VipAPIService.shared.deleteTeamById({
            id,
        });
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return {};
        }
    }
    static async getTeamById({ id }) {
        const [error, response] = await VipAPIService.shared.getTeamById({
            id,
        });
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return new Team(response.data);
        }
    }
    static async updateTeam({ id, name, user_id, commission_percentage }) {
        const [error, response] = await VipAPIService.shared.updateTeam({
            id,
            name,
            user_id,
            commission_percentage,
        });
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return new Team(response.data);
        }
    }
    static async createTeam({ name, user_id, commission_percentage }) {
        const [error, response] = await VipAPIService.shared.createTeam({
            name,
            user_id,
            commission_percentage,
        });
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return new Team(response.data);
        }
    }
}
