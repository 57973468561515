import React, { Component } from 'react';
import { Table, Space, Button, notification, Tag, Tooltip, Select } from 'antd';
import moment from 'moment';
import { EditOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Container, ButtonContainer, IconButton } from './index.style';
import { Utility, Constant } from '../../helpers';

const { Option } = Select;
class Enquiry extends Component {
    state = {
        loading: false,
        data: [],
        teamList: [],
        teamId: 'all',
        filterStatus: Utility.isAccountant() ? Constant.SALES_STATUS.approved : 'all',
        pagination: {
            current: 1,
            total: 0,
            showSizeChanger: false,
        },
    };
    constructor(props) {
        super(props);
        this.div = React.createRef();
        this.columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: 'Enquiry Date',
                dataIndex: 'enquiryDate',
                key: 'enquiryDate',
                render: (text) => <span>{moment(text).format('DD/MM/YYYY')}</span>,
            },
            {
                title: 'Updated At',
                dataIndex: 'updatedAt',
                key: 'updatedAt',
                render: (text) => <span>{moment(text).format('DD/MM/YYYY')}</span>,
            },
            {
                title: 'Team',
                dataIndex: 'team',
                key: 'team',
                render: (text) => <span>{text ? text.name : '-'}</span>,
            },
            {
                title: 'Member',
                dataIndex: 'member',
                key: 'team',
                render: (text) => <span>{text ? text.username : '-'}</span>,
            },
            {
                title: 'Created By',
                key: 'createdBy',
                render: (text) => <span>{text.createdBy.username}</span>,
            },
            {
                title: 'Enquiry Amount',
                dataIndex: 'enquiryAmount',
                key: 'enquiryAmount',
                render: (text) => <b>{Utility.convertNumberToStringCommaNumber(text)}</b>,
            },
            // {
            //     title: 'Status',
            //     dataIndex: 'status',
            //     key: 'status',
            //     render: (text) => {
            //         let color = '#87d068';
            //         if (text === Constant.SALES_STATUS.pending) {
            //             color = 'volcano';
            //         } else if (
            //             text === Constant.SALES_STATUS.rejected ||
            //             text === Constant.SALES_STATUS.accountant_rejected
            //         ) {
            //             color = '#f50';
            //         }
            //         return (
            //             <Space size="middle">
            //                 <Tag color={color}>
            //                     <b>{text.toUpperCase()}</b>
            //                 </Tag>
            //             </Space>
            //         );
            //     },
            // },
            {
                title: 'Action',
                key: 'action',
                render: (record) => {
                    return (
                        <Space size="middle">
                            <IconButton onClick={() => this.onEditButtonPressed({ id: record.id })}>
                                <EditOutlined /> Edit
                            </IconButton>
                            {/* {this.props.session.permissions.includes(
                                Constant.USER_PERMISSIONS.verify_sale
                            ) &&
                                (record.status === Constant.SALES_STATUS.pending ||
                                    (Utility.isAccountant() &&
                                        record.status === Constant.SALES_STATUS.approved)) && (
                                    <div style={{ marginLeft: 50 }}>
                                        <IconButton
                                            onClick={() =>
                                                this.onApproveButtonPressed({ id: record.id })
                                            }
                                            style={{ color: '#87d068' }}
                                        >
                                            <CheckCircleOutlined /> <b>APPROVE</b>
                                        </IconButton>
                                        <IconButton
                                            onClick={() =>
                                                this.onRejectButtonPressed({ id: record.id })
                                            }
                                            style={{ color: '#f50' }}
                                        >
                                            <CloseCircleOutlined /> <b>REJECT</b>
                                        </IconButton>
                                    </div>
                                )} */}
                        </Space>
                    );
                },
            },
        ];
    }
    componentDidMount = async () => {
        this.div.current.scrollIntoView({ behavior: 'smooth' });
        this.setState({ loading: true });
        if (!Utility.isAccountant()) {
            await this.props.getEnquiryList();
            await this.props.getTeamList({ page_size: 50 });
        } else {
            await this.props.getEnquiryList({
                filter_status: Constant.SALES_STATUS.approved,
            });
        }
    };
    componentDidUpdate = (prevProps) => {
        if (this.props.enquiry.error && this.props.enquiry.error !== prevProps.enquiry.error) {
            this.openNotificationWithIcon({
                type: 'error',
                title: this.props.enquiry.error.frontend_type,
                message: this.props.enquiry.error.message,
            });
            this.setState({
                isLoading: this.props.enquiry.isLoading,
            });
        }
        if (this.props.team.error && this.props.team.error !== prevProps.team.error) {
            this.openNotificationWithIcon({
                type: 'error',
                title: this.props.team.error.frontend_type,
                message: this.props.team.error.message,
            });
            this.setState({
                isLoading: this.props.team.isLoading,
            });
        }
        if (prevProps.team.list !== this.props.team.list) {
            this.setState({
                teamList: this.props.team.list,
                loading: false,
            });
        }
        if (prevProps.enquiry.list !== this.props.enquiry.list) {
            this.setState({
                data: this.props.enquiry.list,
                loading: false,
                pagination: {
                    ...this.state.pagination,
                    current: this.props.enquiry.pagination.page,
                    total: this.props.enquiry.pagination.row_count,
                },
            });
        }
    };
    openNotificationWithIcon = ({ type, title, message }) => {
        notification[type]({
            message: title,
            description: message,
        });
    };
    onTeamChanged = async (team) => {
        await this.setState({ teamId: team });
        await this.props.getEnquiryList({ team_ids: [this.state.teamId] });
    };
    onFilterStatusChanged = async (status) => {
        await this.setState({ filterStatus: status });
        await this.props.getEnquiryList({
            team_ids: this.state.teamId !== 'all' ? [this.state.teamId] : undefined,
            page_number: this.state.pagination.current,
            filter_status: this.state.filterStatus,
        });
    };
    onChangeTable = async ({ nextPage }) => {
        this.setState({
            loading: true,
        });
        const payload = {
            team_id: this.state.teamId,
            page_number: nextPage,
            filter_status: this.state.filterStatus,
        };
        await this.props.getEnquiryList(payload);
    };
    onEditButtonPressed = ({ id }) => {
        this.props.history.push(`/enquiry/edit/${id}`);
    };
    onApproveButtonPressed = async ({ id }) => {
        this.setState({ loading: true });
        const payload = {
            team_id: this.state.teamId,
            page_number: this.state.pagination.current,
            filter_status: this.state.filterStatus,
        };
        await this.props.approveAdsById({ id });
        await this.props.getEnquiryList(payload);
    };
    onRejectButtonPressed = async ({ id }) => {
        this.setState({ loading: true });
        const payload = {
            team_id: this.state.teamId,
            page_number: this.state.pagination.current,
        };
        await this.props.rejectAdsById({ id });
        await this.props.getEnquiryList(payload);
    };
    onCreateNewEnquiryPressed = () => {
        this.props.history.push('/enquiry/create');
    };
    render() {
        return (
            <Container ref={this.div}>
                <ButtonContainer>
                    {this.props.session.permissions.includes(
                        Constant.USER_PERMISSIONS.manage_team
                    ) && (
                        <Tooltip placement="left" title="Select a Team">
                            <Select
                                showSearch
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                value={this.state.teamId}
                                size={'large'}
                                style={{ width: 200 }}
                                optionFilterProp="children"
                                placeholder={'Select a Team'}
                                onChange={this.onTeamChanged}
                            >
                                {this.state.teamList.map((team) => {
                                    return (
                                        <Option key={team.id} value={team.id}>
                                            {team.name}
                                        </Option>
                                    );
                                })}
                                <Option key={'all'} value={'all'}>
                                    All
                                </Option>
                            </Select>
                        </Tooltip>
                    )}
                    {/* <Tooltip placement="left" title="Select a Status">
                        <Select
                            showSearch
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            value={this.state.filterStatus}
                            size={'large'}
                            style={{ width: 200 }}
                            optionFilterProp="children"
                            placeholder={'Select a Status'}
                            onChange={this.onFilterStatusChanged}
                        >
                            {!Utility.isAccountant() && (
                                <Option
                                    key={Constant.SALES_STATUS.pending}
                                    value={Constant.SALES_STATUS.pending}
                                >
                                    Pending
                                </Option>
                            )}
                            <Option
                                key={Constant.SALES_STATUS.approved}
                                value={Constant.SALES_STATUS.approved}
                            >
                                Approved
                            </Option>
                            <Option
                                key={Constant.SALES_STATUS.rejected}
                                value={Constant.SALES_STATUS.rejected}
                            >
                                Rejected
                            </Option>
                            <Option
                                key={Constant.SALES_STATUS.accountant_approved}
                                value={Constant.SALES_STATUS.accountant_approved}
                            >
                                Accountant Approved
                            </Option>
                            <Option
                                key={Constant.SALES_STATUS.accountant_rejected}
                                value={Constant.SALES_STATUS.accountant_rejected}
                            >
                                Accountant Rejected
                            </Option>
                            {!Utility.isAccountant() && (
                                <Option key={'all'} value={'all'}>
                                    All
                                </Option>
                            )}
                        </Select>
                    </Tooltip> */}
                    <Button type="primary" size={'large'} onClick={this.onCreateNewEnquiryPressed}>
                        <b>CREATE NEW ENQUIRY</b>
                    </Button>
                </ButtonContainer>
                <Table
                    loading={this.state.loading}
                    rowKey={(record) => record.id}
                    columns={this.columns}
                    dataSource={this.state.data}
                    scroll={{ x: 500 }}
                    pagination={this.state.pagination}
                    onChange={(event) => this.onChangeTable({ nextPage: event.current })}
                />
            </Container>
        );
    }
}

export default Enquiry;
