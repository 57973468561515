import styled from 'styled-components';
export const Container = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
`;
export const FormContainer = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-left: 20%;
    padding-right: 20%;
`;
export const StatusContainer = styled.div`
    flex: 1;
    display: flex;
    align-self: flex-start;
    justify-content: center;
    align-items: flex-start;
`;
export const ButtonContainer = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-start;
    padding-bottom: 50px;
`;
