import { NetworkError, HttpErrorHandler } from './service';
import { VipAPIService } from '../services';
import User from '../models/user';

export default class SessionService {
    static async postSignIn({ username, password }) {
        const [error, response] = await VipAPIService.shared.postSignIn({
            username,
            password,
        });
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            const user = response.data.user;
            if (user) {
                VipAPIService.shared.api.defaults.headers.Authorization = response.data.token;
                return {
                    user: new User(user),
                    token: response.data.token,
                };
            }
        }
    }
    static async getProfile() {
        const [error, response] = await VipAPIService.shared.getProfile();
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return response.data;
        }
    }
}
