export default class Ads {
    constructor(ads) {
        this.id = ads.id;
        this.adsAmount = ads.ads_amount;
        this.createdBy = ads.created_by_user;
        this.status = ads.status;
        this.createdAt = ads.created_at;
        this.adsDate = ads.ads_date;
        this.updatedAt = ads.updated_at;
        this.team = ads.team;
        this.adsType = ads.ads_type;
        this.teamId = ads.team_id;
        this.memberId = ads.user_id;
        this.expenseCategory = ads.expense_category;
        this.member = ads.user;
    }
}
