import React, { Component } from 'react';
import { Result, Spin } from 'antd';
import { connect } from 'react-redux';
import { getClientLinkById } from '../../ducks/vipLink';
import { Container } from './index.style';
class VipLinkClient extends Component {
    componentDidMount = () => {
        document.title = 'Redirecting...';
        this.redirect();
    };
    redirect = async () => {
        if (Object.values(this.props.match.params).length === 0) {
            return;
        }
        const id = this.props.match.params.id;
        await this.props.getClientLinkById({ id });
        window.location = this.props.vipLink.clientLink;
    };
    render() {
        return (
            <Container>
                <Result
                    status="success"
                    title="You will be redirected in a few moments..."
                    subTitle={'Hang tight!'}
                />
                <Spin size={'large'} />
            </Container>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        vipLink: state.vipLink,
    };
};

export default connect(mapStateToProps, { getClientLinkById })(VipLinkClient);
