import { connect } from 'react-redux';
import Ads from './Ads';
import { getAdvertisementList, approveAdsById, rejectAdsById } from '../../ducks/ads';
import { getTeamList } from '../../ducks/team';

const mapStateToProps = (state) => {
    return {
        session: state.session,
        ads: state.ads,
        team: state.team,
    };
};

const AdsContainer = connect(mapStateToProps, {
    getAdvertisementList,
    approveAdsById,
    rejectAdsById,
    getTeamList,
})(Ads);
export default AdsContainer;
