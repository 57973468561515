import React, { Component } from 'react';
import moment from 'moment';
import { Table, Space, Button, notification, Tag, Select, Tooltip, Row, Col } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { Container, ButtonContainer, IconButton, FilterContainer } from './index.style';
import { Constant, Utility } from '../../helpers';

const { Option } = Select;

class TeamManagement extends Component {
    state = {
        loading: false,
        teamId: 'all',
        memberId: 'all',
        teamList: [],
        data: [],
        pagination: {
            current: 1,
            total: 0,
            showSizeChanger: false,
        },
    };
    constructor(props) {
        super(props);
        this.div = React.createRef();
        this.columns = [
            {
                title: 'Created At',
                dataIndex: 'createdAt',
                key: 'createdAt',
                render: (text) => <span>{moment(text).format('DD/MM/YYYY')}</span>,
            },
            {
                title: 'Code',
                dataIndex: 'code',
                key: 'code',
                render: (text) => <b>{text}</b>,
            },
            {
                title: 'Step',
                dataIndex: 'step',
                key: 'step',
                render: (text) => {
                    let color = '#87d068';
                    if (text === Constant.CUSTOMER_REPORT_STEP.newDeposit) {
                        color = 'blue';
                    }
                    return (
                        <Space size="middle">
                            <Tag color={color}>
                                <b>{text.toUpperCase()}</b>
                            </Tag>
                        </Space>
                    );
                },
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (text) => {
                    let color = '#87d068';
                    if (text === Constant.CUSTOMER_REPORT_STATUS.pending) {
                        color = 'volcano';
                    } else if (text === Constant.CUSTOMER_REPORT_STATUS.reject) {
                        color = '#f50';
                    }
                    return (
                        <Space size="middle">
                            <Tag color={color}>
                                <b>{text.toUpperCase()}</b>
                            </Tag>
                        </Space>
                    );
                },
            },
            {
                title: 'Reason',
                dataIndex: 'reason',
                key: 'reason',
                render: (text) => <span>{text}</span>,
            },
            {
                title: 'Created By',
                key: 'createdBy',
                render: (text) => <b>{text.createdBy.username}</b>,
            },
            {
                title: 'Action',
                key: 'action',
                render: (record) => {
                    return (
                        <Space size="middle">
                            <IconButton onClick={() => this.onEditButtonPressed({ id: record.id })}>
                                <EditOutlined /> Edit
                            </IconButton>
                        </Space>
                    );
                },
            },
        ];
    }
    componentDidMount = async () => {
        this.div.current.scrollIntoView({ behavior: 'smooth' });
        if (Utility.isMember()) {
            this.setState({ memberId: this.props.session.id });
        }
        this.setState({ loading: true });
        const payload = {
            team_id: this.state.teamId,
            member_id: this.state.memberId,
        };
        await this.props.getCustomerReportList(payload);
        await this.props.getUserList({
            user_type: [Constant.USER_TYPE.member, Constant.USER_TYPE.agent],
            page_size: 1000,
        });
        if (this.props.session.permissions.includes(Constant.USER_PERMISSIONS.manage_team)) {
            await this.props.getTeamList({ page_size: 50 });
        } else {
            let teamList = [];
            this.props.session.teamList.map((teamObj) => {
                return teamList.push(teamObj.team);
            });
            this.setState({ teamList });
        }
    };
    componentDidUpdate = (prevProps) => {
        if (
            this.props.customerReport.error &&
            this.props.customerReport.error !== prevProps.customerReport.error
        ) {
            this.openNotificationWithIcon({
                type: 'error',
                title: this.props.customerReport.error.frontend_type,
                message: this.props.customerReport.error.message,
            });
            this.setState({
                isLoading: this.props.customerReport.isLoading,
            });
        }
        if (this.props.team.error && this.props.team.error !== prevProps.team.error) {
            this.openNotificationWithIcon({
                type: 'error',
                title: this.props.team.error.frontend_type,
                message: this.props.team.error.message,
            });
            this.setState({
                isLoading: this.props.team.isLoading,
            });
        }
        if (this.props.user.error && this.props.user.error !== prevProps.user.error) {
            this.openNotificationWithIcon({
                type: 'error',
                title: this.props.user.error.frontend_type,
                message: this.props.user.error.message,
            });
            this.setState({
                isLoading: this.props.user.isLoading,
            });
        }
        if (prevProps.team.list !== this.props.team.list) {
            this.setState({
                teamList: this.props.team.list,
                loading: false,
            });
        }
        if (prevProps.customerReport.list !== this.props.customerReport.list) {
            this.setState({
                data: this.props.customerReport.list,
                loading: false,
                pagination: {
                    ...this.state.pagination,
                    current: this.props.customerReport.pagination.page,
                    total: this.props.customerReport.pagination.row_count,
                },
            });
        }
    };
    openNotificationWithIcon = ({ type, title, message }) => {
        notification[type]({
            message: title,
            description: message,
        });
    };
    onChangeTable = async ({ nextPage }) => {
        const payload = {
            team_id: this.state.teamId,
            member_id: this.state.memberId,
            page_number: nextPage,
        };
        this.setState({ loading: true });
        await this.props.getCustomerReportList(payload);
    };
    onEditButtonPressed = ({ id }) => {
        this.props.history.push(`/cs-report/edit/${id}`);
    };
    onCreateCSReportPressed = () => {
        this.props.history.push('/cs-report/create');
    };
    onTeamChanged = async (team) => {
        await this.setState({ teamId: team, memberId: 'all' });
        await this.props.getUserList({
            team_ids: [team],
            user_type: [Constant.USER_TYPE.member, Constant.USER_TYPE.agent],
            page_size: 1000,
        });
        this.generateReport();
    };
    onMemberChanged = async (member) => {
        await this.setState({ memberId: member });
        this.generateReport();
    };
    generateReport = async () => {
        this.setState({ loading: true });
        const payload = {
            team_id: this.state.teamId,
            member_id: this.state.memberId,
        };
        await this.props.getCustomerReportList(payload);
    };
    render() {
        const authorisedFilter = Utility.isAdmin() || Utility.isAgent() || Utility.isAccountant();
        return (
            <Container ref={this.div}>
                <ButtonContainer>
                    {authorisedFilter && (
                        <Tooltip placement="left" title="Select a Team">
                            <Select
                                showSearch
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                value={this.state.teamId}
                                size={'large'}
                                style={{ width: 200 }}
                                optionFilterProp="children"
                                placeholder={'Select a Team'}
                                onChange={this.onTeamChanged}
                            >
                                {this.state.teamList.map((team) => {
                                    return (
                                        <Option key={team.id} value={team.id}>
                                            {team.name}
                                        </Option>
                                    );
                                })}
                                <Option key={'all'} value={'all'}>
                                    All
                                </Option>
                            </Select>
                        </Tooltip>
                    )}
                    {authorisedFilter && (
                        <Tooltip placement="left" title="Select a Member">
                            <Select
                                showSearch
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                value={this.state.memberId}
                                size={'large'}
                                style={{ width: 200 }}
                                optionFilterProp="children"
                                placeholder={'Select a Member'}
                                onChange={this.onMemberChanged}
                            >
                                {this.props.user.list.map((member) => {
                                    return (
                                        <Option key={member.id} value={member.id}>
                                            {member.username}
                                        </Option>
                                    );
                                })}
                                <Option key={'all'} value={'all'}>
                                    All
                                </Option>
                            </Select>
                        </Tooltip>
                    )}
                    <Button type="primary" size={'large'} onClick={this.onCreateCSReportPressed}>
                        <b>CREATE NEW REPORT</b>
                    </Button>
                </ButtonContainer>
                <Table
                    loading={this.state.loading}
                    rowKey={(record) => record.id}
                    columns={this.columns}
                    dataSource={this.state.data}
                    scroll={{ x: 500 }}
                    pagination={this.state.pagination}
                    onChange={(event) => this.onChangeTable({ nextPage: event.current })}
                />
            </Container>
        );
    }
}

export default TeamManagement;
