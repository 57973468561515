import React, { Component } from 'react';
import {
    Table,
    Space,
    notification,
    Tag,
    Row,
    Col,
    Select,
    Collapse,
    DatePicker,
    Tooltip,
    Statistic,
    Card,
} from 'antd';
import moment from 'moment';
import { Container, ButtonContainer } from './index.style';
import { Utility, Constant } from '../../helpers';

const { Option } = Select;
const { Panel } = Collapse;
const { RangePicker } = DatePicker;

class Report extends Component {
    state = {
        loading: false,
        teamIds: ['All'],
        memberIds: ['All'],
        bankId: 'all',
        teamList: [],
        bankList: [],
        reportType: Constant.REPORT_TYPE.ytd,
        dateRange: [moment().startOf('year'), moment()],
        data: [],
        pagination: {
            current: 1,
            total: 0,
            showSizeChanger: false,
        },
    };
    constructor(props) {
        super(props);
        this.div = React.createRef();
        this.columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: 'Sales Date',
                dataIndex: 'salesDate',
                key: 'salesDate',
                render: (text) => <span>{moment(text).format('DD/MM/YYYY')}</span>,
            },
            {
                title: 'Updated At',
                dataIndex: 'updatedAt',
                key: 'updatedAt',
                render: (text) => <span>{moment(text).format('DD/MM/YYYY')}</span>,
            },
            {
                title: 'Team',
                dataIndex: 'team',
                key: 'team',
                render: (text) => <span>{text ? text.name : '-'}</span>,
            },
            {
                title: 'Created By',
                key: 'createdBy',
                render: (text) => <span>{text.createdBy.username}</span>,
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (text) => {
                    let color = '#87d068';
                    if (text === Constant.SALES_STATUS.pending) {
                        color = 'volcano';
                    } else if (
                        text === Constant.SALES_STATUS.rejected ||
                        text === Constant.SALES_STATUS.accountant_rejected
                    ) {
                        color = '#f50';
                    }
                    return (
                        <Space size="middle">
                            <Tag color={color}>
                                <b>{text.toUpperCase()}</b>
                            </Tag>
                        </Space>
                    );
                },
            },
            {
                title: 'Sales Amt',
                dataIndex: 'salesAmount',
                key: 'salesAmount',
                render: (text) => <b>RM {Utility.convertNumberToStringCommaNumber(text)}</b>,
            },
            {
                title: 'Ads Amt',
                dataIndex: 'adsAmount',
                key: 'adsAmount',
                render: (text) => <b>RM {Utility.convertNumberToStringCommaNumber(text)}</b>,
            },
            {
                title: 'Expenses Amt',
                dataIndex: 'expensesAmount',
                key: 'expensesAmount',
                render: (text) => <b>RM {Utility.convertNumberToStringCommaNumber(text)}</b>,
            },
            {
                title: 'Expense Category',
                dataIndex: 'expenseCategory',
                key: 'expenseCategory',
                render: (text) => <b>{(text || '-').toUpperCase()}</b>,
            },
            {
                title: 'Bank Account',
                dataIndex: 'bankAccountId',
                key: 'bankAccountId',
                render: (text) => {
                    const bankAccount = this.props.bankAccount.list.find(
                        (bank) => bank.id === text
                    );
                    return <b>{bankAccount ? bankAccount.name : '-'}</b>;
                },
            },
            {
                title: 'Transfer Type',
                dataIndex: 'transferType',
                key: 'transferType',
                render: (text) => <b>{text.toUpperCase()}</b>,
            },
            {
                title: 'Profit / Loss',
                render: (text) => {
                    const sales = text.salesAmount;
                    const ads = text.adsAmount;
                    const expenses = text.expensesAmount;
                    const nett = sales - (ads + expenses);
                    const color = nett < 0 ? '#f50' : '#87d068';
                    return (
                        <b style={{ color: color }}>
                            RM {Utility.convertNumberToStringCommaNumber(nett.toFixed(2))}
                        </b>
                    );
                },
            },
        ];
    }
    componentDidMount = async () => {
        this.div.current.scrollIntoView({ behavior: 'smooth' });
        if (Utility.isMember()) {
            this.setState({ memberId: this.props.session.id });
        }
        this.setState({ loading: true });
        const payload = {
            team_ids: this.state.teamIds,
            bank_id: this.state.bankId,
            member_ids: this.state.memberIds,
            start_date: moment(this.state.dateRange[0]).format('YYYY-MM-DD'),
            end_date: moment(this.state.dateRange[1]).format('YYYY-MM-DD'),
        };
        await this.props.getSalesList(payload);
        await this.props.getUserList({
            user_type: [Constant.USER_TYPE.member, Constant.USER_TYPE.agent],
            page_size: 1000,
        });
        await this.props.getBankList();
        if (this.props.session.permissions.includes(Constant.USER_PERMISSIONS.manage_team)) {
            await this.props.getTeamList({ page_size: 50 });
        } else {
            let teamList = [];
            this.props.session.teamList.map((teamObj) => {
                return teamList.push(teamObj.team);
            });
            this.setState({ teamList });
        }
    };
    componentDidUpdate = (prevProps) => {
        if (this.props.sale.error && this.props.sale.error !== prevProps.sale.error) {
            this.openNotificationWithIcon({
                type: 'error',
                title: this.props.sale.error.frontend_type,
                message: this.props.sale.error.message,
            });
            this.setState({
                isLoading: this.props.sale.isLoading,
            });
        }
        if (this.props.team.error && this.props.team.error !== prevProps.team.error) {
            this.openNotificationWithIcon({
                type: 'error',
                title: this.props.team.error.frontend_type,
                message: this.props.team.error.message,
            });
            this.setState({
                isLoading: this.props.team.isLoading,
            });
        }
        if (this.props.user.error && this.props.user.error !== prevProps.user.error) {
            this.openNotificationWithIcon({
                type: 'error',
                title: this.props.user.error.frontend_type,
                message: this.props.user.error.message,
            });
            this.setState({
                isLoading: this.props.user.isLoading,
            });
        }
        if (
            this.props.bankAccount.error &&
            this.props.bankAccount.error !== prevProps.bankAccount.error
        ) {
            this.openNotificationWithIcon({
                type: 'error',
                title: this.props.bankAccount.error.frontend_type,
                message: this.props.bankAccount.error.message,
            });
            this.setState({
                isLoading: this.props.bankAccount.isLoading,
            });
        }
        if (prevProps.sale.list !== this.props.sale.list) {
            this.setState({
                data: this.props.sale.list,
                loading: false,
                pagination: {
                    ...this.state.pagination,
                    current: this.props.sale.pagination.page,
                    total: this.props.sale.pagination.row_count,
                },
            });
        }
        if (prevProps.team.list !== this.props.team.list) {
            this.setState({
                teamList: this.props.team.list,
                loading: false,
            });
        }
        if (prevProps.bankAccount.bankList !== this.props.bankAccount.bankList) {
            this.setState({
                bankList: this.props.bankAccount.bankList,
                loading: false,
            });
        }
    };
    openNotificationWithIcon = ({ type, title, message }) => {
        notification[type]({
            message: title,
            description: message,
        });
    };
    onChangeTable = async ({ nextPage }) => {
        this.setState({ loading: true });
        const payload = {
            team_ids: this.state.teamIds,
            bank_id: this.state.bankId,
            member_ids: this.state.memberIds,
            page_number: nextPage,
            start_date: moment(this.state.dateRange[0]).format('YYYY-MM-DD'),
            end_date: moment(this.state.dateRange[1]).format('YYYY-MM-DD'),
        };
        await this.props.getSalesList(payload);
    };
    onTeamChanged = async (team) => {
        if (team.length > 1 && team.includes('All')) {
            team.splice(0, 1);
        } else if (team.length === 0) {
            team.push('All');
        }

        await this.setState({ teamIds: team, memberIds: ['All'] });
        await this.props.getUserList({
            team_ids: team,
            user_type: [Constant.USER_TYPE.member, Constant.USER_TYPE.agent],
            page_size: 1000,
        });
        this.generateReport();
    };
    onMemberChanged = async (member) => {
        if (member.length > 1 && member.includes('All')) {
            member.splice(0, 1);
        } else if (member.length === 0) {
            member.push('All');
        }

        await this.setState({ memberIds: member });
        this.generateReport();
    };
    onBankChanged = async (bank) => {
        await this.setState({ bankId: bank });
        this.generateReport();
    };
    onReportTypeChanged = async (report) => {
        this.setState({ reportType: report });
        switch (report) {
            case Constant.REPORT_TYPE.today: {
                await this.setState({
                    dateRange: [moment(), moment()],
                });
                this.generateReport();
                break;
            }
            case Constant.REPORT_TYPE.yesterday: {
                await this.setState({
                    dateRange: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                });
                this.generateReport();
                break;
            }
            case Constant.REPORT_TYPE.weekly: {
                await this.setState({
                    dateRange: [
                        moment().startOf('week').add(1, 'days'),
                        moment().endOf('week').add(1, 'days'),
                    ],
                });
                this.generateReport();
                break;
            }
            case Constant.REPORT_TYPE.lastWeek: {
                await this.setState({
                    dateRange: [
                        moment().subtract(1, 'week').startOf('week').add(1, 'days'),
                        moment().subtract(1, 'week').endOf('week').add(1, 'days'),
                    ],
                });
                this.generateReport();
                break;
            }
            case Constant.REPORT_TYPE.monthly: {
                await this.setState({
                    dateRange: [moment().startOf('month'), moment().endOf('month')],
                });
                this.generateReport();
                break;
            }
            case Constant.REPORT_TYPE.lastMonth: {
                await this.setState({
                    dateRange: [
                        moment().subtract(1, 'months').startOf('month'),
                        moment().subtract(1, 'months').endOf('month'),
                    ],
                });
                this.generateReport();
                break;
            }
            case Constant.REPORT_TYPE.ytd: {
                await this.setState({
                    dateRange: [moment().startOf('year'), moment()],
                });
                this.generateReport();
                break;
            }
            case Constant.REPORT_TYPE.range: {
                await this.setState({
                    dateRange: [],
                });
                break;
            }
            default:
                break;
        }
    };
    generateReport = async () => {
        this.setState({ loading: true });
        const payload = {
            team_ids: this.state.teamIds,
            bank_id: this.state.bankId,
            member_ids: this.state.memberIds,
            start_date: moment(this.state.dateRange[0]).format('YYYY-MM-DD'),
            end_date: moment(this.state.dateRange[1]).format('YYYY-MM-DD'),
        };
        await this.props.getSalesList(payload);
    };
    onDateChanged = async (date) => {
        await this.setState({
            dateRange: date,
        });
        this.generateReport();
    };
    getColorBasedOnReport = ({ value }) => {
        switch (this.state.reportType) {
            case Constant.REPORT_TYPE.today: {
                return value >= 4300 ? '#87d068' : '#f50';
            }
            case Constant.REPORT_TYPE.yesterday: {
                return value >= 4300 ? '#87d068' : '#f50';
            }
            case Constant.REPORT_TYPE.weekly: {
                return value >= 30000 ? '#87d068' : '#f50';
            }
            case Constant.REPORT_TYPE.monthly: {
                return value >= 120000 ? '#87d068' : '#f50';
            }
            case Constant.REPORT_TYPE.ytd: {
                return 'rgba(0, 0, 0, 0.85)';
            }
            default:
                break;
        }
    };
    disabledDate = (current) => {
        return current && current > moment().endOf('day');
    };
    getCommissionAmount = () => {
        if (!this.props.sale.reportSummary) {
            return;
        }
        // if (Utility.isAdmin()) {
        //     return this.props.sale.reportSummary.totalCompanyAmount;
        // }
        // if (Utility.isAgent()) {
        //     return this.props.sale.reportSummary.totalAgentAmount;
        // }
        // if (Utility.isMember()) {
        //     return this.props.sale.reportSummary.totalMemberAmount;
        // }
        return this.props.sale.reportSummary.totalMemberAmount;
    };
    render() {
        const reportSummary = this.props.sale.reportSummary;
        const authorisedFilter = Utility.isAdmin() || Utility.isAgent() || Utility.isAccountant();
        const selectedMembers = [];

        this.state.memberIds.forEach((memberId) => {
            this.props.user.list.find((user) => {
                if (user.id === memberId) selectedMembers.push(user);
            });
        });

        return (
            <Container ref={this.div}>
                <ButtonContainer>
                    <Row align={'middle'} gutter={[12, 12]}>
                        {authorisedFilter && (
                            <Col xs={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }}>
                                <Tooltip placement="left" title="Select a Team">
                                    <Select
                                        mode="multiple"
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >= 0
                                        }
                                        value={this.state.teamIds}
                                        size={'large'}
                                        style={{ width: 200 }}
                                        optionFilterProp="children"
                                        placeholder={'Select Team'}
                                        onChange={this.onTeamChanged}
                                    >
                                        {this.state.teamList.map((team) => {
                                            return (
                                                <Option key={team.id} value={team.id}>
                                                    {team.name}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </Tooltip>
                            </Col>
                        )}
                        {authorisedFilter && (
                            <Col xs={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }}>
                                <Tooltip placement="left" title="Select a Member">
                                    <Select
                                        mode="multiple"
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >= 0
                                        }
                                        value={this.state.memberIds}
                                        size={'large'}
                                        style={{ width: 200 }}
                                        optionFilterProp="children"
                                        placeholder={'Select Member'}
                                        onChange={this.onMemberChanged}
                                    >
                                        {this.props.user.list.map((member) => {
                                            return (
                                                <Option key={member.id} value={member.id}>
                                                    {member.username}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </Tooltip>
                            </Col>
                        )}
                        {authorisedFilter && (
                            <Col xs={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }}>
                                <Tooltip placement="left" title="Select a Bank">
                                    <Select
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >= 0
                                        }
                                        value={this.state.bankId}
                                        size={'large'}
                                        style={{ width: 200 }}
                                        optionFilterProp="children"
                                        placeholder={'Select a Bank'}
                                        onChange={this.onBankChanged}
                                    >
                                        {this.props.bankAccount.bankList.map((bank) => {
                                            return (
                                                <Option key={bank.id} value={bank.id}>
                                                    {bank.name}
                                                </Option>
                                            );
                                        })}
                                        <Option key={'all'} value={'all'}>
                                            All
                                        </Option>
                                    </Select>
                                </Tooltip>
                            </Col>
                        )}
                        <Col xs={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }}>
                            <Tooltip placement="left" title="Report Type">
                                <Select
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                    value={this.state.reportType}
                                    size={'large'}
                                    style={{ width: 200 }}
                                    optionFilterProp="children"
                                    placeholder={'Report Type'}
                                    onChange={this.onReportTypeChanged}
                                >
                                    <Option
                                        key={Constant.REPORT_TYPE.today}
                                        value={Constant.REPORT_TYPE.today}
                                    >
                                        Today
                                    </Option>
                                    <Option
                                        key={Constant.REPORT_TYPE.yesterday}
                                        value={Constant.REPORT_TYPE.yesterday}
                                    >
                                        Yesterday
                                    </Option>
                                    <Option
                                        key={Constant.REPORT_TYPE.weekly}
                                        value={Constant.REPORT_TYPE.weekly}
                                    >
                                        This Week
                                    </Option>
                                    <Option
                                        key={Constant.REPORT_TYPE.lastWeek}
                                        value={Constant.REPORT_TYPE.lastWeek}
                                    >
                                        Last Week
                                    </Option>
                                    <Option
                                        key={Constant.REPORT_TYPE.monthly}
                                        value={Constant.REPORT_TYPE.monthly}
                                    >
                                        This Month
                                    </Option>
                                    <Option
                                        key={Constant.REPORT_TYPE.lastMonth}
                                        value={Constant.REPORT_TYPE.lastMonth}
                                    >
                                        Last Month
                                    </Option>
                                    <Option
                                        key={Constant.REPORT_TYPE.ytd}
                                        value={Constant.REPORT_TYPE.ytd}
                                    >
                                        YTD
                                    </Option>
                                    <Option
                                        key={Constant.REPORT_TYPE.range}
                                        value={Constant.REPORT_TYPE.range}
                                    >
                                        Range
                                    </Option>
                                </Select>
                            </Tooltip>
                            <br />
                            <br />
                            <RangePicker
                                value={this.state.dateRange}
                                disabledDate={this.disabledDate}
                                onChange={this.onDateChanged}
                                size={'large'}
                                format={'DD/MM/YYYY'}
                                disabled={this.state.reportType !== Constant.REPORT_TYPE.range}
                            />
                        </Col>
                    </Row>
                </ButtonContainer>
                <Collapse defaultActiveKey={['1', '2', '3']}>
                    {selectedMembers.length > 0 && (
                        <Panel
                            header={`${selectedMembers
                                .map((selectedMember) => selectedMember.username)
                                .join(', ')}'s commission`}
                            key="1"
                        >
                            <Row gutter={16}>
                                <Col
                                    span={24}
                                    xs={{ span: 24 }}
                                    lg={{ span: 24 }}
                                    md={{ span: 24 }}
                                >
                                    <Statistic
                                        value={this.getCommissionAmount()}
                                        precision={2}
                                        prefix={'RM'}
                                        valueStyle={{
                                            fontWeight: 'bold',
                                            fontSize: '25pt',
                                            color: '#87d068',
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Panel>
                    )}

                    <Panel header="REPORT SUMMARY" key="2">
                        <Row gutter={16}>
                            <Col span={8} xs={{ span: 24 }} lg={{ span: 8 }} md={{ span: 8 }}>
                                <Card loading={this.state.loading}>
                                    <Statistic
                                        title="Total Sales"
                                        value={reportSummary ? reportSummary.totalSalesAmount : '-'}
                                        precision={2}
                                        prefix={'RM'}
                                    />
                                </Card>
                            </Col>
                            <Col span={8} xs={{ span: 24 }} lg={{ span: 8 }} md={{ span: 8 }}>
                                <Card loading={this.state.loading}>
                                    <Statistic
                                        title="Total Ads"
                                        value={reportSummary ? reportSummary.totalAdsAmount : '-'}
                                        precision={2}
                                        prefix={'RM'}
                                    />
                                </Card>
                            </Col>
                            <Col span={8} xs={{ span: 24 }} lg={{ span: 8 }} md={{ span: 8 }}>
                                <Card loading={this.state.loading}>
                                    <Statistic
                                        title="Total Expenses"
                                        value={
                                            reportSummary ? reportSummary.totalExpensesAmount : '-'
                                        }
                                        precision={2}
                                        prefix={'RM'}
                                    />
                                </Card>
                            </Col>
                        </Row>
                        <br />
                        <Row gutter={16}>
                            <Col span={12}>
                                <Card loading={this.state.loading}>
                                    <Statistic
                                        title="Total Profit"
                                        value={reportSummary ? reportSummary.totalProfit : '-'}
                                        precision={2}
                                        prefix={'RM'}
                                        valueStyle={{
                                            fontWeight: 'bold',
                                            fontSize: '25pt',
                                            color: this.getColorBasedOnReport({
                                                value: reportSummary
                                                    ? reportSummary.totalProfit
                                                    : 0,
                                            }),
                                        }}
                                    />
                                </Card>
                            </Col>
                            <Col span={12}>
                                <Card loading={this.state.loading}>
                                    <Statistic
                                        title="Profit Margin"
                                        value={reportSummary ? reportSummary.profitMargin : '-'}
                                        precision={2}
                                        suffix={'%'}
                                        valueStyle={{
                                            fontWeight: 'bold',
                                            fontSize: '25pt',
                                            color: '#87d068',
                                        }}
                                    />
                                </Card>
                            </Col>
                        </Row>
                    </Panel>
                    <Panel header="SALES ENTRIES" key="3">
                        <Table
                            loading={this.state.loading}
                            rowKey={(record) => record.id}
                            columns={this.columns}
                            dataSource={this.state.data}
                            scroll={{ x: 500 }}
                            pagination={this.state.pagination}
                            onChange={(event) => this.onChangeTable({ nextPage: event.current })}
                        />
                    </Panel>
                </Collapse>
            </Container>
        );
    }
}

export default Report;
