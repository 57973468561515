import EnquiryService from '../services/enquiryService';
// Actions
const GET_ENQUIRY_LIST_REQUEST = 'vip/enquiry/GET_ENQUIRY_LIST_REQUEST';
const GET_ENQUIRY_LIST_SUCCESS = 'vip/enquiry/GET_ENQUIRY_LIST_SUCCESS';
const GET_ENQUIRY_LIST_FAIL = 'vip/enquiry/GET_ENQUIRY_LIST_FAIL';
const GET_ENQUIRY_REQUEST = 'vip/enquiry/GET_ENQUIRY_REQUEST';
const GET_ENQUIRY_SUCCESS = 'vip/enquiry/GET_ENQUIRY_SUCCESS';
const GET_ENQUIRY_FAIL = 'vip/enquiry/GET_ENQUIRY_FAIL';
const DELETE_ENQUIRY_REQUEST = 'vip/enquiry/DELETE_ENQUIRY_REQUEST';
const DELETE_ENQUIRY_SUCCESS = 'vip/enquiry/DELETE_ENQUIRY_SUCCESS';
const DELETE_ENQUIRY_FAIL = 'vip/enquiry/DELETE_ENQUIRY_FAIL';
const POST_CREATE_ENQUIRY_REQUEST = 'vip/enquiry/POST_CREATE_ENQUIRY_REQUEST';
const POST_CREATE_ENQUIRY_SUCCESS = 'vip/enquiry/POST_CREATE_ENQUIRY_SUCCESS';
const POST_CREATE_ENQUIRY_FAIL = 'vip/enquiry/POST_CREATE_ENQUIRY_FAIL';
const PATCH_EDIT_ENQUIRY_REQUEST = 'vip/enquiry/PATCH_EDIT_ENQUIRY_REQUEST';
const PATCH_EDIT_ENQUIRY_SUCCESS = 'vip/enquiry/PATCH_EDIT_ENQUIRY_SUCCESS';
const PATCH_EDIT_ENQUIRY_FAIL = 'vip/enquiry/PATCH_EDIT_ENQUIRY_FAIL';
const SESSION_TOKEN_EXPIRED = 'vip/session/SESSION_TOKEN_EXPIRED';

// Reducer
const INITIAL_STATE = {
    error: null,
    isLoading: false,
    current: null,
    status: null,
    list: [],
    pagination: null,
};

export default function reducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SESSION_TOKEN_EXPIRED: {
            return INITIAL_STATE;
        }
        case GET_ENQUIRY_LIST_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case GET_ENQUIRY_LIST_SUCCESS: {
            return {
                ...state,
                list: action.payload.results,
                pagination: action.payload.pagination,
                isLoading: false,
                error: null,
            };
        }
        case GET_ENQUIRY_LIST_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case GET_ENQUIRY_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case GET_ENQUIRY_SUCCESS: {
            return {
                ...state,
                current: action.payload,
                isLoading: false,
                error: null,
            };
        }
        case GET_ENQUIRY_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case DELETE_ENQUIRY_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case DELETE_ENQUIRY_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: null,
            };
        }
        case DELETE_ENQUIRY_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case POST_CREATE_ENQUIRY_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case POST_CREATE_ENQUIRY_SUCCESS: {
            return {
                ...state,
                current: action.payload,
                isLoading: false,
                error: null,
            };
        }
        case POST_CREATE_ENQUIRY_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case PATCH_EDIT_ENQUIRY_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case PATCH_EDIT_ENQUIRY_SUCCESS: {
            return {
                ...state,
                current: action.payload,
                isLoading: false,
                error: null,
            };
        }
        case PATCH_EDIT_ENQUIRY_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        default:
            return state;
    }
}

// Action Creators
export function getEnquiryListRequest() {
    return {
        type: GET_ENQUIRY_LIST_REQUEST,
    };
}
export function getEnquiryListSuccess(payload) {
    return {
        type: GET_ENQUIRY_LIST_SUCCESS,
        payload,
    };
}
export function getEnquiryListFail(error) {
    return {
        type: GET_ENQUIRY_LIST_FAIL,
        error,
    };
}
export function getEnquiryRequest() {
    return {
        type: GET_ENQUIRY_REQUEST,
    };
}
export function getEnquiryuccess(payload) {
    return {
        type: GET_ENQUIRY_SUCCESS,
        payload,
    };
}
export function getEnquiryFail(error) {
    return {
        type: GET_ENQUIRY_FAIL,
        error,
    };
}
export function deleteEnquiryRequest() {
    return {
        type: DELETE_ENQUIRY_REQUEST,
    };
}
export function deleteEnquirySuccess() {
    return {
        type: DELETE_ENQUIRY_SUCCESS,
    };
}
export function deleteEnquiryFail(error) {
    return {
        type: DELETE_ENQUIRY_FAIL,
        error,
    };
}
export function updateEnquiryRequest() {
    return {
        type: PATCH_EDIT_ENQUIRY_REQUEST,
    };
}
export function updateEnquirySuccess(payload) {
    return {
        type: PATCH_EDIT_ENQUIRY_SUCCESS,
        payload,
    };
}
export function updateEnquiryFail(error) {
    return {
        type: PATCH_EDIT_ENQUIRY_FAIL,
        error,
    };
}
export function createEnquiryRequest() {
    return {
        type: POST_CREATE_ENQUIRY_REQUEST,
    };
}
export function createEnquirySuccess(payload) {
    return {
        type: POST_CREATE_ENQUIRY_SUCCESS,
        payload,
    };
}
export function createEnquiryFail(error) {
    return {
        type: POST_CREATE_ENQUIRY_FAIL,
        error,
    };
}
// side effects, only as applicable
// e.g. thunks, epics, etc
export const getEnquiryList = ({
    page_size,
    page_number,
    team_ids,
    user_ids,
    start_date,
    end_date,
    filter_status,
} = {}) => async (dispatch) => {
    dispatch(getEnquiryListRequest());
    try {
        const list = await EnquiryService.getEnquiryList({
            page_size,
            page_number,
            team_ids,
            user_ids,
            start_date,
            end_date,
            filter_status,
        });
        dispatch(getEnquiryListSuccess(list));
    } catch (error) {
        dispatch(getEnquiryListFail(error));
        throw error;
    }
};
export const createEnquiry = ({ enquiry_amount, enquiry_date, team_id, member_id }) => async (
    dispatch
) => {
    dispatch(createEnquiryRequest());
    try {
        const result = await EnquiryService.createEnquiry({
            enquiry_amount,
            enquiry_date,
            team_id,
            member_id,
        });
        dispatch(createEnquirySuccess(result));
    } catch (error) {
        dispatch(createEnquiryFail(error));
        throw error;
    }
};
export const getEnquiryById = ({ id }) => async (dispatch) => {
    dispatch(getEnquiryRequest());
    try {
        const result = await EnquiryService.getEnquiryById({
            id,
        });
        dispatch(getEnquiryuccess(result));
    } catch (error) {
        dispatch(getEnquiryFail(error));
        throw error;
    }
};
export const updateEnquiry = ({ id, enquiry_amount, enquiry_date, team_id, member_id }) => async (
    dispatch
) => {
    dispatch(updateEnquiryRequest());
    try {
        const result = await EnquiryService.updateEnquiry({
            id,
            enquiry_amount,
            enquiry_date,
            team_id,
            member_id,
        });
        dispatch(updateEnquirySuccess(result));
    } catch (error) {
        dispatch(updateEnquiryFail(error));
        throw error;
    }
};
export const deleteEnquiryById = ({ id }) => async (dispatch) => {
    dispatch(deleteEnquiryRequest());
    try {
        await EnquiryService.deleteEnquiryById({
            id,
        });
        dispatch(deleteEnquirySuccess());
    } catch (error) {
        dispatch(deleteEnquiryFail(error));
        throw error;
    }
};
