import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, history } from './store';
import { persistStore } from 'redux-persist';
import Routes from './router';
import './App.css';

function App() {
    const persistor = persistStore(store);
    return (
        <div className={'App'}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <Routes history={history} />
                </PersistGate>
            </Provider>
        </div>
    );
}

export default App;
