import session from './session';
import vipLink from './vipLink';
import user from './user';
import team from './team';
import sale from './sale';
import bankAccount from './bankAccount';
import clock from './clock';
import announcement from './announcement';
import customerReport from './customerReport';
import ads from './ads';
import enquiry from './enquiry';

export default {
    session,
    vipLink,
    user,
    team,
    sale,
    bankAccount,
    clock,
    announcement,
    customerReport,
    ads,
    enquiry,
};
