import { connect } from 'react-redux';
import BankDashboard from './BankDashboard';
import {
    createBankAccount,
    getBankAccountList,
    getBankList,
    getBankAccountById,
    updateBankAccount,
    deleteBankAccountById,
} from '../../../ducks/bankAccount';
import { getUserList } from '../../../ducks/user';

const mapStateToProps = (state) => {
    return {
        session: state.session,
        bankAccount: state.bankAccount,
    };
};

const BankDashboardContainer = connect(mapStateToProps, {
    createBankAccount,
    getBankList,
    getBankAccountList,
    getBankAccountById,
    updateBankAccount,
    deleteBankAccountById,
    getUserList,
})(BankDashboard);
export default BankDashboardContainer;
