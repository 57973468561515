import { connect } from 'react-redux';
import SalesDashboard from './SalesDashboard';
import {
    createSale,
    getSalesList,
    getSaleById,
    updateSale,
    deleteSaleById,
} from '../../../ducks/sale';
import { getBankAccountList } from '../../../ducks/bankAccount';
import { getProfile } from '../../../ducks/session';
import { getTeamList } from '../../../ducks/team';

const mapStateToProps = (state) => {
    return {
        session: state.session,
        sale: state.sale,
        bankAccount: state.bankAccount,
        team: state.team,
    };
};

const SalesDashboardContainer = connect(mapStateToProps, {
    createSale,
    getSalesList,
    getSaleById,
    updateSale,
    deleteSaleById,
    getProfile,
    getTeamList,
    getBankAccountList,
})(SalesDashboard);
export default SalesDashboardContainer;
