import React, { Component } from 'react';
import { Button, Input, Divider, message, notification, DatePicker, Tooltip, Select } from 'antd';
import moment from 'moment';
import {
    LeftOutlined,
    PlusOutlined,
    DeleteOutlined,
    SaveOutlined,
    BankOutlined,
    BorderlessTableOutlined,
    CalculatorOutlined,
} from '@ant-design/icons';
import {
    Container,
    ButtonContainer,
    FormContainer,
    ButtonsContainer,
    DateContainer,
    ProfitLabel,
    ValueLabel,
    WarningLabel,
} from './index.style';
import { Constant, Utility } from '../../../helpers';

const { Option } = Select;
class SalesDashboard extends Component {
    state = {
        mode: null,
        date: null,
        sales: null,
        ads: null,
        expenses: null,
        isLoading: false,
        teamId: null,
        bankId: null,
        bank: null,
        transferType: null,
        teamList: [],
        expenseCategory: null,
    };
    componentDidMount = async () => {
        const mode =
            Object.keys(this.props.match.params).length > 0
                ? Constant.PAGE_MODE.edit
                : Constant.PAGE_MODE.create;
        await this.setState({ mode });
        await this.props.getProfile();
        await this.props.getBankAccountList({ page_size: 50 });
        if (mode === Constant.PAGE_MODE.edit) {
            const id = this.props.match.params.id;
            await this.props.getSaleById({ id });
        }
        if (this.props.session.permissions.includes(Constant.USER_PERMISSIONS.manage_team)) {
            await this.props.getTeamList({ page_size: 50 });
        } else {
            let teamList = [];
            this.props.session.teamList.map((teamObj) => {
                return teamList.push(teamObj.team);
            });
            this.setState({ teamList });
        }
    };
    componentDidUpdate = (prevProps) => {
        if (this.props.sale.error && this.props.sale.error !== prevProps.sale.error) {
            this.openNotificationWithIcon({
                type: 'error',
                title: this.props.sale.error.frontend_type,
                message: this.props.sale.error.message,
            });
            this.setState({
                isLoading: this.props.sale.isLoading,
            });
        }
        if (prevProps.team.list !== this.props.team.list) {
            this.setState({
                teamList: this.props.team.list,
                isLoading: false,
            });
        }
        if (
            this.props.bankAccount.error &&
            this.props.bankAccount.error !== prevProps.bankAccount.error
        ) {
            this.openNotificationWithIcon({
                type: 'error',
                title: this.props.bankAccount.error.frontend_type,
                message: this.props.bankAccount.error.message,
            });
            this.setState({
                isLoading: this.props.bankAccount.isLoading,
            });
        }
        if (prevProps.sale.current !== this.props.sale.current) {
            const sale = this.props.sale.current;
            this.setState({
                date: moment(sale.salesDate),
                sales: String(sale.salesAmount),
                ads: String(sale.adsAmount),
                expenses: String(sale.expensesAmount),
                bankId: sale.bankAccountId,
                transferType: sale.transferType,
                bank: this.props.bankAccount.list.find((bank) => bank.id === sale.bankAccountId),
                isLoading: false,
                teamId: sale.teamId,
                expenseCategory: sale.expenseCategory,
            });
        }
        if (prevProps.session.teamList !== this.props.session.teamList) {
            const teamList = this.props.session.teamList;
            if (teamList.length === 1) {
                this.setState({
                    teamId: teamList[0].team.id,
                });
            }
        }
    };
    openNotificationWithIcon = ({ type, title, message }) => {
        notification[type]({
            message: title,
            description: message,
        });
    };
    onDateChanged = (date) => {
        this.setState({
            date,
        });
    };
    onTransferTypeChanged = (transferType) => {
        this.setState({ transferType });
    };
    onExpenseCategoryChanged = (expenseCategory) => {
        this.setState({ expenseCategory: expenseCategory || null });
    };
    onTeamChanged = (team) => {
        this.setState({ teamId: team });
    };
    onBankChanged = (bank) => {
        this.setState({
            bankId: bank,
            bank: this.props.bankAccount.list.find((bankObj) => bankObj.id === bank),
        });
    };
    onBackButtonPressed = () => {
        this.props.history.replace('/sale');
    };
    onSalesChanged = ({ text }) => {
        if (Utility.numberRegexCheck(text) || Utility.decimalNumberRegexCheck(text)) {
            this.setState({ sales: text });
            return;
        }
        this.setState({ sales: null });
        return;
    };
    onAdsChanged = ({ text }) => {
        if (Utility.numberRegexCheck(text) || Utility.decimalNumberRegexCheck(text)) {
            this.setState({ ads: text });
            return;
        }
        this.setState({ ads: null });
        return;
    };
    onExpensesChanged = ({ text }) => {
        if (Utility.numberRegexCheck(text) || Utility.decimalNumberRegexCheck(text)) {
            this.setState({ expenses: text });
            return;
        }
        this.setState({ expenses: null });
        return;
    };
    onDeleteButtonPressed = async () => {
        this.setState({ isLoading: true });
        await this.props.deleteSaleById({
            id: this.props.sale.current.id,
        });
        await this.props.getSalesList();
        message.success('Sale deleted!');
        this.onBackButtonPressed();
    };
    onFormValidation = () => {
        if (!this.state.teamId) {
            message.error('Team cannot be empty');
            return false;
        }
        if (!this.state.bankId) {
            message.error('Bank cannot be empty');
            return false;
        }
        if (!this.state.transferType) {
            message.error('Transfer Type cannot be empty');
            return false;
        }
        if (!this.state.date) {
            message.error('Date cannot be empty');
            return false;
        }
        if (!this.state.sales) {
            message.error('Sales cannot be empty');
            return false;
        }
        if (!this.state.ads) {
            message.error('Ads cannot be empty');
            return false;
        }
        if (!this.state.expenses) {
            message.error('Expenses cannot be empty');
            return false;
        }
        if (this.state.expenses > 0 && !this.state.expenseCategory) {
            message.error('Expense category cannot be empty');
            return false;
        }
        return true;
    };
    onSaveButtonPressed = async () => {
        if (this.onFormValidation()) {
            this.setState({ isLoading: true });
            await this.props.updateSale({
                id: this.props.sale.current.id,
                sales_amount: this.state.sales * (1 - (this.state.bank?.percentage || 0)),
                ads_amount: this.state.ads,
                expenses_amount: this.state.expenses,
                sales_date: moment(this.state.date).format('YYYY-MM-DD'),
                team_id: this.state.teamId,
                bank_account_id: this.state.bankId,
                transfer_type: this.state.transferType,
                expense_category: this.state.expenseCategory,
            });
            await this.props.getSalesList();
            message.success('Sale updated!');
            this.onBackButtonPressed();
        }
    };
    onCreateButtonPressed = async () => {
        if (this.onFormValidation()) {
            this.setState({ isLoading: true });
            await this.props.createSale({
                sales_amount: this.state.sales * (1 - (this.state.bank?.percentage || 0)),
                ads_amount: this.state.ads,
                expenses_amount: this.state.expenses,
                sales_date: moment(this.state.date).format('YYYY-MM-DD'),
                team_id: this.state.teamId,
                bank_account_id: this.state.bankId,
                transfer_type: this.state.transferType,
                expense_category: this.state.expenseCategory,
            });
            await this.props.getSalesList();
            message.success('Sale created!');
            this.onBackButtonPressed();
        }
    };
    disabledDate = (current) => {
        return current && current > moment().endOf('day');
    };
    render() {
        const EOD =
            parseFloat(this.state.sales) * (1 - (this.state.bank?.percentage || 0)) -
            (parseFloat(this.state.ads) + parseFloat(this.state.expenses));
        return (
            <Container>
                <ButtonContainer>
                    <Button type="link" size={'large'} onClick={this.onBackButtonPressed}>
                        <LeftOutlined style={{ paddingRight: '10px' }} />
                        <b>Back</b>
                    </Button>
                </ButtonContainer>
                <FormContainer>
                    <Divider orientation="left">Sale Details</Divider>
                    <br />
                    <DateContainer>
                        <DatePicker
                            value={this.state.date}
                            style={{ width: 250 }}
                            placeholder={'Pick a Date'}
                            disabledDate={this.disabledDate}
                            onChange={this.onDateChanged}
                            size={'large'}
                            format={'DD/MM/YYYY'}
                        />
                    </DateContainer>
                    <br />
                    <DateContainer>
                        <Select
                            size={'large'}
                            showSearch
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            placeholder={'Select a Team'}
                            onChange={this.onTeamChanged}
                            style={{ width: 250 }}
                            value={this.state.teamId || 'Select a Team'}
                        >
                            {this.state.teamList.map((team) => {
                                return (
                                    <Option key={team.id} value={team.id}>
                                        {team.name}
                                    </Option>
                                );
                            })}
                        </Select>
                    </DateContainer>
                    <br />
                    <DateContainer>
                        <Select
                            size={'large'}
                            showSearch
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            placeholder={'Select a Bank'}
                            onChange={this.onBankChanged}
                            style={{ width: 250 }}
                            value={this.state.bankId || 'Select a Bank'}
                        >
                            {this.props.bankAccount.list.map((bank) => {
                                return (
                                    <Option key={bank.id} value={bank.id}>
                                        {bank.name}
                                    </Option>
                                );
                            })}
                        </Select>
                    </DateContainer>
                    <br />
                    <DateContainer>
                        <Select
                            size={'large'}
                            showSearch
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            placeholder={'Select a Transfer Type'}
                            onChange={this.onTransferTypeChanged}
                            style={{ width: 250 }}
                            value={this.state.transferType || 'Select a Transfer Type'}
                        >
                            <Option
                                key={Constant.TRANSFER_TYPE.instant}
                                value={Constant.TRANSFER_TYPE.instant}
                            >
                                INSTANT
                            </Option>
                            <Option
                                key={Constant.TRANSFER_TYPE.ibg}
                                value={Constant.TRANSFER_TYPE.ibg}
                            >
                                IBG
                            </Option>
                        </Select>
                    </DateContainer>
                    <br />
                    <DateContainer>
                        <Select
                            allowClear
                            size={'large'}
                            showSearch
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            placeholder={'Select a Expense Category'}
                            onChange={this.onExpenseCategoryChanged}
                            style={{ width: 250 }}
                            value={this.state.expenseCategory || 'Select a Expense Category'}
                        >
                            {Object.values(Constant.EXPENSE_CATEGORY).map((ec) => (
                                <Option key={ec} value={ec}>
                                    {ec}
                                </Option>
                            ))}
                        </Select>
                    </DateContainer>
                    <br />
                    {this.state.bank && this.state.bank.limitUsedPercentage >= 80 && (
                        <DateContainer>
                            <WarningLabel>
                                This bank account has used up RM
                                {Utility.convertNumberToStringCommaNumber(
                                    this.state.bank.amountLimit *
                                        (this.state.bank.limitUsedPercentage / 100).toFixed(2)
                                )}{' '}
                                of RM$
                                {Utility.convertNumberToStringCommaNumber(
                                    this.state.bank.amountLimit
                                )}{' '}
                                quota!
                            </WarningLabel>
                        </DateContainer>
                    )}
                    <br />
                    <Tooltip placement="left" title="Sales Amount">
                        <Input
                            maxLength={12}
                            value={this.state.sales}
                            onChange={(event) => this.onSalesChanged({ text: event.target.value })}
                            size="large"
                            placeholder="Sales Amount"
                            prefix={<BankOutlined />}
                            suffix={'MYR'}
                        />
                    </Tooltip>
                    <br />
                    <Tooltip placement="left" title="Ads Amount">
                        <Input
                            maxLength={12}
                            value={this.state.ads}
                            onChange={(event) => this.onAdsChanged({ text: event.target.value })}
                            size="large"
                            placeholder="Ads Amount"
                            prefix={<BorderlessTableOutlined />}
                            suffix={'MYR'}
                        />
                    </Tooltip>
                    <br />
                    <Tooltip placement="left" title="Expenses Amount">
                        <Input
                            maxLength={12}
                            value={this.state.expenses}
                            onChange={(event) =>
                                this.onExpensesChanged({ text: event.target.value })
                            }
                            size="large"
                            placeholder="Expenses Amount"
                            prefix={<CalculatorOutlined />}
                            suffix={'MYR'}
                        />
                    </Tooltip>
                    <br />
                    <br />
                    <ProfitLabel>Total Profit/Loss</ProfitLabel>
                    <ValueLabel EOD={EOD}>
                        RM {EOD ? Utility.convertNumberToStringCommaNumber(EOD.toFixed(2)) : '0'}
                    </ValueLabel>
                    <Divider />
                    {this.state.mode === Constant.PAGE_MODE.create ? (
                        <Button
                            type="primary"
                            size={'large'}
                            onClick={this.onCreateButtonPressed}
                            loading={this.state.isLoading}
                        >
                            <PlusOutlined style={{ paddingRight: '10px' }} />
                            <b>CREATE</b>
                        </Button>
                    ) : (
                        <ButtonsContainer>
                            <Button
                                type="primary"
                                size={'large'}
                                onClick={this.onSaveButtonPressed}
                                loading={this.state.isLoading}
                                style={{ marginRight: 10 }}
                            >
                                <SaveOutlined style={{ paddingRight: '10px' }} />
                                <b>SAVE</b>
                            </Button>
                            {this.props.session.permissions.includes(
                                Constant.USER_PERMISSIONS.verify_sale
                            ) && (
                                <Button
                                    type="danger"
                                    size={'large'}
                                    onClick={this.onDeleteButtonPressed}
                                    loading={this.state.isLoading}
                                >
                                    <DeleteOutlined style={{ paddingRight: '10px' }} />
                                    <b>DELETE</b>
                                </Button>
                            )}
                        </ButtonsContainer>
                    )}
                </FormContainer>
            </Container>
        );
    }
}

export default SalesDashboard;
