export default class User {
    constructor(user) {
        this.id = user.id;
        this.username = user.username;
        this.firstName = user.first_name;
        this.lastName = user.last_name;
        this.userType = user.user_type;
        this.status = user.status;
        this.phoneNumber = user.phone_number;
        this.team =
            user.team_members && user.team_members.length > 0 ? user.team_members[0].team : null;
        this.commissionsTier = user.commissions_tier;
    }
}
