import BankAccountService from '../services/bankAccountService';
// Actions
const GET_BANK_LIST_REQUEST = 'vip/bank/GET_BANK_LIST_REQUEST';
const GET_BANK_LIST_SUCCESS = 'vip/bank/GET_BANK_LIST_SUCCESS';
const GET_BANK_LIST_FAIL = 'vip/bank/GET_BANK_LIST_FAIL';
const GET_BANK_ACCOUNT_LIST_REQUEST = 'vip/bank/GET_BANK_ACCOUNT_LIST_REQUEST';
const GET_BANK_ACCOUNT_LIST_SUCCESS = 'vip/bank/GET_BANK_ACCOUNT_LIST_SUCCESS';
const GET_BANK_ACCOUNT_LIST_FAIL = 'vip/bank/GET_BANK_ACCOUNT_LIST_FAIL';
const GET_BANK_ACCOUNT_REQUEST = 'vip/bank/GET_BANK_ACCOUNT_REQUEST';
const GET_BANK_ACCOUNT_SUCCESS = 'vip/bank/GET_BANK_ACCOUNT_SUCCESS';
const GET_BANK_ACCOUNT_FAIL = 'vip/bank/GET_BANK_ACCOUNT_FAIL';
const DELETE_BANK_ACCOUNT_REQUEST = 'vip/bank/DELETE_BANK_ACCOUNT_REQUEST';
const DELETE_BANK_ACCOUNT_SUCCESS = 'vip/bank/DELETE_BANK_ACCOUNT_SUCCESS';
const DELETE_BANK_ACCOUNT_FAIL = 'vip/bank/DELETE_BANK_ACCOUNT_FAIL';
const POST_CREATE_BANK_ACCOUNT_REQUEST = 'vip/bank/POST_CREATE_BANK_ACCOUNT_REQUEST';
const POST_CREATE_BANK_ACCOUNT_SUCCESS = 'vip/bank/POST_CREATE_BANK_ACCOUNT_SUCCESS';
const POST_CREATE_BANK_ACCOUNT_FAIL = 'vip/bank/POST_CREATE_BANK_ACCOUNT_FAIL';
const PATCH_EDIT_BANK_ACCOUNT_REQUEST = 'vip/bank/PATCH_EDIT_BANK_ACCOUNT_REQUEST';
const PATCH_EDIT_BANK_ACCOUNT_SUCCESS = 'vip/bank/PATCH_EDIT_BANK_ACCOUNT_SUCCESS';
const PATCH_EDIT_BANK_ACCOUNT_FAIL = 'vip/bank/PATCH_EDIT_BANK_ACCOUNT_FAIL';
const SESSION_TOKEN_EXPIRED = 'vip/session/SESSION_TOKEN_EXPIRED';

// Reducer
const INITIAL_STATE = {
    error: null,
    isLoading: false,
    current: null,
    list: [],
    bankList: [],
    pagination: null,
};

export default function reducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SESSION_TOKEN_EXPIRED: {
            return INITIAL_STATE;
        }
        case GET_BANK_LIST_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case GET_BANK_LIST_SUCCESS: {
            return {
                ...state,
                bankList: action.payload.results,
                pagination: action.payload.pagination,
                isLoading: false,
                error: null,
            };
        }
        case GET_BANK_LIST_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case GET_BANK_ACCOUNT_LIST_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case GET_BANK_ACCOUNT_LIST_SUCCESS: {
            return {
                ...state,
                list: action.payload.results,
                pagination: action.payload.pagination,
                isLoading: false,
                error: null,
            };
        }
        case GET_BANK_ACCOUNT_LIST_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case GET_BANK_ACCOUNT_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case GET_BANK_ACCOUNT_SUCCESS: {
            return {
                ...state,
                current: action.payload,
                isLoading: false,
                error: null,
            };
        }
        case GET_BANK_ACCOUNT_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case DELETE_BANK_ACCOUNT_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case DELETE_BANK_ACCOUNT_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: null,
            };
        }
        case DELETE_BANK_ACCOUNT_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case POST_CREATE_BANK_ACCOUNT_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case POST_CREATE_BANK_ACCOUNT_SUCCESS: {
            return {
                ...state,
                current: action.payload,
                isLoading: false,
                error: null,
            };
        }
        case POST_CREATE_BANK_ACCOUNT_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case PATCH_EDIT_BANK_ACCOUNT_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case PATCH_EDIT_BANK_ACCOUNT_SUCCESS: {
            return {
                ...state,
                current: action.payload,
                isLoading: false,
                error: null,
            };
        }
        case PATCH_EDIT_BANK_ACCOUNT_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        default:
            return state;
    }
}

// Action Creators
export function getBankListRequest() {
    return {
        type: GET_BANK_LIST_REQUEST,
    };
}
export function getBankListSuccess(payload) {
    return {
        type: GET_BANK_LIST_SUCCESS,
        payload,
    };
}
export function getBankListFail(error) {
    return {
        type: GET_BANK_LIST_FAIL,
        error,
    };
}
export function getBankAccountListRequest() {
    return {
        type: GET_BANK_ACCOUNT_LIST_REQUEST,
    };
}
export function getBankAccountListSuccess(payload) {
    return {
        type: GET_BANK_ACCOUNT_LIST_SUCCESS,
        payload,
    };
}
export function getBankAccountListFail(error) {
    return {
        type: GET_BANK_ACCOUNT_LIST_FAIL,
        error,
    };
}
export function getBankRequest() {
    return {
        type: GET_BANK_ACCOUNT_REQUEST,
    };
}
export function getBankSuccess(payload) {
    return {
        type: GET_BANK_ACCOUNT_SUCCESS,
        payload,
    };
}
export function getBankFail(error) {
    return {
        type: GET_BANK_ACCOUNT_FAIL,
        error,
    };
}
export function deleteBankAccountRequest() {
    return {
        type: DELETE_BANK_ACCOUNT_REQUEST,
    };
}
export function deleteBankAccountSuccess() {
    return {
        type: DELETE_BANK_ACCOUNT_SUCCESS,
    };
}
export function deleteBankAccountFail(error) {
    return {
        type: DELETE_BANK_ACCOUNT_FAIL,
        error,
    };
}
export function updateBankAccountRequest() {
    return {
        type: PATCH_EDIT_BANK_ACCOUNT_REQUEST,
    };
}
export function updateBankAccountSuccess(payload) {
    return {
        type: PATCH_EDIT_BANK_ACCOUNT_SUCCESS,
        payload,
    };
}
export function updateBankAccountFail(error) {
    return {
        type: PATCH_EDIT_BANK_ACCOUNT_FAIL,
        error,
    };
}
export function createBankAccountRequest() {
    return {
        type: POST_CREATE_BANK_ACCOUNT_REQUEST,
    };
}
export function createBankAccountSuccess(payload) {
    return {
        type: POST_CREATE_BANK_ACCOUNT_SUCCESS,
        payload,
    };
}
export function createBankAccountFail(error) {
    return {
        type: POST_CREATE_BANK_ACCOUNT_FAIL,
        error,
    };
}
// side effects, only as applicable
// e.g. thunks, epics, etc
export const getBankList = ({ page_size, page_number } = {}) => async (dispatch) => {
    dispatch(getBankListRequest());
    try {
        const list = await BankAccountService.getBankList({ page_size, page_number });
        dispatch(getBankListSuccess(list));
    } catch (error) {
        dispatch(getBankListFail(error));
        throw error;
    }
};
export const getBankAccountList = ({ page_size, page_number } = {}) => async (dispatch) => {
    dispatch(getBankAccountListRequest());
    try {
        const list = await BankAccountService.getBankAccountList({ page_size, page_number });
        dispatch(getBankAccountListSuccess(list));
    } catch (error) {
        dispatch(getBankAccountListFail(error));
        throw error;
    }
};
export const createBankAccount = ({
    bank_id,
    name,
    account_number,
    amount_limit,
    percentage,
}) => async (dispatch) => {
    dispatch(createBankAccountRequest());
    try {
        const result = await BankAccountService.createBankAccount({
            bank_id,
            name,
            account_number,
            amount_limit,
            percentage,
        });
        dispatch(createBankAccountSuccess(result));
    } catch (error) {
        dispatch(createBankAccountFail(error));
        throw error;
    }
};
export const getBankAccountById = ({ id }) => async (dispatch) => {
    dispatch(getBankRequest());
    try {
        const result = await BankAccountService.getBankAccountById({
            id,
        });
        dispatch(getBankSuccess(result));
    } catch (error) {
        dispatch(getBankFail(error));
        throw error;
    }
};
export const updateBankAccount = ({
    id,
    bank_id,
    name,
    account_number,
    amount_limit,
    percentage,
}) => async (dispatch) => {
    dispatch(updateBankAccountRequest());
    try {
        const result = await BankAccountService.updateBankAccount({
            id,
            bank_id,
            name,
            account_number,
            amount_limit,
            percentage,
        });
        dispatch(updateBankAccountSuccess(result));
    } catch (error) {
        dispatch(updateBankAccountFail(error));
        throw error;
    }
};
export const deleteBankAccountById = ({ id }) => async (dispatch) => {
    dispatch(deleteBankAccountRequest());
    try {
        await BankAccountService.deleteBankAccountById({
            id,
        });
        dispatch(deleteBankAccountSuccess());
    } catch (error) {
        dispatch(deleteBankAccountFail(error));
        throw error;
    }
};
