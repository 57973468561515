import { connect } from 'react-redux';
import AdsManagerManagement from './AdsManagerManagement';
import { getUserList } from '../../ducks/user';

const mapStateToProps = (state) => {
    return {
        session: state.session,
        user: state.user,
    };
};

const AdsManagerManagementContainer = connect(mapStateToProps, {
    getUserList,
})(AdsManagerManagement);
export default AdsManagerManagementContainer;
