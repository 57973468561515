import React, { Component } from 'react';
import { Button, Input, Divider, message, notification } from 'antd';
import {
    LeftOutlined,
    PlusOutlined,
    SaveOutlined,
    DeleteOutlined,
    NotificationOutlined,
} from '@ant-design/icons';
import { Container, ButtonContainer, FormContainer, ButtonsContainer } from './index.style';
import { Constant } from '../../../helpers';
const { TextArea } = Input;
class AnnouncementDashboard extends Component {
    state = {
        mode: null,
        title: '',
        content: '',
        isLoading: false,
    };
    componentDidMount = async () => {
        const mode =
            Object.keys(this.props.match.params).length > 0
                ? Constant.PAGE_MODE.edit
                : Constant.PAGE_MODE.create;
        await this.setState({ mode });
        if (mode === Constant.PAGE_MODE.edit) {
            const id = this.props.match.params.id;
            await this.props.getAnnouncementById({ id });
        }
    };
    componentDidUpdate = (prevProps) => {
        if (
            this.props.announcement.error &&
            this.props.announcement.error !== prevProps.announcement.error
        ) {
            this.openNotificationWithIcon({
                type: 'error',
                title: this.props.announcement.error.frontend_type,
                message: this.props.announcement.error.message,
            });
            this.setState({
                isLoading: this.props.announcement.isLoading,
            });
        }
        if (prevProps.announcement.current !== this.props.announcement.current) {
            const announcement = this.props.announcement.current;
            if (!this.state.leader) {
                this.setState({
                    title: announcement.title || '',
                    content: announcement.content,
                    isLoading: false,
                });
            }
        }
    };
    openNotificationWithIcon = ({ type, title, message }) => {
        notification[type]({
            message: title,
            description: message,
        });
    };
    onBackButtonPressed = () => {
        this.props.history.replace('/announcement');
    };
    onTitleChanged = ({ text }) => {
        this.setState({ title: text });
    };
    onContentChanged = ({ text }) => {
        this.setState({ content: text });
    };
    onFormValidation = () => {
        let canSubmit = true;
        if (this.state.title.trim().length === 0) {
            message.error('Title cannot be empty');
            return false;
        }
        if (this.state.content.trim().length === 0) {
            message.error('Content cannot be empty');
            return false;
        }
        return canSubmit;
    };
    onSaveButtonPressed = async () => {
        if (this.onFormValidation()) {
            this.setState({ isLoading: true });
            await this.props.updateAnnouncement({
                id: this.props.announcement.current.id,
                title: this.state.title,
                content: this.state.content,
            });
            await this.props.getAnnouncementList();
            message.success('Announcement updated!');
            this.onBackButtonPressed();
        }
    };
    onDeleteButtonPressed = async () => {
        this.setState({ isLoading: true });
        await this.props.deleteAnnouncementById({
            id: this.props.announcement.current.id,
        });
        await this.props.getAnnouncementList();
        message.success('Announcement deleted!');
        this.onBackButtonPressed();
    };
    onCreateButtonPressed = async () => {
        if (this.onFormValidation()) {
            this.setState({ isLoading: true });
            await this.props.createAnnouncement({
                title: this.state.title,
                content: this.state.content,
            });
            await this.props.getAnnouncementList();
            message.success('Announcement created!');
            this.onBackButtonPressed();
        }
    };
    render() {
        return (
            <Container>
                <ButtonContainer>
                    <Button type="link" size={'large'} onClick={this.onBackButtonPressed}>
                        <LeftOutlined style={{ paddingRight: '10px' }} />
                        <b>Back</b>
                    </Button>
                </ButtonContainer>
                <FormContainer>
                    <Divider orientation="left">Announcement Details</Divider>
                    <br />
                    <Input
                        maxLength={50}
                        value={this.state.title}
                        onChange={(event) => this.onTitleChanged({ text: event.target.value })}
                        size="large"
                        placeholder="Title"
                        prefix={<NotificationOutlined />}
                    />
                    <br />
                    <TextArea
                        value={this.state.content}
                        onChange={(event) => this.onContentChanged({ text: event.target.value })}
                        placeholder="Content"
                        autoSize={{ minRows: 3, maxRows: 5 }}
                    />
                    <br />
                    <Divider />
                    {this.state.mode === Constant.PAGE_MODE.create ? (
                        <Button
                            type="primary"
                            size={'large'}
                            onClick={this.onCreateButtonPressed}
                            loading={this.state.isLoading}
                        >
                            <PlusOutlined style={{ paddingRight: '10px' }} />
                            <b>CREATE</b>
                        </Button>
                    ) : (
                        <ButtonsContainer>
                            <Button
                                type="primary"
                                size={'large'}
                                onClick={this.onSaveButtonPressed}
                                loading={this.state.isLoading}
                                style={{ marginRight: 10 }}
                            >
                                <SaveOutlined style={{ paddingRight: '10px' }} />
                                <b>SAVE</b>
                            </Button>
                            <Button
                                type="danger"
                                size={'large'}
                                onClick={this.onDeleteButtonPressed}
                                loading={this.state.isLoading}
                            >
                                <DeleteOutlined style={{ paddingRight: '10px' }} />
                                <b>DELETE</b>
                            </Button>
                        </ButtonsContainer>
                    )}
                </FormContainer>
            </Container>
        );
    }
}

export default AnnouncementDashboard;
