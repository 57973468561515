import { connect } from 'react-redux';
import BankManagement from './BankManagement';
import { getBankAccountList, getBankList } from '../../ducks/bankAccount';

const mapStateToProps = (state) => {
    return {
        session: state.session,
        bankAccount: state.bankAccount,
    };
};

const BankManagementContainer = connect(mapStateToProps, {
    getBankAccountList,
    getBankList,
})(BankManagement);
export default BankManagementContainer;
