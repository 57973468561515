import { connect } from 'react-redux';
import MemberDashboard from './MemberDashboard';
import { createMember, getUserById, updateUser, getUserList } from '../../../ducks/user';
import { getProfile } from '../../../ducks/session';
const mapStateToProps = (state) => {
    return {
        session: state.session,
        user: state.user,
    };
};

const MemberDashboardContainer = connect(mapStateToProps, {
    createMember,
    getUserById,
    updateUser,
    getUserList,
    getProfile,
})(MemberDashboard);
export default MemberDashboardContainer;
