import { connect } from 'react-redux';
import AccountantManagement from './AccountantManagement';
import { getUserList } from '../../ducks/user';

const mapStateToProps = (state) => {
    return {
        session: state.session,
        user: state.user,
    };
};

const AccountantManagementContainer = connect(mapStateToProps, {
    getUserList,
})(AccountantManagement);
export default AccountantManagementContainer;
