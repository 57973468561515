import { connect } from 'react-redux';
import TeamDashboard from './TeamDashboard';
import {
    createTeam,
    getTeamList,
    getTeamById,
    updateTeam,
    deleteTeamById,
} from '../../../ducks/team';
import { getUserList } from '../../../ducks/user';

const mapStateToProps = (state) => {
    return {
        session: state.session,
        team: state.team,
        user: state.user,
    };
};

const TeamDashboardContainer = connect(mapStateToProps, {
    createTeam,
    getTeamList,
    getTeamById,
    updateTeam,
    deleteTeamById,
    getUserList,
})(TeamDashboard);
export default TeamDashboardContainer;
