import { Constant } from './';
const Utility = {
    logoutSession() {
        return localStorage.clear();
    },
    getItem(itemKey) {
        return JSON.parse(localStorage.getItem(itemKey));
    },
    setItem({ key, storeObject }) {
        return localStorage.setItem(key, JSON.stringify(storeObject));
    },
    getSession() {
        if (localStorage.getItem('session') !== 'undefined' || localStorage.getItem('session')) {
            return JSON.parse(localStorage.getItem('session'));
        }
        return null;
    },
    hasSession() {
        if (this.getSession() && this.getSession().userToken && this.getSession().isLoggedIn) {
            return true;
        }
        return false;
    },
    isAdmin() {
        if (this.getSession() && this.getSession().userType === Constant.USER_TYPE.admin) {
            return true;
        }
        return false;
    },
    isAgent() {
        if (this.getSession() && this.getSession().userType === Constant.USER_TYPE.agent) {
            return true;
        }
        return false;
    },
    isAccountant() {
        if (this.getSession() && this.getSession().userType === Constant.USER_TYPE.accountant) {
            return true;
        }
        return false;
    },
    isMember() {
        if (this.getSession() && this.getSession().userType === Constant.USER_TYPE.member) {
            return true;
        }
        return false;
    },
    isAdsManager() {
        if (this.getSession() && this.getSession().userType === Constant.USER_TYPE.ads_manager) {
            return true;
        }
        return false;
    },
    getEnvironment() {
        return String(process.env.REACT_APP_ENVIRONMENT);
    },
    resolvePromise(promise) {
        return promise.then((data) => [null, data]).catch((err) => [err, null]);
    },
    nameRegexCheck(name) {
        const nameRegex = /^[-'a-z\u0E00-\u0E7F\u4e00-\u9eff ]{1,30}$/i;
        return nameRegex.test(name);
    },
    emailRegexCheck(email) {
        // eslint-disable-next-line no-control-regex
        const emailRegex = /^(?:[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[A-Za-z0-9-]*[A-Za-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
        return emailRegex.test(email);
    },
    isNumericRegexCheck(number) {
        const isNumbericRegex = /^-{0,1}\d*\.{0,1}\d+$/;
        return isNumbericRegex.test(number);
    },
    usernameRegexCheck(username) {
        const usernameRegex = /^[a-z0-9_-]{3,16}$/;
        return usernameRegex.test(username);
    },
    urlRegexCheck(url) {
        const urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
        return urlRegex.test(url);
    },
    numberRegexCheck(number) {
        const numberRegex = /^\d+$/;
        return numberRegex.test(number);
    },
    malaysiaPhoneNumberRegexCheck(number) {
        const numberRegex = /^(\ ?01)[0|1|2|3|4|6|7|8|9]\-*[0-9]{7,8}$/;
        return numberRegex.test(number);
    },
    decimalNumberRegexCheck(number) {
        const numberRegex = /^\d+\.?\d{0,2}$/;
        return numberRegex.test(number);
    },
    countWords(string) {
        let targetString = string;
        targetString = targetString.replace(/(^\s*)|(\s*$)/gi, '');
        targetString = targetString.replace(/[ ]{2,}/gi, ' ');
        targetString = targetString.replace(/\n /, '\n');
        return targetString.split(' ').length;
    },
    convertNumberToStringCommaNumber(number) {
        return String(number).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
};

export { Utility };
