import React, { Component } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { message } from 'antd';
import { connect } from 'react-redux';

import { getProfile } from '../ducks/session';
import { Utility, Constant } from '../helpers';

import NotFound from '../containers/NotFound';
import SignInContainer from '../containers/SignIn';
import Landing from '../containers/Landing';

import AgentManagement from '../containers/AgentManagement';
import AgentDashboard from '../containers/AgentManagement/AgentDashboard';

import AccountantManagement from '../containers/AccountantManagement';
import AccountantDashboard from '../containers/AccountantManagement/AccountantDashboard';

import MemberManagement from '../containers/MemberManagement';
import MemberDashboard from '../containers/MemberManagement/MemberDashboard';

import VipLink from '../containers/VipLink';
import VipLinkDashboard from '../containers/VipLink/VipLinkDashboard';

import TeamManagement from '../containers/TeamManagement';
import TeamDashboard from '../containers/TeamManagement/TeamDashboard';

import CSReportManagement from '../containers/CSReportManagement';
import CSReportDashboard from '../containers/CSReportManagement/CSReportDashboard';

import AnnouncementManagement from '../containers/AnnouncementManagement';
import AnnouncementDashboard from '../containers/AnnouncementManagement/AnnouncementDashboard';

import Sales from '../containers/Sales';
import SalesDashboard from '../containers/Sales/SalesDashboard';

import PhoneRecords from '../containers/PhoneRecords';
import Report from '../containers/Report';

import BankManagement from '../containers/BankManagement';
import BankDashboard from '../containers/BankManagement/BankDashboard';

import Announcement from '../containers/Announcement';
import ClockIn from '../containers/ClockIn';

import VipLinkClient from '../containers/VipLinkClient';

import Ads from '../containers/Ads';
import AdsDashboard from '../containers/Ads/AdsDashboard';

import Enquiry from '../containers/Enquiry';
import EnquiryDashboard from '../containers/Enquiry/EnquiryDashboard';

import AdsManagerManagement from '../containers/AdsManagerManagement';
import AdsManagerDashboard from '../containers/AdsManagerManagement/AdsManagerDashboard';

const AuthenticatedRoute = ({ component: Component, isAuthorized, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) => {
                if (Utility.hasSession() && isAuthorized) {
                    const pathName = props.location.pathname;
                    const pathNamePart = pathName.split('/');
                    Utility.setItem({
                        key: 'selectedMenu',
                        storeObject: `${pathNamePart[0]}${pathNamePart[1]}`,
                    });
                    return (
                        <Landing {...props}>
                            <Component {...props} />
                        </Landing>
                    );
                }
                message.error('Unauthorized Access');
                return (
                    <Redirect
                        to={{
                            pathname: '/signin',
                        }}
                    />
                );
            }}
        />
    );
};
class Routes extends Component {
    componentDidMount = async () => {
        if (Utility.hasSession()) {
            await this.props.getProfile();
        }
    };
    render() {
        const permissions = this.props.session.permissions || [];
        return (
            <ConnectedRouter history={this.props.history}>
                <Switch>
                    <Redirect exact from="/" to="/sale" />
                    <Route exact path={'/signin'} component={SignInContainer} />
                    <AuthenticatedRoute
                        exact
                        path="/clock-in"
                        component={ClockIn}
                        isAuthorized={permissions.some((permission) =>
                            [Constant.USER_PERMISSIONS.manage_user_clock].includes(permission)
                        )}
                    />
                    <AuthenticatedRoute
                        exact
                        path="/announcement"
                        component={AnnouncementManagement}
                        isAuthorized={permissions.some((permission) =>
                            [Constant.USER_PERMISSIONS.manage_announcement].includes(permission)
                        )}
                    />
                    <AuthenticatedRoute
                        exact
                        path="/announcement/create"
                        component={AnnouncementDashboard}
                        isAuthorized={permissions.some((permission) =>
                            [Constant.USER_PERMISSIONS.manage_announcement].includes(permission)
                        )}
                    />
                    <AuthenticatedRoute
                        exact
                        path="/announcement/edit/:id"
                        component={AnnouncementDashboard}
                        isAuthorized={permissions.some((permission) =>
                            [Constant.USER_PERMISSIONS.manage_announcement].includes(permission)
                        )}
                    />
                    <AuthenticatedRoute
                        exact
                        path="/bank"
                        component={BankManagement}
                        isAuthorized={permissions.some((permission) =>
                            [Constant.USER_PERMISSIONS.manage_bank_account].includes(permission)
                        )}
                    />
                    <AuthenticatedRoute
                        exact
                        path="/bank/create"
                        component={BankDashboard}
                        isAuthorized={permissions.some((permission) =>
                            [Constant.USER_PERMISSIONS.manage_bank_account].includes(permission)
                        )}
                    />
                    <AuthenticatedRoute
                        exact
                        path="/bank/edit/:id"
                        component={BankDashboard}
                        isAuthorized={permissions.some((permission) =>
                            [Constant.USER_PERMISSIONS.manage_bank_account].includes(permission)
                        )}
                    />
                    <AuthenticatedRoute
                        exact
                        path="/report"
                        component={Report}
                        isAuthorized={permissions.some((permission) =>
                            [Constant.USER_PERMISSIONS.manage_report].includes(permission)
                        )}
                    />
                    <AuthenticatedRoute
                        exact
                        path="/vip-link"
                        component={VipLink}
                        isAuthorized={permissions.some((permission) =>
                            [Constant.USER_PERMISSIONS.manage_link].includes(permission)
                        )}
                    />
                    <AuthenticatedRoute
                        exact
                        path="/vip-link/create"
                        component={VipLinkDashboard}
                        isAuthorized={permissions.some((permission) =>
                            [Constant.USER_PERMISSIONS.manage_link].includes(permission)
                        )}
                    />
                    <AuthenticatedRoute
                        exact
                        path="/vip-link/edit/:id"
                        component={VipLinkDashboard}
                        isAuthorized={permissions.some((permission) =>
                            [Constant.USER_PERMISSIONS.manage_link].includes(permission)
                        )}
                    />
                    <AuthenticatedRoute
                        exact
                        path="/agent"
                        component={AgentManagement}
                        isAuthorized={permissions.some((permission) =>
                            [Constant.USER_PERMISSIONS.manage_agent].includes(permission)
                        )}
                    />
                    <AuthenticatedRoute
                        exact
                        path="/agent/create"
                        component={AgentDashboard}
                        isAuthorized={permissions.some((permission) =>
                            [Constant.USER_PERMISSIONS.manage_agent].includes(permission)
                        )}
                    />
                    <AuthenticatedRoute
                        exact
                        path="/agent/edit/:id"
                        component={AgentDashboard}
                        isAuthorized={permissions.some((permission) =>
                            [Constant.USER_PERMISSIONS.manage_agent].includes(permission)
                        )}
                    />
                    <AuthenticatedRoute
                        exact
                        path="/accountant"
                        component={AccountantManagement}
                        isAuthorized={permissions.some((permission) =>
                            [Constant.USER_PERMISSIONS.manage_accountant].includes(permission)
                        )}
                    />
                    <AuthenticatedRoute
                        exact
                        path="/accountant/create"
                        component={AccountantDashboard}
                        isAuthorized={permissions.some((permission) =>
                            [Constant.USER_PERMISSIONS.manage_accountant].includes(permission)
                        )}
                    />
                    <AuthenticatedRoute
                        exact
                        path="/accountant/edit/:id"
                        component={AccountantDashboard}
                        isAuthorized={permissions.some((permission) =>
                            [Constant.USER_PERMISSIONS.manage_accountant].includes(permission)
                        )}
                    />
                    <AuthenticatedRoute
                        exact
                        path="/team"
                        component={TeamManagement}
                        isAuthorized={permissions.some((permission) =>
                            [
                                Constant.USER_PERMISSIONS.manage_team,
                                Constant.USER_PERMISSIONS.view_teams,
                            ].includes(permission)
                        )}
                    />
                    <AuthenticatedRoute
                        exact
                        path="/team/create"
                        component={TeamDashboard}
                        isAuthorized={permissions.some((permission) =>
                            [Constant.USER_PERMISSIONS.manage_team].includes(permission)
                        )}
                    />
                    <AuthenticatedRoute
                        exact
                        path="/team/edit/:id"
                        component={TeamDashboard}
                        isAuthorized={permissions.some((permission) =>
                            [Constant.USER_PERMISSIONS.manage_team].includes(permission)
                        )}
                    />
                    <AuthenticatedRoute
                        exact
                        path="/cs-report"
                        component={CSReportManagement}
                        isAuthorized={permissions.some((permission) =>
                            [
                                Constant.USER_PERMISSIONS.manage_sales,
                                Constant.USER_PERMISSIONS.create_sales,
                            ].includes(permission)
                        )}
                    />
                    <AuthenticatedRoute
                        exact
                        path="/cs-report/create"
                        component={CSReportDashboard}
                        isAuthorized={permissions.some((permission) =>
                            [
                                Constant.USER_PERMISSIONS.manage_sales,
                                Constant.USER_PERMISSIONS.create_sales,
                            ].includes(permission)
                        )}
                    />
                    <AuthenticatedRoute
                        exact
                        path="/cs-report/edit/:id"
                        component={CSReportDashboard}
                        isAuthorized={permissions.some((permission) =>
                            [
                                Constant.USER_PERMISSIONS.manage_sales,
                                Constant.USER_PERMISSIONS.create_sales,
                            ].includes(permission)
                        )}
                    />
                    <AuthenticatedRoute
                        exact
                        path="/sale"
                        component={Sales}
                        isAuthorized={permissions.some((permission) =>
                            [
                                Constant.USER_PERMISSIONS.manage_sales,
                                Constant.USER_PERMISSIONS.create_sales,
                            ].includes(permission)
                        )}
                    />
                    <AuthenticatedRoute
                        exact
                        path="/sale/create"
                        component={SalesDashboard}
                        isAuthorized={permissions.some((permission) =>
                            [
                                Constant.USER_PERMISSIONS.manage_sales,
                                Constant.USER_PERMISSIONS.create_sales,
                            ].includes(permission)
                        )}
                    />
                    <AuthenticatedRoute
                        exact
                        path="/sale/edit/:id"
                        component={SalesDashboard}
                        isAuthorized={permissions.some((permission) =>
                            [
                                Constant.USER_PERMISSIONS.manage_sales,
                                Constant.USER_PERMISSIONS.create_sales,
                            ].includes(permission)
                        )}
                    />
                    <AuthenticatedRoute
                        exact
                        path="/member"
                        component={MemberManagement}
                        isAuthorized={permissions.some((permission) =>
                            [
                                Constant.USER_PERMISSIONS.manage_team_member,
                                Constant.USER_PERMISSIONS.view_members,
                            ].includes(permission)
                        )}
                    />
                    <AuthenticatedRoute
                        exact
                        path="/member/create"
                        component={MemberDashboard}
                        isAuthorized={permissions.some((permission) =>
                            [Constant.USER_PERMISSIONS.manage_team_member].includes(permission)
                        )}
                    />
                    <AuthenticatedRoute
                        exact
                        path="/member/edit/:id"
                        component={MemberDashboard}
                        isAuthorized={permissions.some((permission) =>
                            [Constant.USER_PERMISSIONS.manage_team_member].includes(permission)
                        )}
                    />
                    <AuthenticatedRoute
                        exact
                        path="/phone-records"
                        component={PhoneRecords}
                        isAuthorized={permissions.some((permission) =>
                            [Constant.USER_PERMISSIONS.view_link_numbers].includes(permission)
                        )}
                    />
                    <AuthenticatedRoute
                        exact
                        path="/ads"
                        component={Ads}
                        isAuthorized={permissions.some((permission) =>
                            [
                                Constant.USER_PERMISSIONS.manage_sales,
                                Constant.USER_PERMISSIONS.create_sales,
                            ].includes(permission)
                        )}
                    />
                    <AuthenticatedRoute
                        exact
                        path="/ads/create"
                        component={AdsDashboard}
                        isAuthorized={permissions.some((permission) =>
                            [
                                Constant.USER_PERMISSIONS.manage_sales,
                                Constant.USER_PERMISSIONS.create_sales,
                            ].includes(permission)
                        )}
                    />
                    <AuthenticatedRoute
                        exact
                        path="/ads/edit/:id"
                        component={AdsDashboard}
                        isAuthorized={permissions.some((permission) =>
                            [
                                Constant.USER_PERMISSIONS.manage_sales,
                                Constant.USER_PERMISSIONS.create_sales,
                            ].includes(permission)
                        )}
                    />

                    <AuthenticatedRoute
                        exact
                        path="/enquiry"
                        component={Enquiry}
                        isAuthorized={permissions.some((permission) =>
                            [
                                Constant.USER_PERMISSIONS.manage_enquiry,
                                Constant.USER_PERMISSIONS.create_enquiry,
                            ].includes(permission)
                        )}
                    />
                    <AuthenticatedRoute
                        exact
                        path="/enquiry/create"
                        component={EnquiryDashboard}
                        isAuthorized={permissions.some((permission) =>
                            [
                                Constant.USER_PERMISSIONS.manage_enquiry,
                                Constant.USER_PERMISSIONS.create_enquiry,
                            ].includes(permission)
                        )}
                    />
                    <AuthenticatedRoute
                        exact
                        path="/enquiry/edit/:id"
                        component={EnquiryDashboard}
                        isAuthorized={permissions.some((permission) =>
                            [
                                Constant.USER_PERMISSIONS.manage_enquiry,
                                Constant.USER_PERMISSIONS.create_enquiry,
                            ].includes(permission)
                        )}
                    />

                    <AuthenticatedRoute
                        exact
                        path="/ads_manager"
                        component={AdsManagerManagement}
                        isAuthorized={permissions.some((permission) =>
                            [Constant.USER_PERMISSIONS.manage_accountant].includes(permission)
                        )}
                    />
                    <AuthenticatedRoute
                        exact
                        path="/ads_manager/create"
                        component={AdsManagerDashboard}
                        isAuthorized={permissions.some((permission) =>
                            [Constant.USER_PERMISSIONS.manage_accountant].includes(permission)
                        )}
                    />
                    <AuthenticatedRoute
                        exact
                        path="/ads_manager/edit/:id"
                        component={AdsManagerDashboard}
                        isAuthorized={permissions.some((permission) =>
                            [Constant.USER_PERMISSIONS.manage_accountant].includes(permission)
                        )}
                    />

                    <Route path="/link/:id" component={VipLinkClient} />
                    <Route path="/404" component={NotFound} />
                    <Redirect to="/404" />
                </Switch>
            </ConnectedRouter>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        session: state.session,
    };
};

export default connect(mapStateToProps, { getProfile })(Routes);
