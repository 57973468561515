import SaleService from '../services/saleService';
// Actions
const GET_SALE_LIST_REQUEST = 'vip/sale/GET_SALE_LIST_REQUEST';
const GET_SALE_LIST_SUCCESS = 'vip/sale/GET_SALE_LIST_SUCCESS';
const GET_SALE_LIST_FAIL = 'vip/sale/GET_SALE_LIST_FAIL';
const GET_SALE_REQUEST = 'vip/sale/GET_SALE_REQUEST';
const GET_SALE_SUCCESS = 'vip/sale/GET_SALE_SUCCESS';
const GET_SALE_FAIL = 'vip/sale/GET_SALE_FAIL';
const DELETE_SALE_REQUEST = 'vip/sale/DELETE_SALE_REQUEST';
const DELETE_SALE_SUCCESS = 'vip/sale/DELETE_SALE_SUCCESS';
const DELETE_SALE_FAIL = 'vip/sale/DELETE_SALE_FAIL';
const POST_CREATE_SALE_REQUEST = 'vip/sale/POST_CREATE_SALE_REQUEST';
const POST_CREATE_SALE_SUCCESS = 'vip/sale/POST_CREATE_SALE_SUCCESS';
const POST_CREATE_SALE_FAIL = 'vip/sale/POST_CREATE_SALE_FAIL';
const PATCH_EDIT_SALES_REQUEST = 'vip/sale/PATCH_EDIT_SALES_REQUEST';
const PATCH_EDIT_SALES_SUCCESS = 'vip/sale/PATCH_EDIT_SALES_SUCCESS';
const PATCH_EDIT_SALES_FAIL = 'vip/sale/PATCH_EDIT_SALES_FAIL';
const APPROVE_SALE_REQUEST = 'vip/sale/APPROVE_SALE_REQUEST';
const APPROVE_SALE_SUCCESS = 'vip/sale/APPROVE_SALE_SUCCESS';
const APPROVE_SALE_FAIL = 'vip/sale/APPROVE_SALE_FAIL';
const REJECT_SALE_REQUEST = 'vip/sale/REJECT_SALE_REQUEST';
const REJECT_SALE_SUCCESS = 'vip/sale/REJECT_SALE_SUCCESS';
const REJECT_SALE_FAIL = 'vip/sale/REJECT_SALE_FAIL';
const GET_SALES_STATUS_REQUEST = 'vip/sale/GET_SALES_STATUS_REQUEST';
const GET_SALES_STATUS_SUCCESS = 'vip/sale/GET_SALES_STATUS_SUCCESS';
const GET_SALES_STATUS_FAIL = 'vip/sale/GET_SALES_STATUS_FAIL';
const SESSION_TOKEN_EXPIRED = 'vip/session/SESSION_TOKEN_EXPIRED';

// Reducer
const INITIAL_STATE = {
    error: null,
    isLoading: false,
    current: null,
    status: null,
    reportSummary: null,
    list: [],
    pagination: null,
};

export default function reducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SESSION_TOKEN_EXPIRED: {
            return INITIAL_STATE;
        }
        case GET_SALE_LIST_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case GET_SALE_LIST_SUCCESS: {
            return {
                ...state,
                list: action.payload.results,
                pagination: action.payload.pagination,
                reportSummary: action.payload.reportSummary,
                isLoading: false,
                error: null,
            };
        }
        case GET_SALE_LIST_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case GET_SALES_STATUS_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case GET_SALES_STATUS_SUCCESS: {
            return {
                ...state,
                status: action.payload,
                isLoading: false,
                error: null,
            };
        }
        case GET_SALES_STATUS_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case GET_SALE_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case GET_SALE_SUCCESS: {
            return {
                ...state,
                current: action.payload,
                isLoading: false,
                error: null,
            };
        }
        case GET_SALE_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case DELETE_SALE_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case DELETE_SALE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: null,
            };
        }
        case DELETE_SALE_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case APPROVE_SALE_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case APPROVE_SALE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: null,
            };
        }
        case APPROVE_SALE_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case REJECT_SALE_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case REJECT_SALE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: null,
            };
        }
        case REJECT_SALE_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case POST_CREATE_SALE_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case POST_CREATE_SALE_SUCCESS: {
            return {
                ...state,
                current: action.payload,
                isLoading: false,
                error: null,
            };
        }
        case POST_CREATE_SALE_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case PATCH_EDIT_SALES_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case PATCH_EDIT_SALES_SUCCESS: {
            return {
                ...state,
                current: action.payload,
                isLoading: false,
                error: null,
            };
        }
        case PATCH_EDIT_SALES_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        default:
            return state;
    }
}

// Action Creators
export function getSalesListRequest() {
    return {
        type: GET_SALE_LIST_REQUEST,
    };
}
export function getSalesListSuccess(payload) {
    return {
        type: GET_SALE_LIST_SUCCESS,
        payload,
    };
}
export function getSalesListFail(error) {
    return {
        type: GET_SALE_LIST_FAIL,
        error,
    };
}
export function getSalesStatusRequest() {
    return {
        type: GET_SALES_STATUS_REQUEST,
    };
}
export function getSalesStatusSuccess(payload) {
    return {
        type: GET_SALES_STATUS_SUCCESS,
        payload,
    };
}
export function getSalesStatusFail(error) {
    return {
        type: GET_SALES_STATUS_FAIL,
        error,
    };
}
export function getSaleRequest() {
    return {
        type: GET_SALE_REQUEST,
    };
}
export function getSaleSuccess(payload) {
    return {
        type: GET_SALE_SUCCESS,
        payload,
    };
}
export function getSaleFail(error) {
    return {
        type: GET_SALE_FAIL,
        error,
    };
}
export function deleteSaleRequest() {
    return {
        type: DELETE_SALE_REQUEST,
    };
}
export function deleteSaleSuccess() {
    return {
        type: DELETE_SALE_SUCCESS,
    };
}
export function deleteSaleFail(error) {
    return {
        type: DELETE_SALE_FAIL,
        error,
    };
}
export function approveSaleRequest() {
    return {
        type: APPROVE_SALE_REQUEST,
    };
}
export function approveSaleSuccess() {
    return {
        type: APPROVE_SALE_SUCCESS,
    };
}
export function approveSaleFail(error) {
    return {
        type: APPROVE_SALE_FAIL,
        error,
    };
}
export function rejectSaleRequest() {
    return {
        type: REJECT_SALE_REQUEST,
    };
}
export function rejectSaleSuccess() {
    return {
        type: REJECT_SALE_SUCCESS,
    };
}
export function rejectSaleFail(error) {
    return {
        type: REJECT_SALE_FAIL,
        error,
    };
}
export function updateSaleRequest() {
    return {
        type: PATCH_EDIT_SALES_REQUEST,
    };
}
export function updateSaleSuccess(payload) {
    return {
        type: PATCH_EDIT_SALES_SUCCESS,
        payload,
    };
}
export function updateSaleFail(error) {
    return {
        type: PATCH_EDIT_SALES_FAIL,
        error,
    };
}
export function createSaleRequest() {
    return {
        type: POST_CREATE_SALE_REQUEST,
    };
}
export function createSaleSuccess(payload) {
    return {
        type: POST_CREATE_SALE_SUCCESS,
        payload,
    };
}
export function createSaleFail(error) {
    return {
        type: POST_CREATE_SALE_FAIL,
        error,
    };
}
// side effects, only as applicable
// e.g. thunks, epics, etc
export const getSalesList = ({
    page_size,
    page_number,
    team_ids,
    bank_id,
    member_ids,
    start_date,
    end_date,
    filter_status,
} = {}) => async (dispatch) => {
    dispatch(getSalesListRequest());
    try {
        const list = await SaleService.getSalesList({
            page_size,
            page_number,
            team_ids,
            bank_id,
            member_ids,
            start_date,
            end_date,
            filter_status,
        });
        dispatch(getSalesListSuccess(list));
    } catch (error) {
        dispatch(getSalesListFail(error));
        throw error;
    }
};
export const createSale = ({
    sales_amount,
    ads_amount,
    expenses_amount,
    sales_date,
    team_id,
    bank_account_id,
    transfer_type,
    expense_category,
}) => async (dispatch) => {
    dispatch(createSaleRequest());
    try {
        const result = await SaleService.createSale({
            sales_amount,
            ads_amount,
            expenses_amount,
            sales_date,
            team_id,
            bank_account_id,
            transfer_type,
            expense_category,
        });
        dispatch(createSaleSuccess(result));
    } catch (error) {
        dispatch(createSaleFail(error));
        throw error;
    }
};
export const getSaleById = ({ id }) => async (dispatch) => {
    dispatch(getSaleRequest());
    try {
        const result = await SaleService.getSaleById({
            id,
        });
        dispatch(getSaleSuccess(result));
    } catch (error) {
        dispatch(getSaleFail(error));
        throw error;
    }
};
export const getSalesStatus = () => async (dispatch) => {
    dispatch(getSalesStatusRequest());
    try {
        const result = await SaleService.getSalesStatus();
        dispatch(getSalesStatusSuccess(result));
    } catch (error) {
        dispatch(getSalesStatusFail(error));
        throw error;
    }
};
export const updateSale = ({
    id,
    sales_amount,
    ads_amount,
    expenses_amount,
    sales_date,
    transfer_type,
    team_id,
    bank_account_id,
    expense_category,
}) => async (dispatch) => {
    dispatch(updateSaleRequest());
    try {
        const result = await SaleService.updateSale({
            id,
            sales_amount,
            ads_amount,
            expenses_amount,
            sales_date,
            transfer_type,
            team_id,
            bank_account_id,
            expense_category,
        });
        dispatch(updateSaleSuccess(result));
    } catch (error) {
        dispatch(updateSaleFail(error));
        throw error;
    }
};
export const deleteSaleById = ({ id }) => async (dispatch) => {
    dispatch(deleteSaleRequest());
    try {
        await SaleService.deleteSaleById({
            id,
        });
        dispatch(deleteSaleSuccess());
    } catch (error) {
        dispatch(deleteSaleFail(error));
        throw error;
    }
};
export const approveSaleById = ({ id }) => async (dispatch) => {
    dispatch(approveSaleRequest());
    try {
        await SaleService.approveSaleById({
            id,
        });
        dispatch(approveSaleSuccess());
    } catch (error) {
        dispatch(approveSaleFail(error));
        throw error;
    }
};
export const rejectSaleById = ({ id }) => async (dispatch) => {
    dispatch(rejectSaleRequest());
    try {
        await SaleService.rejectSaleById({
            id,
        });
        dispatch(rejectSaleSuccess());
    } catch (error) {
        dispatch(rejectSaleFail(error));
        throw error;
    }
};
