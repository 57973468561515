import { connect } from 'react-redux';
import VipLinkDashboard from './VipLinkDashboard';
import {
    createVipLink,
    getVipLinkList,
    getVipLinkById,
    updateVipLink,
    deleteVipLinkById,
} from '../../../ducks/vipLink';

const mapStateToProps = (state) => {
    return {
        session: state.session,
        vipLink: state.vipLink,
    };
};

const VipLinkDashboardContainer = connect(mapStateToProps, {
    createVipLink,
    getVipLinkList,
    getVipLinkById,
    updateVipLink,
    deleteVipLinkById,
})(VipLinkDashboard);
export default VipLinkDashboardContainer;
