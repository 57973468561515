import React, { Component } from 'react';
import { Table, Tag, Space, notification, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Container } from './index.style';
import { Utility } from '../../helpers/';

class PhoneRecords extends Component {
    state = {
        loading: false,
        data: [],
        pagination: {
            current: 1,
            total: 0,
            showSizeChanger: false,
        },
    };
    constructor(props) {
        super(props);
        this.div = React.createRef();
        this.columns = [
            {
                title: 'Item',
                dataIndex: 'item',
                key: 'item',
                render: (text) => {
                    if (Utility.urlRegexCheck(text)) {
                        return (
                            <Tooltip placement="right" title={text}>
                                <a target="_blank" rel="noopener noreferrer" href={text}>
                                    Link <InfoCircleOutlined />
                                </a>
                            </Tooltip>
                        );
                    }
                    return (
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={Utility.urlRegexCheck(text) ? text : `https://wa.me/6${text}`}
                        >
                            {text}
                        </a>
                    );
                },
            },
            {
                title: 'VIP Link Name',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'Created By',
                key: 'createdBy',
                render: (text) => <b>{text.createdBy.username}</b>,
            },
            {
                title: 'Link Type',
                dataIndex: 'type',
                key: 'type',
                render: (text) => <b style={{ textTransform: 'capitalize' }}>{text}</b>,
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (text) => (
                    <Space size="middle">
                        {text === 'active' ? (
                            <Tag color="#87d068">
                                <b>{text.toUpperCase()}</b>
                            </Tag>
                        ) : (
                            <Tag color="#f50">
                                <b>{text.toUpperCase()}</b>
                            </Tag>
                        )}
                    </Space>
                ),
            },
        ];
    }
    componentDidMount = async () => {
        this.div.current.scrollIntoView({ behavior: 'smooth' });
        this.setState({ loading: true });
        await this.props.getVipLinkList();
    };
    componentDidUpdate = (prevProps) => {
        if (this.props.vipLink.error && this.props.vipLink.error !== prevProps.vipLink.error) {
            this.openNotificationWithIcon({
                type: 'error',
                title: this.props.vipLink.error.frontend_type,
                message: this.props.vipLink.error.message,
            });
            this.setState({
                isLoading: this.props.vipLink.isLoading,
            });
        }
        if (prevProps.vipLink.list !== this.props.vipLink.list) {
            this.restructurePhoneRecordsData({
                vipLinkList: this.props.vipLink.list,
            });
        }
    };
    restructurePhoneRecordsData = ({ vipLinkList }) => {
        const tempList = [...vipLinkList];
        let data = [];
        tempList.map((record) => {
            return record.numbers.map((number) => {
                return data.push({
                    ...record,
                    item: number,
                });
            });
        });
        this.setState({
            data,
            loading: false,
            pagination: {
                ...this.state.pagination,
                total: data.length,
            },
        });
    };
    openNotificationWithIcon = ({ type, title, message }) => {
        notification[type]({
            message: title,
            description: message,
        });
    };
    onChangeTable = async ({ nextPage }) => {
        this.setState({ loading: true });
        this.setState({
            loading: false,
            pagination: {
                ...this.state.pagination,
                current: nextPage,
            },
        });
    };
    render() {
        return (
            <Container ref={this.div}>
                <Table
                    loading={this.state.loading}
                    rowKey={(record) => record.id}
                    columns={this.columns}
                    dataSource={this.state.data}
                    scroll={{ x: 500 }}
                    pagination={this.state.pagination}
                    onChange={(event) => this.onChangeTable({ nextPage: event.current })}
                />
            </Container>
        );
    }
}

export default PhoneRecords;
