import { connect } from 'react-redux';
import EnquiryDashboard from './EnquiryDashboard';
import {
    createEnquiry,
    getEnquiryList,
    getEnquiryById,
    updateEnquiry,
    deleteEnquiryById,
} from '../../../ducks/enquiry';
import { getUserList } from '../../../ducks/user';
import { getTeamList } from '../../../ducks/team';

const mapStateToProps = (state) => {
    return {
        session: state.session,
        enquiry: state.enquiry,
        team: state.team,
        user: state.user,
    };
};

const EnquiryDashboardContainer = connect(mapStateToProps, {
    createEnquiry,
    getEnquiryList,
    getEnquiryById,
    updateEnquiry,
    deleteEnquiryById,
    getTeamList,
    getUserList,
})(EnquiryDashboard);
export default EnquiryDashboardContainer;
