import AnnouncementService from '../services/announcementService';
// Actions
const GET_ANNOUNCEMENT_LIST_REQUEST = 'vip/announcement/GET_ANNOUNCEMENT_LIST_REQUEST';
const GET_ANNOUNCEMENT_LIST_SUCCESS = 'vip/announcement/GET_ANNOUNCEMENT_LIST_SUCCESS';
const GET_ANNOUNCEMENT_LIST_FAIL = 'vip/announcement/GET_ANNOUNCEMENT_LIST_FAIL';
const GET_ANNOUNCEMENT_REQUEST = 'vip/announcement/GET_ANNOUNCEMENT_REQUEST';
const GET_ANNOUNCEMENT_SUCCESS = 'vip/announcement/GET_ANNOUNCEMENT_SUCCESS';
const GET_ANNOUNCEMENT_FAIL = 'vip/announcement/GET_ANNOUNCEMENT_FAIL';
const DELETE_ANNOUNCEMENT_REQUEST = 'vip/announcement/DELETE_ANNOUNCEMENT_REQUEST';
const DELETE_ANNOUNCEMENT_SUCCESS = 'vip/announcement/DELETE_ANNOUNCEMENT_SUCCESS';
const DELETE_ANNOUNCEMENT_FAIL = 'vip/announcement/DELETE_ANNOUNCEMENT_FAIL';
const POST_CREATE_ANNOUNCEMENT_REQUEST = 'vip/announcement/POST_CREATE_ANNOUNCEMENT_REQUEST';
const POST_CREATE_ANNOUNCEMENT_SUCCESS = 'vip/announcement/POST_CREATE_ANNOUNCEMENT_SUCCESS';
const POST_CREATE_ANNOUNCEMENT_FAIL = 'vip/announcement/POST_CREATE_ANNOUNCEMENT_FAIL';
const PATCH_EDIT_ANNOUNCEMENT_REQUEST = 'vip/announcement/PATCH_EDIT_ANNOUNCEMENT_REQUEST';
const PATCH_EDIT_ANNOUNCEMENT_SUCCESS = 'vip/announcement/PATCH_EDIT_ANNOUNCEMENT_SUCCESS';
const PATCH_EDIT_ANNOUNCEMENT_FAIL = 'vip/announcement/PATCH_EDIT_ANNOUNCEMENT_FAIL';
const SESSION_TOKEN_EXPIRED = 'vip/session/SESSION_TOKEN_EXPIRED';

// Reducer
const INITIAL_STATE = {
    error: null,
    isLoading: false,
    current: null,
    list: [],
    pagination: null,
};

export default function reducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SESSION_TOKEN_EXPIRED: {
            return INITIAL_STATE;
        }
        case GET_ANNOUNCEMENT_LIST_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case GET_ANNOUNCEMENT_LIST_SUCCESS: {
            return {
                ...state,
                list: action.payload.results,
                pagination: action.payload.pagination,
                isLoading: false,
                error: null,
            };
        }
        case GET_ANNOUNCEMENT_LIST_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case GET_ANNOUNCEMENT_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case GET_ANNOUNCEMENT_SUCCESS: {
            return {
                ...state,
                current: action.payload,
                isLoading: false,
                error: null,
            };
        }
        case GET_ANNOUNCEMENT_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case DELETE_ANNOUNCEMENT_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case DELETE_ANNOUNCEMENT_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: null,
            };
        }
        case DELETE_ANNOUNCEMENT_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case POST_CREATE_ANNOUNCEMENT_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case POST_CREATE_ANNOUNCEMENT_SUCCESS: {
            return {
                ...state,
                current: action.payload,
                isLoading: false,
                error: null,
            };
        }
        case POST_CREATE_ANNOUNCEMENT_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case PATCH_EDIT_ANNOUNCEMENT_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case PATCH_EDIT_ANNOUNCEMENT_SUCCESS: {
            return {
                ...state,
                current: action.payload,
                isLoading: false,
                error: null,
            };
        }
        case PATCH_EDIT_ANNOUNCEMENT_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        default:
            return state;
    }
}

// Action Creators
export function getAnnouncementListRequest() {
    return {
        type: GET_ANNOUNCEMENT_LIST_REQUEST,
    };
}
export function getAnnouncementListSuccess(payload) {
    return {
        type: GET_ANNOUNCEMENT_LIST_SUCCESS,
        payload,
    };
}
export function getAnnouncementListFail(error) {
    return {
        type: GET_ANNOUNCEMENT_LIST_FAIL,
        error,
    };
}
export function getAnnouncementRequest() {
    return {
        type: GET_ANNOUNCEMENT_REQUEST,
    };
}
export function getAnnouncementSuccess(payload) {
    return {
        type: GET_ANNOUNCEMENT_SUCCESS,
        payload,
    };
}
export function getAnnouncementFail(error) {
    return {
        type: GET_ANNOUNCEMENT_FAIL,
        error,
    };
}
export function deleteAnnouncementRequest() {
    return {
        type: DELETE_ANNOUNCEMENT_REQUEST,
    };
}
export function deleteAnnouncementSuccess() {
    return {
        type: DELETE_ANNOUNCEMENT_SUCCESS,
    };
}
export function deleteAnnouncementFail(error) {
    return {
        type: DELETE_ANNOUNCEMENT_FAIL,
        error,
    };
}
export function updateAnnouncementRequest() {
    return {
        type: PATCH_EDIT_ANNOUNCEMENT_REQUEST,
    };
}
export function updateAnnouncementSuccess(payload) {
    return {
        type: PATCH_EDIT_ANNOUNCEMENT_SUCCESS,
        payload,
    };
}
export function updateAnnouncementFail(error) {
    return {
        type: PATCH_EDIT_ANNOUNCEMENT_FAIL,
        error,
    };
}
export function createAnnouncementRequest() {
    return {
        type: POST_CREATE_ANNOUNCEMENT_REQUEST,
    };
}
export function createAnnouncementSuccess(payload) {
    return {
        type: POST_CREATE_ANNOUNCEMENT_SUCCESS,
        payload,
    };
}
export function createAnnouncementFail(error) {
    return {
        type: POST_CREATE_ANNOUNCEMENT_FAIL,
        error,
    };
}
// side effects, only as applicable
// e.g. thunks, epics, etc
export const getAnnouncementList = ({ page_size, page_number } = {}) => async (dispatch) => {
    dispatch(getAnnouncementListRequest());
    try {
        const list = await AnnouncementService.getAnnouncementList({ page_size, page_number });
        dispatch(getAnnouncementListSuccess(list));
    } catch (error) {
        dispatch(getAnnouncementListFail(error));
        throw error;
    }
};
export const createAnnouncement = ({ title, content }) => async (dispatch) => {
    dispatch(createAnnouncementRequest());
    try {
        const result = await AnnouncementService.createAnnouncement({
            title,
            content,
        });
        dispatch(createAnnouncementSuccess(result));
    } catch (error) {
        dispatch(createAnnouncementFail(error));
        throw error;
    }
};
export const getAnnouncementById = ({ id }) => async (dispatch) => {
    dispatch(getAnnouncementRequest());
    try {
        const result = await AnnouncementService.getAnnouncementById({
            id,
        });
        dispatch(getAnnouncementSuccess(result));
    } catch (error) {
        dispatch(getAnnouncementFail(error));
        throw error;
    }
};
export const updateAnnouncement = ({ id, title, content }) => async (dispatch) => {
    dispatch(updateAnnouncementRequest());
    try {
        const result = await AnnouncementService.updateAnnouncement({
            id,
            title,
            content,
        });
        dispatch(updateAnnouncementSuccess(result));
    } catch (error) {
        dispatch(updateAnnouncementFail(error));
        throw error;
    }
};
export const deleteAnnouncementById = ({ id }) => async (dispatch) => {
    dispatch(deleteAnnouncementRequest());
    try {
        await AnnouncementService.deleteAnnouncementById({
            id,
        });
        dispatch(deleteAnnouncementSuccess());
    } catch (error) {
        dispatch(deleteAnnouncementFail(error));
        throw error;
    }
};
