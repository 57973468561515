import { connect } from 'react-redux';
import Landing from './Landing';
import { getProfile } from '../../ducks/session';
import { getSalesStatus } from '../../ducks/sale';

const mapStateToProps = (state) => {
    return {
        session: state.session,
        user: state.user,
        sale: state.sale,
    };
};

const LandingContainer = connect(mapStateToProps, {
    getProfile,
    getSalesStatus,
})(Landing);
export default LandingContainer;
