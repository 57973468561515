import { connect } from 'react-redux';
import TeamManagement from './TeamManagement';
import { getTeamList } from '../../ducks/team';

const mapStateToProps = (state) => {
    return {
        session: state.session,
        team: state.team,
    };
};

const TeamManagementContainer = connect(mapStateToProps, {
    getTeamList,
})(TeamManagement);
export default TeamManagementContainer;
