import { connect } from 'react-redux';
import AgentManagement from './AgentManagement';
import { getUserList } from '../../ducks/user';

const mapStateToProps = (state) => {
    return {
        session: state.session,
        user: state.user,
    };
};

const AgentManagementContainer = connect(mapStateToProps, {
    getUserList,
})(AgentManagement);
export default AgentManagementContainer;
