import { NetworkError, HttpErrorHandler } from './service';
import { VipAPIService } from '../services';
import Ads from '../models/ads';

export default class AdsService {
    static async getAdvertisementList({
        page_size,
        page_number,
        team_ids,
        user_ids,
        start_date,
        end_date,
        filter_status,
    }) {
        const [error, response] = await VipAPIService.shared.getAdvertisementList({
            page_size,
            page_number,
            team_ids,
            user_ids,
            start_date,
            end_date,
            filter_status,
        });
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return {
                results: response.data.results.map((result) => new Ads(result)),
                pagination: response.data.pagination,
            };
        }
    }
    static async deleteAdsById({ id }) {
        const [error, response] = await VipAPIService.shared.deleteAdsById({
            id,
        });
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return {};
        }
    }
    static async getAdvertisementById({ id }) {
        const [error, response] = await VipAPIService.shared.getAdvertisementById({
            id,
        });
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return new Ads(response.data);
        }
    }
    static async updateAds({
        id,
        ads_amount,
        ads_date,
        team_id,
        member_id,
        ads_type,
        expense_category,
    }) {
        const [error, response] = await VipAPIService.shared.updateAds({
            id,
            ads_amount,
            ads_date,
            team_id,
            member_id,
            ads_type,
            expense_category,
        });
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return new Ads(response.data);
        }
    }
    static async createAds({
        ads_amount,
        ads_date,
        team_id,
        member_id,
        ads_type,
        expense_category,
    }) {
        const [error, response] = await VipAPIService.shared.createAds({
            ads_amount,
            ads_date,
            team_id,
            member_id,
            ads_type,
            expense_category,
        });
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return new Ads(response.data);
        }
    }
    static async approveAdsById({ id }) {
        const [error, response] = await VipAPIService.shared.approveAdsById({
            id,
        });
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return {};
        }
    }
    static async rejectAdsById({ id }) {
        const [error, response] = await VipAPIService.shared.rejectAdsById({
            id,
        });
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return {};
        }
    }
    static async getSalesStatus() {
        const [error, response] = await VipAPIService.shared.getSalesStatus();
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return response.data;
        }
    }
}
