import { connect } from 'react-redux';
import MemberManagement from './MemberManagement';
import { getUserList } from '../../ducks/user';

const mapStateToProps = (state) => {
    return {
        session: state.session,
        user: state.user,
    };
};

const MemberManagementContainer = connect(mapStateToProps, {
    getUserList,
})(MemberManagement);
export default MemberManagementContainer;
