import { connect } from 'react-redux';
import AgentDashboard from './AgentDashboard';
import { createAgent, getUserById, updateUser, getUserList } from '../../../ducks/user';

const mapStateToProps = (state) => {
    return {
        session: state.session,
        user: state.user,
    };
};

const AgentDashboardContainer = connect(mapStateToProps, {
    createAgent,
    getUserById,
    updateUser,
    getUserList,
})(AgentDashboard);
export default AgentDashboardContainer;
