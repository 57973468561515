import React, { Component } from 'react';
import { Table, Space, Button, notification } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { Container, ButtonContainer, IconButton } from './index.style';
import { Constant } from '../../helpers';

class TeamManagement extends Component {
    state = {
        loading: false,
        data: [],
        pagination: {
            current: 1,
            total: 0,
            showSizeChanger: false,
        },
    };
    constructor(props) {
        super(props);
        this.div = React.createRef();
        this.columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'Leader',
                dataIndex: 'leaders',
                key: 'leaders',
                render: (text) => <b>{(text.length > 0 && text[0].user.username) || '-'}</b>,
            },
            {
                title: 'Created By',
                key: 'createdBy',
                render: (text) => <b>{text.createdBy.username}</b>,
            },
            {
                title: 'Action',
                key: 'action',
                render: (record) => {
                    return (
                        <Space size="middle">
                            {this.props.session.permissions.includes(
                                Constant.USER_PERMISSIONS.manage_team
                            ) && (
                                <IconButton
                                    onClick={() => this.onEditButtonPressed({ id: record.id })}
                                >
                                    <EditOutlined /> Edit
                                </IconButton>
                            )}
                        </Space>
                    );
                },
            },
        ];
    }
    componentDidMount = async () => {
        this.div.current.scrollIntoView({ behavior: 'smooth' });
        this.setState({ loading: true });
        await this.props.getTeamList({ page_size: 50 });
    };
    componentDidUpdate = (prevProps) => {
        if (this.props.team.error && this.props.team.error !== prevProps.team.error) {
            this.openNotificationWithIcon({
                type: 'error',
                title: this.props.team.error.frontend_type,
                message: this.props.team.error.message,
            });
            this.setState({
                isLoading: this.props.team.isLoading,
            });
        }
        if (prevProps.team.list !== this.props.team.list) {
            this.setState({
                data: this.props.team.list,
                loading: false,
                pagination: {
                    ...this.state.pagination,
                    current: this.props.team.pagination.page,
                    total: this.props.team.pagination.row_count,
                },
            });
        }
    };
    openNotificationWithIcon = ({ type, title, message }) => {
        notification[type]({
            message: title,
            description: message,
        });
    };
    onChangeTable = async ({ nextPage }) => {
        this.setState({ loading: true });
        await this.props.getTeamList({ page_number: nextPage });
    };
    onEditButtonPressed = ({ id }) => {
        this.props.history.push(`/team/edit/${id}`);
    };
    onCreateNewTeamPressed = () => {
        this.props.history.push('/team/create');
    };
    render() {
        return (
            <Container ref={this.div}>
                <ButtonContainer>
                    <Button type="primary" size={'large'} onClick={this.onCreateNewTeamPressed}>
                        <b>CREATE NEW TEAM</b>
                    </Button>
                </ButtonContainer>
                <Table
                    loading={this.state.loading}
                    rowKey={(record) => record.id}
                    columns={this.columns}
                    dataSource={this.state.data}
                    scroll={{ x: 500 }}
                    pagination={this.state.pagination}
                    onChange={(event) => this.onChangeTable({ nextPage: event.current })}
                />
            </Container>
        );
    }
}

export default TeamManagement;
