import React, { Component } from 'react';
import { Table, Tag, Space, Button, Tooltip, message, notification } from 'antd';
import { LinkOutlined, EditOutlined, SyncOutlined } from '@ant-design/icons';
import { Container, ButtonContainer, IconButton } from './index.style';
import { Utility } from '../../helpers';

class VipLink extends Component {
    state = {
        loading: false,
        data: [],
        pagination: {
            current: 1,
            total: 0,
            showSizeChanger: false,
        },
    };
    constructor(props) {
        super(props);
        this.div = React.createRef();
        this.columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'Total Click',
                dataIndex: 'count',
                key: 'count',
                render: (text) => <span>{Utility.convertNumberToStringCommaNumber(text)}</span>,
            },
            {
                title: 'Created By',
                key: 'createdBy',
                render: (text) => <b>{text.createdBy.username}</b>,
            },
            {
                title: 'Link Type',
                dataIndex: 'type',
                key: 'type',
                render: (text) => <b style={{ textTransform: 'capitalize' }}>{text}</b>,
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (text) => (
                    <Space size="middle">
                        {text === 'active' ? (
                            <Tag color="#87d068">
                                <b>{text.toUpperCase()}</b>
                            </Tag>
                        ) : (
                            <Tag color="#f50">
                                <b>{text.toUpperCase()}</b>
                            </Tag>
                        )}
                    </Space>
                ),
            },
            {
                title: 'Action',
                key: 'action',
                render: (record) => {
                    return (
                        <Space size="middle">
                            <Tooltip placement="right" title="Click to copy shareable link">
                                <IconButton
                                    onClick={() =>
                                        this.onDirectLinkButtonPressed({ id: record.id })
                                    }
                                >
                                    <LinkOutlined /> Direct Link
                                </IconButton>
                            </Tooltip>
                            <Tooltip placement="right" title="Click to copy shareable link">
                                <IconButton
                                    onClick={() => this.onLinkButtonPressed({ id: record.id })}
                                >
                                    <LinkOutlined /> Link
                                </IconButton>
                            </Tooltip>
                            <Tooltip
                                placement="right"
                                title="Click to regenerate a new link & copy shareable link"
                            >
                                <IconButton
                                    onClick={() =>
                                        this.onRegenerateButtonPressed({ id: record.id })
                                    }
                                >
                                    <SyncOutlined spin={this.state.loading} /> Regenerate
                                </IconButton>
                            </Tooltip>
                            <IconButton onClick={() => this.onEditButtonPressed({ id: record.id })}>
                                <EditOutlined /> Edit
                            </IconButton>
                        </Space>
                    );
                },
            },
        ];
    }
    componentDidMount = async () => {
        this.div.current.scrollIntoView({ behavior: 'smooth' });
        this.setState({ loading: true });
        await this.props.getVipLinkList();
    };
    componentDidUpdate = (prevProps) => {
        if (this.props.vipLink.error && this.props.vipLink.error !== prevProps.vipLink.error) {
            this.openNotificationWithIcon({
                type: 'error',
                title: this.props.vipLink.error.frontend_type,
                message: this.props.vipLink.error.message,
            });
            this.setState({
                isLoading: this.props.vipLink.isLoading,
            });
        }
        if (prevProps.vipLink.list !== this.props.vipLink.list) {
            this.setState({
                data: this.props.vipLink.list,
                loading: false,
                pagination: {
                    ...this.state.pagination,
                    current: this.props.vipLink.pagination.page,
                    total: this.props.vipLink.pagination.row_count,
                },
            });
        }
    };
    openNotificationWithIcon = ({ type, title, message }) => {
        notification[type]({
            message: title,
            description: message,
        });
    };
    onChangeTable = async ({ nextPage }) => {
        this.setState({ loading: true });
        await this.props.getVipLinkList({ page_number: nextPage });
    };
    onEditButtonPressed = ({ id }) => {
        this.props.history.push(`/vip-link/edit/${id}`);
    };
    onRegenerateButtonPressed = async ({ id }) => {
        this.setState({ loading: true });
        await this.props.regenerateVipLink({ id });
        this.setState({ loading: false });
        const url = `https://smartqueuesystem.com/link/${this.props.vipLink.current.code}`;
        const input = document.createElement('textarea');
        input.innerHTML = url;
        document.body.appendChild(input);
        input.select();
        document.execCommand('copy');
        document.body.removeChild(input);
        message.success('Link regenerated & copied to clipboard');
    };
    onDirectLinkButtonPressed = ({ id }) => {
        const url = `${String(process.env.REACT_APP_API_URL)}api/v1/viplink/${id}`;
        const input = document.createElement('textarea');
        input.innerHTML = url;
        document.body.appendChild(input);
        input.select();
        document.execCommand('copy');
        document.body.removeChild(input);
        message.success('Copied to clipboard');
    };
    onLinkButtonPressed = ({ id }) => {
        const url = `https://smartqueuesystem.com/link/${id}`;
        const input = document.createElement('textarea');
        input.innerHTML = url;
        document.body.appendChild(input);
        input.select();
        document.execCommand('copy');
        document.body.removeChild(input);
        message.success('Copied to clipboard');
    };
    onCreateNewVipLinkPressed = () => {
        this.props.history.push('/vip-link/create');
    };
    render() {
        return (
            <Container ref={this.div}>
                <ButtonContainer>
                    <Button type="primary" size={'large'} onClick={this.onCreateNewVipLinkPressed}>
                        <b>CREATE NEW VIP LINK</b>
                    </Button>
                </ButtonContainer>
                <Table
                    loading={this.state.loading}
                    rowKey={(record) => record.id}
                    columns={this.columns}
                    dataSource={this.state.data}
                    scroll={{ x: 500 }}
                    pagination={this.state.pagination}
                    onChange={(event) => this.onChangeTable({ nextPage: event.current })}
                />
            </Container>
        );
    }
}

export default VipLink;
