export default class BankAccount {
    constructor(bankAccount) {
        this.id = bankAccount.id;
        this.bankId = bankAccount.bank_id;
        this.name = bankAccount.name;
        this.accountNumber = bankAccount.account_number;
        this.amountLimit = bankAccount.amount_limit;
        this.holdTotalSales = bankAccount.hold_total_sales;
        this.currentTotalSales = bankAccount.current_total_sales;
        this.limitBalance = bankAccount.limit_balance;
        this.limitUsedPercentage = bankAccount.limit_used_percentage;
        this.percentage = bankAccount.percentage;
    }
}
