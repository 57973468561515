export default class CustomerReport {
    constructor(customerReport) {
        this.id = customerReport.id;
        this.code = customerReport.code;
        this.step = customerReport.step;
        this.status = customerReport.status;
        this.reason = customerReport.reason;
        this.createdAt = customerReport.created_at;
        this.createdBy = customerReport.created_by_user;
    }
}
