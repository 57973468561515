export default class Sale {
    constructor(sale) {
        this.id = sale.id;
        this.salesAmount = sale.sales_amount;
        this.adsAmount = sale.ads_amount;
        this.expensesAmount = sale.expenses_amount;
        this.createdBy = sale.created_by_user;
        this.status = sale.status;
        this.createdAt = sale.created_at;
        this.salesDate = sale.sales_date;
        this.updatedAt = sale.updated_at;
        this.team = sale.team;
        this.bankAccountId = sale.bank_account_id;
        this.transferType = sale.transfer_type;
        this.companyPercentage = sale.company_percentage;
        this.companyAmount = sale.company_amount;
        this.agentPercentage = sale.agent_percentage;
        this.agentAmount = sale.agent_amount;
        this.memberPercentage = sale.member_percentage;
        this.memberAmount = sale.member_amount;
        this.totalAmount = sale.total_amount;
        this.teamId = sale.team_id;
        this.expenseCategory = sale.expense_category;
    }
}
