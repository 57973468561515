import { NetworkError, HttpErrorHandler } from './service';
import { VipAPIService } from '../services';
import Announcement from '../models/announcement';

export default class AnnouncementService {
    static async getAnnouncementList({ page_size, page_number }) {
        const [error, response] = await VipAPIService.shared.getAnnouncementList({
            page_size,
            page_number,
        });
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return {
                results: response.data.results.map(
                    (announcement) => new Announcement(announcement)
                ),
                pagination: response.data.pagination,
            };
        }
    }
    static async deleteAnnouncementById({ id }) {
        const [error, response] = await VipAPIService.shared.deleteAnnouncementById({
            id,
        });
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return {};
        }
    }
    static async getAnnouncementById({ id }) {
        const [error, response] = await VipAPIService.shared.getAnnouncementById({
            id,
        });
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return new Announcement(response.data);
        }
    }
    static async updateAnnouncement({ id, title, content }) {
        const [error, response] = await VipAPIService.shared.updateAnnouncement({
            id,
            title,
            content,
        });
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return new Announcement(response.data);
        }
    }
    static async createAnnouncement({ title, content }) {
        const [error, response] = await VipAPIService.shared.createAnnouncement({
            title,
            content,
        });
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return new Announcement(response.data);
        }
    }
}
