import AdsService from '../services/adsService';
// Actions
const GET_ADS_LIST_REQUEST = 'vip/ads/GET_ADS_LIST_REQUEST';
const GET_ADS_LIST_SUCCESS = 'vip/ads/GET_ADS_LIST_SUCCESS';
const GET_ADS_LIST_FAIL = 'vip/ads/GET_ADS_LIST_FAIL';
const GET_ADS_REQUEST = 'vip/ads/GET_ADS_REQUEST';
const GET_ADS_SUCCESS = 'vip/ads/GET_ADS_SUCCESS';
const GET_ADS_FAIL = 'vip/ads/GET_ADS_FAIL';
const DELETE_ADS_REQUEST = 'vip/ads/DELETE_ADS_REQUEST';
const DELETE_ADS_SUCCESS = 'vip/ads/DELETE_ADS_SUCCESS';
const DELETE_ADS_FAIL = 'vip/ads/DELETE_ADS_FAIL';
const POST_CREATE_ADS_REQUEST = 'vip/ads/POST_CREATE_ADS_REQUEST';
const POST_CREATE_ADS_SUCCESS = 'vip/ads/POST_CREATE_ADS_SUCCESS';
const POST_CREATE_ADS_FAIL = 'vip/ads/POST_CREATE_ADS_FAIL';
const PATCH_EDIT_ADS_REQUEST = 'vip/ads/PATCH_EDIT_ADS_REQUEST';
const PATCH_EDIT_ADS_SUCCESS = 'vip/ads/PATCH_EDIT_ADS_SUCCESS';
const PATCH_EDIT_ADS_FAIL = 'vip/ads/PATCH_EDIT_ADS_FAIL';
const APPROVE_ADS_REQUEST = 'vip/ads/APPROVE_ADS_REQUEST';
const APPROVE_ADS_SUCCESS = 'vip/ads/APPROVE_ADS_SUCCESS';
const APPROVE_ADS_FAIL = 'vip/ads/APPROVE_ADS_FAIL';
const REJECT_ADS_REQUEST = 'vip/ads/REJECT_ADS_REQUEST';
const REJECT_ADS_SUCCESS = 'vip/ads/REJECT_ADS_SUCCESS';
const REJECT_ADS_FAIL = 'vip/ads/REJECT_ADS_FAIL';
const GET_ADS_STATUS_REQUEST = 'vip/ads/GET_ADS_STATUS_REQUEST';
const GET_ADS_STATUS_SUCCESS = 'vip/ads/GET_ADS_STATUS_SUCCESS';
const GET_ADS_STATUS_FAIL = 'vip/ads/GET_ADS_STATUS_FAIL';
const SESSION_TOKEN_EXPIRED = 'vip/session/SESSION_TOKEN_EXPIRED';

// Reducer
const INITIAL_STATE = {
    error: null,
    isLoading: false,
    current: null,
    status: null,
    list: [],
    pagination: null,
};

export default function reducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SESSION_TOKEN_EXPIRED: {
            return INITIAL_STATE;
        }
        case GET_ADS_LIST_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case GET_ADS_LIST_SUCCESS: {
            return {
                ...state,
                list: action.payload.results,
                pagination: action.payload.pagination,
                isLoading: false,
                error: null,
            };
        }
        case GET_ADS_LIST_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case GET_ADS_STATUS_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case GET_ADS_STATUS_SUCCESS: {
            return {
                ...state,
                status: action.payload,
                isLoading: false,
                error: null,
            };
        }
        case GET_ADS_STATUS_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case GET_ADS_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case GET_ADS_SUCCESS: {
            return {
                ...state,
                current: action.payload,
                isLoading: false,
                error: null,
            };
        }
        case GET_ADS_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case DELETE_ADS_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case DELETE_ADS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: null,
            };
        }
        case DELETE_ADS_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case APPROVE_ADS_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case APPROVE_ADS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: null,
            };
        }
        case APPROVE_ADS_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case REJECT_ADS_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case REJECT_ADS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: null,
            };
        }
        case REJECT_ADS_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case POST_CREATE_ADS_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case POST_CREATE_ADS_SUCCESS: {
            return {
                ...state,
                current: action.payload,
                isLoading: false,
                error: null,
            };
        }
        case POST_CREATE_ADS_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case PATCH_EDIT_ADS_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case PATCH_EDIT_ADS_SUCCESS: {
            return {
                ...state,
                current: action.payload,
                isLoading: false,
                error: null,
            };
        }
        case PATCH_EDIT_ADS_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        default:
            return state;
    }
}

// Action Creators
export function getAdvertisementListRequest() {
    return {
        type: GET_ADS_LIST_REQUEST,
    };
}
export function getAdvertisementListSuccess(payload) {
    return {
        type: GET_ADS_LIST_SUCCESS,
        payload,
    };
}
export function getAdvertisementListFail(error) {
    return {
        type: GET_ADS_LIST_FAIL,
        error,
    };
}
export function getAdvertisementStatusRequest() {
    return {
        type: GET_ADS_STATUS_REQUEST,
    };
}
export function getAdvertisementStatusSuccess(payload) {
    return {
        type: GET_ADS_STATUS_SUCCESS,
        payload,
    };
}
export function getAdvertisementStatusFail(error) {
    return {
        type: GET_ADS_STATUS_FAIL,
        error,
    };
}
export function getAdvertisementRequest() {
    return {
        type: GET_ADS_REQUEST,
    };
}
export function getAdvertisementuccess(payload) {
    return {
        type: GET_ADS_SUCCESS,
        payload,
    };
}
export function getAdvertisementFail(error) {
    return {
        type: GET_ADS_FAIL,
        error,
    };
}
export function deleteAdsRequest() {
    return {
        type: DELETE_ADS_REQUEST,
    };
}
export function deleteAdsSuccess() {
    return {
        type: DELETE_ADS_SUCCESS,
    };
}
export function deleteAdsFail(error) {
    return {
        type: DELETE_ADS_FAIL,
        error,
    };
}
export function approveAdsRequest() {
    return {
        type: APPROVE_ADS_REQUEST,
    };
}
export function approveAdsSuccess() {
    return {
        type: APPROVE_ADS_SUCCESS,
    };
}
export function approveAdsFail(error) {
    return {
        type: APPROVE_ADS_FAIL,
        error,
    };
}
export function rejectAdsRequest() {
    return {
        type: REJECT_ADS_REQUEST,
    };
}
export function rejectAdsSuccess() {
    return {
        type: REJECT_ADS_SUCCESS,
    };
}
export function rejectAdsFail(error) {
    return {
        type: REJECT_ADS_FAIL,
        error,
    };
}
export function updateAdsRequest() {
    return {
        type: PATCH_EDIT_ADS_REQUEST,
    };
}
export function updateAdsSuccess(payload) {
    return {
        type: PATCH_EDIT_ADS_SUCCESS,
        payload,
    };
}
export function updateAdsFail(error) {
    return {
        type: PATCH_EDIT_ADS_FAIL,
        error,
    };
}
export function createAdsRequest() {
    return {
        type: POST_CREATE_ADS_REQUEST,
    };
}
export function createAdsSuccess(payload) {
    return {
        type: POST_CREATE_ADS_SUCCESS,
        payload,
    };
}
export function createAdsFail(error) {
    return {
        type: POST_CREATE_ADS_FAIL,
        error,
    };
}
// side effects, only as applicable
// e.g. thunks, epics, etc
export const getAdvertisementList = ({
    page_size,
    page_number,
    team_ids,
    user_ids,
    start_date,
    end_date,
    filter_status,
} = {}) => async (dispatch) => {
    dispatch(getAdvertisementListRequest());
    try {
        const list = await AdsService.getAdvertisementList({
            page_size,
            page_number,
            team_ids,
            user_ids,
            start_date,
            end_date,
            filter_status,
        });
        dispatch(getAdvertisementListSuccess(list));
    } catch (error) {
        dispatch(getAdvertisementListFail(error));
        throw error;
    }
};
export const createAds = ({
    ads_amount,
    ads_date,
    team_id,
    member_id,
    ads_type,
    expense_category,
}) => async (dispatch) => {
    dispatch(createAdsRequest());
    try {
        const result = await AdsService.createAds({
            ads_amount,
            ads_date,
            team_id,
            member_id,
            ads_type,
            expense_category,
        });
        dispatch(createAdsSuccess(result));
    } catch (error) {
        dispatch(createAdsFail(error));
        throw error;
    }
};
export const getAdvertisementById = ({ id }) => async (dispatch) => {
    dispatch(getAdvertisementRequest());
    try {
        const result = await AdsService.getAdvertisementById({
            id,
        });
        dispatch(getAdvertisementuccess(result));
    } catch (error) {
        dispatch(getAdvertisementFail(error));
        throw error;
    }
};
export const getAdvertisementStatus = () => async (dispatch) => {
    dispatch(getAdvertisementStatusRequest());
    try {
        const result = await AdsService.getAdvertisementStatus();
        dispatch(getAdvertisementStatusSuccess(result));
    } catch (error) {
        dispatch(getAdvertisementStatusFail(error));
        throw error;
    }
};
export const updateAds = ({
    id,
    ads_amount,
    ads_date,
    team_id,
    member_id,
    ads_type,
    expense_category,
}) => async (dispatch) => {
    dispatch(updateAdsRequest());
    try {
        const result = await AdsService.updateAds({
            id,
            ads_amount,
            ads_date,
            team_id,
            member_id,
            ads_type,
            expense_category,
        });
        dispatch(updateAdsSuccess(result));
    } catch (error) {
        dispatch(updateAdsFail(error));
        throw error;
    }
};
export const deleteAdsById = ({ id }) => async (dispatch) => {
    dispatch(deleteAdsRequest());
    try {
        await AdsService.deleteAdsById({
            id,
        });
        dispatch(deleteAdsSuccess());
    } catch (error) {
        dispatch(deleteAdsFail(error));
        throw error;
    }
};
export const approveAdsById = ({ id }) => async (dispatch) => {
    dispatch(approveAdsRequest());
    try {
        await AdsService.approveAdsById({
            id,
        });
        dispatch(approveAdsSuccess());
    } catch (error) {
        dispatch(approveAdsFail(error));
        throw error;
    }
};
export const rejectAdsById = ({ id }) => async (dispatch) => {
    dispatch(rejectAdsRequest());
    try {
        await AdsService.rejectAdsById({
            id,
        });
        dispatch(rejectAdsSuccess());
    } catch (error) {
        dispatch(rejectAdsFail(error));
        throw error;
    }
};
