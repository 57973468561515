import { connect } from 'react-redux';
import Announcement from './Announcement';
import { getTeamList } from '../../ducks/team';

const mapStateToProps = (state) => {
    return {
        session: state.session,
        team: state.team,
    };
};

const AnnouncementContainer = connect(mapStateToProps, {
    getTeamList,
})(Announcement);
export default AnnouncementContainer;
