import { NetworkError, HttpErrorHandler } from './service';
import { VipAPIService } from '../services';
import Clock from '../models/clock';

export default class ClockService {
    static async getClockList({
        page_size,
        page_number,
        team_id,
        member_id,
        start_date_time,
        end_date_time,
    }) {
        const [error, response] = await VipAPIService.shared.getClockList({
            page_size,
            page_number,
            team_id,
            member_id,
            start_date_time,
            end_date_time,
        });
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return {
                results: response.data.results.map((clock) => new Clock(clock)),
                pagination: response.data.pagination,
            };
        }
    }
    static async postClockIn({ lat, lng }) {
        const [error, response] = await VipAPIService.shared.postClockIn({
            lat,
            lng,
        });
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return new Clock(response.data);
        }
    }
    static async patchClockOut() {
        const [error, response] = await VipAPIService.shared.patchClockOut();
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return new Clock(response.data);
        }
    }
}
