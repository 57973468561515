import styled from 'styled-components';
export const Container = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
`;
export const ButtonContainer = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 50px;
`;
export const IconButton = styled.div`
    cursor: pointer;
    color: #108ee9;
`;
export const FilterContainer = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
`;
