import React, { Component } from 'react';
import { notification, Card, Tag } from 'antd';
import {
    Container,
    CardContainer,
    InnerCardInfoContainer,
    DateLabel,
    Titlelabel,
} from './index.style';

class Announcement extends Component {
    state = {
        loading: false,
        data: [],
        pagination: {
            current: 1,
            total: 0,
            showSizeChanger: false,
        },
    };
    constructor(props) {
        super(props);
        this.div = React.createRef();
    }
    componentDidMount = async () => {
        this.div.current.scrollIntoView({ behavior: 'smooth' });
        this.setState({ loading: true });
        await this.props.getTeamList({ page_size: 50 });
    };
    componentDidUpdate = (prevProps) => {
        if (this.props.team.error && this.props.team.error !== prevProps.team.error) {
            this.openNotificationWithIcon({
                type: 'error',
                title: this.props.team.error.frontend_type,
                message: this.props.team.error.message,
            });
            this.setState({
                isLoading: this.props.team.isLoading,
            });
        }
        if (prevProps.team.list !== this.props.team.list) {
            this.setState({
                data: this.props.team.list,
                loading: false,
                pagination: {
                    ...this.state.pagination,
                    current: this.props.team.pagination.page,
                    total: this.props.team.pagination.row_count,
                },
            });
        }
    };
    openNotificationWithIcon = ({ type, title, message }) => {
        notification[type]({
            message: title,
            description: message,
        });
    };
    render() {
        return (
            <Container ref={this.div}>
                <CardContainer>
                    <Card title="Card title" bordered>
                        <InnerCardInfoContainer>
                            <div
                                style={{
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    flexDirection: 'column',
                                }}
                            >
                                <Titlelabel>Date</Titlelabel>
                                <DateLabel>21/05/2020</DateLabel>
                            </div>
                            <div
                                style={{
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'flex-end',
                                    flexDirection: 'column',
                                }}
                            >
                                <Titlelabel>Posted By</Titlelabel>
                                <Tag color="#87d068" style={{ marginRight: '0px' }}>
                                    <b>Admin</b>
                                </Tag>
                            </div>
                        </InnerCardInfoContainer>
                    </Card>
                </CardContainer>
            </Container>
        );
    }
}

export default Announcement;
