import UserService from '../services/userService';
// Actions
const GET_USER_LIST_REQUEST = 'vip/user/GET_USER_LIST_REQUEST';
const GET_USER_LIST_SUCCESS = 'vip/user/GET_USER_LIST_SUCCESS';
const GET_USER_LIST_FAIL = 'vip/user/GET_USER_LIST_FAIL';
const GET_USER_REQUEST = 'vip/user/GET_USER_REQUEST';
const GET_USER_SUCCESS = 'vip/user/GET_USER_SUCCESS';
const GET_USER_FAIL = 'vip/user/GET_USER_FAIL';
const POST_CREATE_AGENT_REQUEST = 'vip/user/POST_CREATE_AGENT_REQUEST';
const POST_CREATE_AGENT_SUCCESS = 'vip/user/POST_CREATE_AGENT_SUCCESS';
const POST_CREATE_AGENT_FAIL = 'vip/user/POST_CREATE_AGENT_FAIL';
const POST_CREATE_ACCOUNTANT_REQUEST = 'vip/user/POST_CREATE_ACCOUNTANT_REQUEST';
const POST_CREATE_ACCOUNTANT_SUCCESS = 'vip/user/POST_CREATE_ACCOUNTANT_SUCCESS';
const POST_CREATE_ACCOUNTANT_FAIL = 'vip/user/POST_CREATE_ACCOUNTANT_FAIL';
const POST_CREATE_MEMBER_REQUEST = 'vip/user/POST_CREATE_MEMBER_REQUEST';
const POST_CREATE_MEMBER_SUCCESS = 'vip/user/POST_CREATE_MEMBER_SUCCESS';
const POST_CREATE_MEMBER_FAIL = 'vip/user/POST_CREATE_MEMBER_FAIL';
const PATCH_EDIT_USER_REQUEST = 'vip/user/PATCH_EDIT_USER_REQUEST';
const PATCH_EDIT_USER_SUCCESS = 'vip/user/PATCH_EDIT_USER_SUCCESS';
const PATCH_EDIT_USER_FAIL = 'vip/user/PATCH_EDIT_USER_FAIL';
const SESSION_TOKEN_EXPIRED = 'vip/session/SESSION_TOKEN_EXPIRED';
const POST_CREATE_ADS_MANAGER_REQUEST = 'vip/user/POST_CREATE_ADS_MANAGER_REQUEST';
const POST_CREATE_ADS_MANAGER_SUCCESS = 'vip/user/POST_CREATE_ADS_MANAGER_SUCCESS';
const POST_CREATE_ADS_MANAGER_FAIL = 'vip/user/POST_CREATE_ADS_MANAGER_FAIL';

// Reducer
const INITIAL_STATE = {
    error: null,
    isLoading: false,
    current: null,
    list: [],
    pagination: null,
};

export default function reducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SESSION_TOKEN_EXPIRED: {
            return INITIAL_STATE;
        }
        case GET_USER_LIST_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case GET_USER_LIST_SUCCESS: {
            return {
                ...state,
                list: action.payload.results,
                pagination: action.payload.pagination,
                isLoading: false,
                error: null,
            };
        }
        case GET_USER_LIST_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case GET_USER_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case GET_USER_SUCCESS: {
            return {
                ...state,
                current: action.payload,
                isLoading: false,
                error: null,
            };
        }
        case GET_USER_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case POST_CREATE_AGENT_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case POST_CREATE_AGENT_SUCCESS: {
            return {
                ...state,
                current: action.payload,
                isLoading: false,
                error: null,
            };
        }
        case POST_CREATE_AGENT_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case POST_CREATE_ACCOUNTANT_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case POST_CREATE_ACCOUNTANT_SUCCESS: {
            return {
                ...state,
                current: action.payload,
                isLoading: false,
                error: null,
            };
        }
        case POST_CREATE_ACCOUNTANT_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case POST_CREATE_MEMBER_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case POST_CREATE_MEMBER_SUCCESS: {
            return {
                ...state,
                current: action.payload,
                isLoading: false,
                error: null,
            };
        }
        case POST_CREATE_MEMBER_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case PATCH_EDIT_USER_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case PATCH_EDIT_USER_SUCCESS: {
            return {
                ...state,
                current: action.payload,
                isLoading: false,
                error: null,
            };
        }
        case PATCH_EDIT_USER_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case POST_CREATE_ADS_MANAGER_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case POST_CREATE_ADS_MANAGER_SUCCESS: {
            return {
                ...state,
                current: action.payload,
                isLoading: false,
                error: null,
            };
        }
        case POST_CREATE_ADS_MANAGER_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        default:
            return state;
    }
}

// Action Creators
export function getUserListRequest() {
    return {
        type: GET_USER_LIST_REQUEST,
    };
}
export function getUserListSuccess(payload) {
    return {
        type: GET_USER_LIST_SUCCESS,
        payload,
    };
}
export function getUserListFail(error) {
    return {
        type: GET_USER_LIST_FAIL,
        error,
    };
}
export function getUserRequest() {
    return {
        type: GET_USER_REQUEST,
    };
}
export function getUserSuccess(payload) {
    return {
        type: GET_USER_SUCCESS,
        payload,
    };
}
export function getUserFail(error) {
    return {
        type: GET_USER_FAIL,
        error,
    };
}
export function updateUserRequest() {
    return {
        type: PATCH_EDIT_USER_REQUEST,
    };
}
export function updateUserSuccess(payload) {
    return {
        type: PATCH_EDIT_USER_SUCCESS,
        payload,
    };
}
export function updateUserFail(error) {
    return {
        type: PATCH_EDIT_USER_FAIL,
        error,
    };
}
export function createAgentRequest() {
    return {
        type: POST_CREATE_AGENT_REQUEST,
    };
}
export function createAgentSuccess(payload) {
    return {
        type: POST_CREATE_AGENT_SUCCESS,
        payload,
    };
}
export function createAgentFail(error) {
    return {
        type: POST_CREATE_AGENT_FAIL,
        error,
    };
}
export function createAccountantRequest() {
    return {
        type: POST_CREATE_ACCOUNTANT_REQUEST,
    };
}
export function createAccountantSuccess(payload) {
    return {
        type: POST_CREATE_ACCOUNTANT_SUCCESS,
        payload,
    };
}
export function createAccountantFail(error) {
    return {
        type: POST_CREATE_ACCOUNTANT_FAIL,
        error,
    };
}
export function createMemberRequest() {
    return {
        type: POST_CREATE_MEMBER_REQUEST,
    };
}
export function createMemberSuccess(payload) {
    return {
        type: POST_CREATE_MEMBER_SUCCESS,
        payload,
    };
}
export function createMemberFail(error) {
    return {
        type: POST_CREATE_MEMBER_FAIL,
        error,
    };
}

export function createAdsManagerRequest() {
    return {
        type: POST_CREATE_ADS_MANAGER_REQUEST,
    };
}
export function createAdsManagerSuccess(payload) {
    return {
        type: POST_CREATE_ADS_MANAGER_SUCCESS,
        payload,
    };
}
export function createAdsManagerFail(error) {
    return {
        type: POST_CREATE_ADS_MANAGER_FAIL,
        error,
    };
}

// side effects, only as applicable
// e.g. thunks, epics, etc
export const getUserList = ({ page_size, page_number, user_type, team_ids } = {}) => async (
    dispatch
) => {
    dispatch(getUserListRequest());
    try {
        const list = await UserService.getUserList({ page_size, page_number, user_type, team_ids });
        dispatch(getUserListSuccess(list));
    } catch (error) {
        dispatch(getUserListFail(error));
        throw error;
    }
};
export const createAgent = ({ username, password, firstName, lastName, phoneNumber }) => async (
    dispatch
) => {
    dispatch(createAgentRequest());
    try {
        const result = await UserService.createAgent({
            username,
            password,
            firstName,
            lastName,
            phoneNumber,
        });
        dispatch(createAgentSuccess(result));
    } catch (error) {
        dispatch(createAgentFail(error));
        throw error;
    }
};
export const createAccountant = ({
    username,
    password,
    firstName,
    lastName,
    phoneNumber,
}) => async (dispatch) => {
    dispatch(createAccountantRequest());
    try {
        const result = await UserService.createAccountant({
            username,
            password,
            firstName,
            lastName,
            phoneNumber,
        });
        dispatch(createAccountantSuccess(result));
    } catch (error) {
        dispatch(createAccountantFail(error));
        throw error;
    }
};
export const createAdsManager = ({
    username,
    password,
    firstName,
    lastName,
    phoneNumber,
}) => async (dispatch) => {
    dispatch(createAdsManagerRequest());
    try {
        const result = await UserService.createAdsManager({
            username,
            password,
            firstName,
            lastName,
            phoneNumber,
        });
        dispatch(createAdsManagerSuccess(result));
    } catch (error) {
        dispatch(createAdsManagerFail(error));
        throw error;
    }
};
export const createMember = ({
    username,
    password,
    firstName,
    lastName,
    phoneNumber,
    teamId,
    commissionPercentage,
    commissionsTier,
}) => async (dispatch) => {
    dispatch(createMemberRequest());
    try {
        const result = await UserService.createMember({
            username,
            password,
            firstName,
            lastName,
            phoneNumber,
            teamId,
            commissionPercentage,
            commissionsTier,
        });
        dispatch(createMemberSuccess(result));
    } catch (error) {
        dispatch(createMemberFail(error));
        throw error;
    }
};
export const getUserById = ({ id }) => async (dispatch) => {
    dispatch(getUserRequest());
    try {
        const result = await UserService.getUserById({
            id,
        });
        dispatch(getUserSuccess(result));
    } catch (error) {
        dispatch(getUserFail(error));
        throw error;
    }
};
export const updateUser = ({
    id,
    username,
    password,
    firstName,
    lastName,
    phoneNumber,
    status,
    commissionPercentage,
    commissionsTier,
}) => async (dispatch) => {
    dispatch(updateUserRequest());
    try {
        const result = await UserService.updateUser({
            id,
            username,
            password,
            firstName,
            lastName,
            phoneNumber,
            status,
            commissionPercentage,
            commissionsTier,
        });
        dispatch(updateUserSuccess(result));
    } catch (error) {
        dispatch(updateUserFail(error));
        throw error;
    }
};
