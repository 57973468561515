export class NetworkError {
    constructor(message) {
        this.message = message;
    }
    toString() {
        return this.message;
    }
}

export class HttpErrorHandler {
    constructor(data) {
        this.status = data.statusCode;
        this.frontend_type = data.frontend_type;
        this.message = data.message;
        this.details = data.details;
    }
    toString() {
        return `HTTP ${this.status}: ${this.frontend_type} - ${this.message}`;
    }
}
