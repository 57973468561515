import React, { Component } from 'react';
import { Button, Input, Divider, message, notification, DatePicker, Tooltip, Select } from 'antd';
import moment from 'moment';
import {
    LeftOutlined,
    PlusOutlined,
    DeleteOutlined,
    SaveOutlined,
    BorderlessTableOutlined,
} from '@ant-design/icons';
import {
    Container,
    ButtonContainer,
    FormContainer,
    ButtonsContainer,
    DateContainer,
} from './index.style';
import { Constant, Utility } from '../../../helpers';

const { Option } = Select;
class EnquiryDashboard extends Component {
    state = {
        mode: null,
        date: null,
        enquiry: null,
        isLoading: false,
        teamId: null,
        memberId: null,
        teamList: [],
    };
    componentDidMount = async () => {
        const mode =
            Object.keys(this.props.match.params).length > 0
                ? Constant.PAGE_MODE.edit
                : Constant.PAGE_MODE.create;
        await this.setState({ mode });
        if (mode === Constant.PAGE_MODE.edit) {
            const id = this.props.match.params.id;
            await this.props.getEnquiryById({ id });
        }

        await this.props.getTeamList({ page_size: 50 });
    };
    componentDidUpdate = async (prevProps) => {
        if (this.props.enquiry.error && this.props.enquiry.error !== prevProps.enquiry.error) {
            this.openNotificationWithIcon({
                type: 'error',
                title: this.props.enquiry.error.frontend_type,
                message: this.props.enquiry.error.message,
            });
            this.setState({
                isLoading: this.props.enquiry.isLoading,
            });
        }
        if (prevProps.team.list !== this.props.team.list) {
            this.setState({
                teamList: this.props.team.list,
                isLoading: false,
            });
        }
        if (prevProps.enquiry.current !== this.props.enquiry.current) {
            const enquiry = this.props.enquiry.current;
            await this.props.getUserList({
                team_ids: [enquiry.teamId],
                user_type: [Constant.USER_TYPE.member, Constant.USER_TYPE.agent],
                page_size: 1000,
            });
            this.setState({
                date: moment(enquiry.enquiryDate),
                enquiry: String(enquiry.enquiryAmount),
                memberId: enquiry.memberId,
                isLoading: false,
                teamId: enquiry.teamId,
            });
        }
        if (prevProps.session.teamList !== this.props.session.teamList) {
            const teamList = this.props.session.teamList;
            if (teamList.length === 1) {
                this.setState({
                    teamId: teamList[0].team.id,
                });
            }
        }
    };
    openNotificationWithIcon = ({ type, title, message }) => {
        notification[type]({
            message: title,
            description: message,
        });
    };
    onDateChanged = (date) => {
        this.setState({
            date,
        });
    };
    onTeamChanged = async (team) => {
        await this.props.getUserList({
            team_ids: [team],
            user_type: [Constant.USER_TYPE.member, Constant.USER_TYPE.agent],
            page_size: 1000,
        });

        this.setState({ teamId: team, memberId: null });
    };
    onMemberChanged = async (member) => {
        this.setState({ memberId: member });
    };
    onBackButtonPressed = () => {
        this.props.history.replace('/enquiry');
    };
    onEnquiryChanged = ({ text }) => {
        if (Utility.numberRegexCheck(text) || Utility.decimalNumberRegexCheck(text)) {
            this.setState({ enquiry: text });
            return;
        }
        this.setState({ enquiry: null });
        return;
    };
    onDeleteButtonPressed = async () => {
        this.setState({ isLoading: true });
        await this.props.deleteEnquiryById({
            id: this.props.enquiry.current.id,
        });
        await this.props.getEnquiryList();
        message.success('Enquiry deleted!');
        this.onBackButtonPressed();
    };
    onFormValidation = () => {
        if (!this.state.teamId) {
            message.error('Team cannot be empty');
            return false;
        }
        if (!this.state.memberId) {
            message.error('Member cannot be empty');
            return false;
        }
        if (!this.state.date) {
            message.error('Date cannot be empty');
            return false;
        }
        if (!this.state.enquiry) {
            message.error('Enquiry amount cannot be empty');
            return false;
        }
        return true;
    };
    onSaveButtonPressed = async () => {
        if (this.onFormValidation()) {
            this.setState({ isLoading: true });
            await this.props.updateEnquiry({
                id: this.props.enquiry.current.id,
                enquiry_amount: this.state.enquiry,
                enquiry_date: moment(this.state.date).format('YYYY-MM-DD'),
                team_id: this.state.teamId,
                member_id: this.state.memberId,
            });
            await this.props.getEnquiryList();
            message.success('Enquiry updated!');
            this.onBackButtonPressed();
        }
    };
    onCreateButtonPressed = async () => {
        if (this.onFormValidation()) {
            this.setState({ isLoading: true });
            await this.props.createEnquiry({
                enquiry_amount: this.state.enquiry,
                enquiry_date: moment(this.state.date).format('YYYY-MM-DD'),
                team_id: this.state.teamId,
                member_id: this.state.memberId,
            });
            await this.props.getEnquiryList();
            message.success('Enquiry created!');
            this.onBackButtonPressed();
        }
    };
    disabledDate = (current) => {
        return current && current > moment().endOf('day');
    };
    render() {
        // const EOD =
        //     parseFloat(this.state.sales) -
        //     (parseFloat(this.state.ads) + parseFloat(this.state.expenses));
        return (
            <Container>
                <ButtonContainer>
                    <Button type="link" size={'large'} onClick={this.onBackButtonPressed}>
                        <LeftOutlined style={{ paddingRight: '10px' }} />
                        <b>Back</b>
                    </Button>
                </ButtonContainer>
                <FormContainer>
                    <Divider orientation="left">Enquiry Details</Divider>
                    <br />
                    <DateContainer>
                        <DatePicker
                            value={this.state.date}
                            style={{ width: 250 }}
                            placeholder={'Pick a Date'}
                            disabledDate={this.disabledDate}
                            onChange={this.onDateChanged}
                            size={'large'}
                            format={'DD/MM/YYYY'}
                        />
                    </DateContainer>
                    <br />
                    <DateContainer>
                        <Select
                            size={'large'}
                            showSearch
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            placeholder={'Select a Team'}
                            onChange={this.onTeamChanged}
                            style={{ width: 250 }}
                            value={this.state.teamId || 'Select a Team'}
                        >
                            {this.state.teamList.map((team) => {
                                return (
                                    <Option key={team.id} value={team.id}>
                                        {team.name}
                                    </Option>
                                );
                            })}
                        </Select>
                    </DateContainer>
                    <br />
                    <DateContainer>
                        <Select
                            showSearch
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            value={this.state.memberId || 'Select a Member'}
                            size={'large'}
                            style={{ width: 250 }}
                            optionFilterProp="children"
                            placeholder={'Select a Member'}
                            onChange={this.onMemberChanged}
                        >
                            {this.props.user.list.map((member) => {
                                return (
                                    <Option key={member.id} value={member.id}>
                                        {member.username}
                                    </Option>
                                );
                            })}
                        </Select>
                    </DateContainer>
                    <br />
                    <Tooltip placement="left" title="Enquiry Amount">
                        <Input
                            maxLength={12}
                            value={this.state.enquiry}
                            onChange={(event) =>
                                this.onEnquiryChanged({ text: event.target.value })
                            }
                            size="large"
                            placeholder="Enquiry Amount"
                            prefix={<BorderlessTableOutlined />}
                        />
                    </Tooltip>
                    <br />
                    <br />
                    {/* <ProfitLabel>Total Profit/Loss</ProfitLabel>
                    <ValueLabel EOD={EOD}>
                        RM {EOD ? Utility.convertNumberToStringCommaNumber(EOD.toFixed(2)) : '0'}
                    </ValueLabel> */}
                    <Divider />
                    {this.state.mode === Constant.PAGE_MODE.create ? (
                        <Button
                            type="primary"
                            size={'large'}
                            onClick={this.onCreateButtonPressed}
                            loading={this.state.isLoading}
                        >
                            <PlusOutlined style={{ paddingRight: '10px' }} />
                            <b>CREATE</b>
                        </Button>
                    ) : (
                        <ButtonsContainer>
                            <Button
                                type="primary"
                                size={'large'}
                                onClick={this.onSaveButtonPressed}
                                loading={this.state.isLoading}
                                style={{ marginRight: 10 }}
                            >
                                <SaveOutlined style={{ paddingRight: '10px' }} />
                                <b>SAVE</b>
                            </Button>
                            {this.props.session.permissions.includes(
                                Constant.USER_PERMISSIONS.verify_sale
                            ) && (
                                <Button
                                    type="danger"
                                    size={'large'}
                                    onClick={this.onDeleteButtonPressed}
                                    loading={this.state.isLoading}
                                >
                                    <DeleteOutlined style={{ paddingRight: '10px' }} />
                                    <b>DELETE</b>
                                </Button>
                            )}
                        </ButtonsContainer>
                    )}
                </FormContainer>
            </Container>
        );
    }
}

export default EnquiryDashboard;
