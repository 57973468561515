import React, { Component } from 'react';
import { Button, Input, Divider, message, notification, DatePicker, Tooltip, Select } from 'antd';
import moment from 'moment';
import {
    LeftOutlined,
    PlusOutlined,
    DeleteOutlined,
    SaveOutlined,
    BorderlessTableOutlined,
} from '@ant-design/icons';
import {
    Container,
    ButtonContainer,
    FormContainer,
    ButtonsContainer,
    DateContainer,
} from './index.style';
import { Constant, Utility } from '../../../helpers';

const { Option } = Select;
class AdsDashboard extends Component {
    state = {
        mode: null,
        date: null,
        ads: null,
        isLoading: false,
        teamId: null,
        memberId: null,
        adsType: null,
        teamList: [],
        expenseCategory: null,
    };
    componentDidMount = async () => {
        const mode =
            Object.keys(this.props.match.params).length > 0
                ? Constant.PAGE_MODE.edit
                : Constant.PAGE_MODE.create;
        await this.setState({ mode });
        if (mode === Constant.PAGE_MODE.edit) {
            const id = this.props.match.params.id;
            await this.props.getAdvertisementById({ id });
        }

        await this.props.getTeamList({ page_size: 50 });
    };
    componentDidUpdate = (prevProps) => {
        if (this.props.ads.error && this.props.ads.error !== prevProps.ads.error) {
            this.openNotificationWithIcon({
                type: 'error',
                title: this.props.ads.error.frontend_type,
                message: this.props.ads.error.message,
            });
            this.setState({
                isLoading: this.props.ads.isLoading,
            });
        }
        if (prevProps.team.list !== this.props.team.list) {
            this.setState({
                teamList: this.props.team.list,
                isLoading: false,
            });
        }
        if (prevProps.ads.current !== this.props.ads.current) {
            const ads = this.props.ads.current;
            this.setState({
                date: moment(ads.adsDate),
                ads: String(ads.adsAmount),
                memberId: ads.memberId,
                adsType: ads.adsType,
                isLoading: false,
                teamId: ads.teamId,
                expenseCategory: ads.expenseCategory,
            });
        }
        if (prevProps.session.teamList !== this.props.session.teamList) {
            const teamList = this.props.session.teamList;
            if (teamList.length === 1) {
                this.setState({
                    teamId: teamList[0].team.id,
                });
            }
        }
    };
    openNotificationWithIcon = ({ type, title, message }) => {
        notification[type]({
            message: title,
            description: message,
        });
    };
    onDateChanged = (date) => {
        this.setState({
            date,
        });
    };
    onAdsTypeChanged = (adsType) => {
        this.setState({ adsType });
    };
    onExpenseCategoryChanged = (expenseCategory) => {
        this.setState({ expenseCategory: expenseCategory || null });
    };
    onTeamChanged = async (team) => {
        await this.props.getUserList({
            team_ids: [team],
            user_type: [Constant.USER_TYPE.member, Constant.USER_TYPE.agent],
            page_size: 1000,
        });

        this.setState({ teamId: team, memberId: null });
    };
    onMemberChanged = async (member) => {
        this.setState({ memberId: member });
    };
    onBackButtonPressed = () => {
        this.props.history.replace('/ads');
    };
    onAdsChanged = ({ text }) => {
        if (Utility.numberRegexCheck(text) || Utility.decimalNumberRegexCheck(text)) {
            this.setState({ ads: text });
            return;
        }
        this.setState({ ads: null });
        return;
    };
    onDeleteButtonPressed = async () => {
        this.setState({ isLoading: true });
        await this.props.deleteAdsById({
            id: this.props.ads.current.id,
        });
        await this.props.getAdvertisementList();
        message.success('Ads deleted!');
        this.onBackButtonPressed();
    };
    onFormValidation = () => {
        if (!this.state.teamId) {
            message.error('Team cannot be empty');
            return false;
        }
        if (!this.state.memberId) {
            message.error('Member cannot be empty');
            return false;
        }
        if (!this.state.adsType) {
            message.error('Ads Type cannot be empty');
            return false;
        }
        if (!this.state.date) {
            message.error('Date cannot be empty');
            return false;
        }
        if (!this.state.ads) {
            message.error('Ads cannot be empty');
            return false;
        }
        if (this.state.adsType === 'Expense' && !this.state.expenseCategory) {
            message.error('Expense category cannot be empty');
            return false;
        }
        return true;
    };
    onSaveButtonPressed = async () => {
        if (this.onFormValidation()) {
            this.setState({ isLoading: true });
            await this.props.updateAds({
                id: this.props.ads.current.id,
                ads_amount: this.state.ads,
                ads_date: moment(this.state.date).format('YYYY-MM-DD'),
                team_id: this.state.teamId,
                member_id: this.state.memberId,
                ads_type: this.state.adsType,
                expense_category:
                    this.state.adsType === 'Expense' ? this.state.expenseCategory : null,
            });
            await this.props.getAdvertisementList();
            message.success('Ads updated!');
            this.onBackButtonPressed();
        }
    };
    onCreateButtonPressed = async () => {
        if (this.onFormValidation()) {
            this.setState({ isLoading: true });
            await this.props.createAds({
                ads_amount: this.state.ads,
                ads_date: moment(this.state.date).format('YYYY-MM-DD'),
                team_id: this.state.teamId,
                member_id: this.state.memberId,
                ads_type: this.state.adsType,
                expense_category:
                    this.state.adsType === 'Expense' ? this.state.expenseCategory : null,
            });
            await this.props.getAdvertisementList();
            message.success('Ads created!');
            this.onBackButtonPressed();
        }
    };
    disabledDate = (current) => {
        return current && current > moment().endOf('day');
    };
    render() {
        // const EOD =
        //     parseFloat(this.state.sales) -
        //     (parseFloat(this.state.ads) + parseFloat(this.state.expenses));
        return (
            <Container>
                <ButtonContainer>
                    <Button type="link" size={'large'} onClick={this.onBackButtonPressed}>
                        <LeftOutlined style={{ paddingRight: '10px' }} />
                        <b>Back</b>
                    </Button>
                </ButtonContainer>
                <FormContainer>
                    <Divider orientation="left">Ads Details</Divider>
                    <br />
                    <DateContainer>
                        <DatePicker
                            value={this.state.date}
                            style={{ width: 250 }}
                            placeholder={'Pick a Date'}
                            disabledDate={this.disabledDate}
                            onChange={this.onDateChanged}
                            size={'large'}
                            format={'DD/MM/YYYY'}
                        />
                    </DateContainer>
                    <br />
                    <DateContainer>
                        <Select
                            size={'large'}
                            showSearch
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            placeholder={'Select a Team'}
                            onChange={this.onTeamChanged}
                            style={{ width: 250 }}
                            value={this.state.teamId || 'Select a Team'}
                        >
                            {this.state.teamList.map((team) => {
                                return (
                                    <Option key={team.id} value={team.id}>
                                        {team.name}
                                    </Option>
                                );
                            })}
                        </Select>
                    </DateContainer>
                    <br />
                    <DateContainer>
                        <Select
                            showSearch
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            value={this.state.memberId || 'Select a Member'}
                            size={'large'}
                            style={{ width: 250 }}
                            optionFilterProp="children"
                            placeholder={'Select a Member'}
                            onChange={this.onMemberChanged}
                        >
                            {this.props.user.list.map((member) => {
                                return (
                                    <Option key={member.id} value={member.id}>
                                        {member.username}
                                    </Option>
                                );
                            })}
                        </Select>
                    </DateContainer>
                    <br />
                    <DateContainer>
                        <Select
                            size={'large'}
                            showSearch
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            placeholder={'Select a Ads Type'}
                            onChange={this.onAdsTypeChanged}
                            style={{ width: 250 }}
                            value={this.state.adsType || 'Select a Ads Type'}
                        >
                            {Object.values(Constant.ADS_TYPE).map((at) => (
                                <Option key={at} value={at}>
                                    {at}
                                </Option>
                            ))}
                        </Select>
                    </DateContainer>
                    <br />
                    {this.state.adsType === 'Expense' && (
                        <DateContainer>
                            <Select
                                allowClear
                                size={'large'}
                                showSearch
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                placeholder={'Select a Expense Category'}
                                onChange={this.onExpenseCategoryChanged}
                                style={{ width: 250 }}
                                value={this.state.expenseCategory || 'Select a Expense Category'}
                            >
                                {Object.values(Constant.EXPENSE_CATEGORY).map((ec) => (
                                    <Option key={ec} value={ec}>
                                        {ec}
                                    </Option>
                                ))}
                            </Select>
                        </DateContainer>
                    )}

                    <br />
                    <Tooltip placement="left" title="Ads Amount">
                        <Input
                            maxLength={12}
                            value={this.state.ads}
                            onChange={(event) => this.onAdsChanged({ text: event.target.value })}
                            size="large"
                            placeholder="Ads Amount"
                            suffix="MYR"
                            prefix={<BorderlessTableOutlined />}
                        />
                    </Tooltip>
                    <br />
                    <br />
                    {/* <ProfitLabel>Total Profit/Loss</ProfitLabel>
                    <ValueLabel EOD={EOD}>
                        RM {EOD ? Utility.convertNumberToStringCommaNumber(EOD.toFixed(2)) : '0'}
                    </ValueLabel> */}
                    <Divider />
                    {this.state.mode === Constant.PAGE_MODE.create ? (
                        <Button
                            type="primary"
                            size={'large'}
                            onClick={this.onCreateButtonPressed}
                            loading={this.state.isLoading}
                        >
                            <PlusOutlined style={{ paddingRight: '10px' }} />
                            <b>CREATE</b>
                        </Button>
                    ) : (
                        <ButtonsContainer>
                            <Button
                                type="primary"
                                size={'large'}
                                onClick={this.onSaveButtonPressed}
                                loading={this.state.isLoading}
                                style={{ marginRight: 10 }}
                            >
                                <SaveOutlined style={{ paddingRight: '10px' }} />
                                <b>SAVE</b>
                            </Button>
                            {this.props.session.permissions.includes(
                                Constant.USER_PERMISSIONS.verify_sale
                            ) && (
                                <Button
                                    type="danger"
                                    size={'large'}
                                    onClick={this.onDeleteButtonPressed}
                                    loading={this.state.isLoading}
                                >
                                    <DeleteOutlined style={{ paddingRight: '10px' }} />
                                    <b>DELETE</b>
                                </Button>
                            )}
                        </ButtonsContainer>
                    )}
                </FormContainer>
            </Container>
        );
    }
}

export default AdsDashboard;
