import axios from 'axios';
import querystring from 'query-string';
import { throttleAdapterEnhancer } from 'axios-extensions';

import { Utility } from '../helpers';

let sharedService = null;
class VipAPIService {
    static get shared() {
        return sharedService;
    }

    constructor() {
        this.api = axios.create({
            timeout: 60000,
            adapter: throttleAdapterEnhancer(
                String(process.env.ENVIRONMENT) === 'testing'
                    ? require('axios/lib/adapters/http')
                    : require('axios/lib/adapters/xhr'),
                { threshold: 2 * 1000 }
            ),
        });

        if (!sharedService) {
            sharedService = this;
        }
    }

    // CRUD
    get(path, params = null) {
        const queryParams = Object.assign({}, params || {});
        const queryString = querystring.stringify(queryParams);
        return this.validateSessionToken(this.api.get(`${path}${params ? `?${queryString}` : ''}`));
    }

    post(path, payload) {
        return this.validateSessionToken(this.api.post(path, payload));
    }

    put(path, payload) {
        return this.validateSessionToken(this.api.put(path, payload));
    }

    patch(path, payload) {
        return this.validateSessionToken(this.api.patch(path, payload));
    }

    delete(path, payload) {
        return this.validateSessionToken(this.api.delete(path, payload));
    }

    async validateSessionToken(promise) {
        const [error, response] = await Utility.resolvePromise(promise);

        if (error) {
            if (error.response && error.response.status === 401) {
                Utility.logoutSession();
            }

            if (!error.response) {
                // error from axios, not from api server
                let errorResponse = {
                    data: {
                        message: error,
                    },
                };
                error.response = errorResponse;
            }
        }

        return [error, response];
    }

    /**
     *  User Related Endpoints
     */
    postSignIn = ({ username = null, password = null }) => {
        return this.post('/api/v1/user/login', {
            username,
            password,
        });
    };
    getProfile = () => {
        return this.get('/api/v1/user/me');
    };
    getUserList = ({ page_size = 10, page_number = 1, user_type, team_ids }) => {
        let url = `/api/v1/users?page_size=${page_size}&page_number=${page_number}`;
        const userType = Array.isArray(user_type) ? user_type : [`"${user_type}"`];
        if (user_type) {
            url = `${url}&search=[{"column":"user_type", "value": ${userType}}]`;
        }
        if (team_ids) {
            if (!team_ids.includes('All')) {
                url = `${url}&team_ids=${team_ids}`;
            }
        }
        return this.get(url);
    };
    getUserById = ({ id }) => {
        return this.get(`/api/v1/user/${id}`);
    };
    updateUser = ({
        id,
        username,
        password,
        firstName,
        lastName,
        phoneNumber,
        status,
        commissionPercentage = null,
        commissionsTier,
    }) => {
        const payload = {
            id,
            username,
            password,
            first_name: firstName,
            last_name: lastName,
            phone_number: phoneNumber,
            status,
            commission_percentage: commissionPercentage,
            commissions_tier: commissionsTier,
        };
        if (password) {
            payload['password'] = password;
        }
        return this.patch('/api/v1/user', payload);
    };
    createAgent = ({ username, password, firstName, lastName, phoneNumber }) => {
        return this.post('/api/v1/user/register/agent', {
            username,
            password,
            first_name: firstName,
            last_name: lastName,
            phone_number: phoneNumber,
        });
    };
    createAccountant = ({ username, password, firstName, lastName, phoneNumber }) => {
        return this.post('/api/v1/user/register/accountant', {
            username,
            password,
            first_name: firstName,
            last_name: lastName,
            phone_number: phoneNumber,
        });
    };
    createMember = ({
        username,
        password,
        firstName,
        lastName,
        phoneNumber,
        teamId,
        commissionPercentage = null,
        commissionsTier,
    }) => {
        return this.post('/api/v1/user/register/member', {
            username,
            password,
            first_name: firstName,
            last_name: lastName,
            phone_number: phoneNumber,
            team_id: teamId,
            commission_percentage: commissionPercentage,
            commissions_tier: commissionsTier,
        });
    };
    /**
     *  Team Related Endpoints
     */
    getTeamList = ({ page_size = 10, page_number = 1 }) => {
        return this.get(`/api/v1/teams?page_size=${page_size}&page_number=${page_number}`);
    };
    getTeamById = ({ id }) => {
        return this.get(`/api/v1/team/${id}`);
    };
    updateTeam = ({ id, name, user_id, commission_percentage = null }) => {
        return this.patch('/api/v1/team', {
            id,
            name,
            user_id,
            commission_percentage,
        });
    };
    createTeam = ({ name, user_id, commission_percentage = null }) => {
        return this.post('/api/v1/team', {
            name,
            user_id,
            commission_percentage,
        });
    };
    deleteTeamById = ({ id }) => {
        return this.delete('/api/v1/team', { data: { id } });
    };
    /**
     *  Bank Related Endpoints
     */
    getBankList = ({ page_size = 30, page_number = 1 }) => {
        return this.get(`/api/v1/banks?page_size=${page_size}&page_number=${page_number}`);
    };
    getBankAccountList = ({ page_size = 10, page_number = 1 }) => {
        return this.get(`/api/v1/bank/accounts?page_size=${page_size}&page_number=${page_number}`);
    };
    getBankAccountById = ({ id }) => {
        return this.get(`/api/v1/bank/account/${id}`);
    };
    updateBankAccount = ({ id, bank_id, name, account_number, amount_limit, percentage }) => {
        return this.patch('/api/v1/bank/account', {
            id,
            bank_id,
            name,
            account_number,
            amount_limit,
            percentage,
        });
    };
    createBankAccount = ({ bank_id, name, account_number, amount_limit, percentage }) => {
        return this.post('/api/v1/bank/account', {
            bank_id,
            name,
            account_number,
            amount_limit,
            percentage,
        });
    };
    deleteBankAccountById = ({ id }) => {
        return this.delete('/api/v1/bank/account', { data: { id } });
    };
    /**
     *  Sales Related Endpoints
     */
    getSalesList = ({
        page_size = 10,
        page_number = 1,
        team_ids,
        bank_id,
        member_ids,
        start_date,
        end_date,
        filter_status,
    }) => {
        let url = `/api/v1/sales?page_size=${page_size}&page_number=${page_number}`;
        if (filter_status) {
            if (filter_status !== 'all') {
                url = `${url}&search=[{"column":"status", "value": "${filter_status}", "operator": "="}]`;
            }
        }
        if (team_ids) {
            if (!team_ids.includes('All') && !team_ids.includes('all')) {
                url = `${url}&team_ids=${team_ids}`;
            }
        }
        if (start_date && end_date) {
            url = `${url}&start_date=${start_date}&end_date=${end_date}&search=[{"column":"status", "value": "accountant_approved"}]`;
        }
        if (member_ids) {
            if (!member_ids.includes('All') && !member_ids.includes('all')) {
                url = `${url}&member_ids=${member_ids}`;
            }
        }
        if (bank_id) {
            if (bank_id !== 'all') {
                url = `${url}&bank_id=${bank_id}`;
            }
        }
        return this.get(url);
    };
    getSaleById = ({ id }) => {
        return this.get(`/api/v1/sale/${id}`);
    };
    updateSale = ({
        id,
        sales_amount,
        ads_amount,
        expenses_amount,
        sales_date,
        transfer_type,
        team_id,
        bank_account_id,
        expense_category,
    }) => {
        return this.patch('/api/v1/sale', {
            id,
            sales_amount,
            ads_amount,
            expenses_amount,
            sales_date,
            transfer_type,
            team_id,
            bank_account_id,
            expense_category,
        });
    };
    createSale = ({
        sales_amount,
        ads_amount,
        expenses_amount,
        sales_date,
        team_id,
        bank_account_id,
        transfer_type,
        expense_category,
    }) => {
        return this.post('/api/v1/sale', {
            sales_amount,
            ads_amount,
            expenses_amount,
            sales_date,
            team_id,
            bank_account_id,
            transfer_type,
            expense_category,
        });
    };
    deleteSaleById = ({ id }) => {
        return this.delete('/api/v1/sale', { data: { id } });
    };
    approveSaleById = ({ id }) => {
        return this.patch('/api/v1/sale/approve', {
            id,
        });
    };
    rejectSaleById = ({ id }) => {
        return this.patch('/api/v1/sale/reject', {
            id,
        });
    };
    getSalesStatus = () => {
        return this.get('/api/v1/sales/status');
    };
    /**
     *  VIP link Related Endpoints
     */
    getClientLinkById = ({ id }) => {
        return this.get(`/api/v1/viplink/direct/${id}`);
    };
    getVipLinkList = ({ page_size = 10, page_number = 1 }) => {
        return this.get(`/api/v1/links?page_size=${page_size}&page_number=${page_number}`);
    };
    getVipLinkById = ({ id }) => {
        return this.get(`/api/v1/link/${id}`);
    };
    regenerateVipLink = ({ id }) => {
        return this.patch('/api/v1/link/code/regenerate', {
            link_id: id,
        });
    };
    updateVipLink = ({ id, name, numbers, message, status, type }) => {
        return this.patch('/api/v1/link', {
            id,
            name,
            numbers,
            message,
            status,
            type,
        });
    };
    createVipLink = ({ name, numbers, message, status, type }) => {
        return this.post('/api/v1/link', {
            name,
            numbers,
            message,
            status,
            type,
        });
    };
    deleteVipLinkById = ({ id }) => {
        return this.delete('/api/v1/link', { data: { id } });
    };
    /**
     *  Clock Related Endpoints
     */
    getClockList = ({
        page_size = 10,
        page_number = 1,
        team_id,
        member_id,
        start_date_time,
        end_date_time,
    }) => {
        let url = `/api/v1/user/clocks?page_size=${page_size}&page_number=${page_number}`;

        if (team_id) {
            if (team_id !== 'all') {
                url = `${url}&team_id=${team_id}`;
            }
        }
        if (start_date_time && end_date_time) {
            url = `${url}&start_date_time=${start_date_time}&end_date_time=${end_date_time}`;
        }
        if (member_id) {
            if (member_id !== 'all') {
                url = `${url}&member_id=${member_id}`;
            }
        }
        return this.get(url);
    };
    postClockIn = ({ lat, lng }) => {
        return this.post('/api/v1/user/clock/in', {
            lat,
            lng,
        });
    };
    patchClockOut = () => {
        return this.patch('/api/v1/user/clock/out');
    };
    /**
     *  Announcement Related Endpoints
     */
    getAnnouncementList = ({ page_size = 10, page_number = 1 }) => {
        return this.get(`/api/v1/announcements?page_size=${page_size}&page_number=${page_number}`);
    };
    getAnnouncementById = ({ id }) => {
        return this.get(`/api/v1/announcement/${id}`);
    };
    updateAnnouncement = ({ id, title, content }) => {
        return this.patch('/api/v1/announcement', {
            id,
            title,
            content,
        });
    };
    createAnnouncement = ({ title, content }) => {
        return this.post('/api/v1/announcement', {
            title,
            content,
        });
    };
    deleteAnnouncementById = ({ id }) => {
        return this.delete('/api/v1/announcement', { data: { id } });
    };
    /**
     *  Customer Report
     */
    getCustomerReportList = ({ page_size = 10, page_number = 1, team_id, member_id }) => {
        let url = `/api/v1/customer/reports?page_size=${page_size}&page_number=${page_number}`;

        if (team_id) {
            if (team_id !== 'all') {
                url = `${url}&team_id=${team_id}`;
            }
        }
        if (member_id) {
            if (member_id !== 'all') {
                url = `${url}&member_id=${member_id}`;
            }
        }
        return this.get(url);
    };
    getCustomerReportById = ({ id }) => {
        return this.get(`/api/v1/customer/report/${id}`);
    };
    updateCustomerReport = ({ id, code, step, status, reason }) => {
        return this.patch('/api/v1/customer/report', {
            id,
            code,
            step,
            status,
            reason,
        });
    };
    createCustomerReport = ({ code, step, status, reason }) => {
        return this.post('/api/v1/customer/report', {
            code,
            step,
            status,
            reason,
        });
    };
    deleteCustomerReportById = ({ id }) => {
        return this.delete('/api/v1/customer/report', { data: { id } });
    };

    getAdvertisementList = ({
        page_size = 10,
        page_number = 1,
        team_ids,
        user_ids,
        start_date,
        end_date,
        filter_status,
    }) => {
        let url = `/api/v1/advertisements?page_size=${page_size}&page_number=${page_number}`;
        if (filter_status) {
            if (filter_status !== 'all') {
                url = `${url}&search=[{"column":"status", "value": "${filter_status}", "operator": "="}]`;
            }
        }
        if (team_ids) {
            if (!team_ids.includes('All')) {
                url = `${url}&team_ids=${team_ids}`;
            }
        }
        if (start_date && end_date) {
            url = `${url}&start_date=${start_date}&end_date=${end_date}&search=[{"column":"status", "value": "accountant_approved"}]`;
        }
        if (user_ids) {
            if (!user_ids.includes('All')) {
                url = `${url}&user_ids=${user_ids}`;
            }
        }
        return this.get(url);
    };
    getAdvertisementById = ({ id }) => {
        return this.get(`/api/v1/advertisement/${id}`);
    };
    createAds = ({ ads_amount, ads_date, team_id, member_id, ads_type, expense_category }) => {
        return this.post('/api/v1/advertisement', {
            ads_amount,
            ads_date,
            team_id,
            member_id,
            ads_type,
            expense_category,
        });
    };
    updateAds = ({ id, ads_amount, ads_date, team_id, member_id, ads_type, expense_category }) => {
        return this.patch('/api/v1/advertisement', {
            id,
            ads_amount,
            ads_date,
            team_id,
            member_id,
            ads_type,
            expense_category,
        });
    };
    approveAdsById = ({ id }) => {
        return this.patch('/api/v1/advertisement/approve', {
            id,
        });
    };
    rejectAdsById = ({ id }) => {
        return this.patch('/api/v1/advertisement/reject', {
            id,
        });
    };

    getEnquiryList = ({
        page_size = 10,
        page_number = 1,
        team_ids,
        user_ids,
        start_date,
        end_date,
        filter_status,
    }) => {
        let url = `/api/v1/enquiries?page_size=${page_size}&page_number=${page_number}`;
        if (filter_status) {
            if (filter_status !== 'all') {
                url = `${url}&search=[{"column":"status", "value": "${filter_status}", "operator": "="}]`;
            }
        }
        if (team_ids) {
            if (!team_ids.includes('All')) {
                url = `${url}&team_ids=${team_ids}`;
            }
        }
        if (start_date && end_date) {
            url = `${url}&start_date=${start_date}&end_date=${end_date}&search=[{"column":"status", "value": "accountant_approved"}]`;
        }
        if (user_ids) {
            if (!user_ids.includes('All')) {
                url = `${url}&user_ids=${user_ids}`;
            }
        }
        return this.get(url);
    };
    getEnquiryById = ({ id }) => {
        return this.get(`/api/v1/enquiry/${id}`);
    };
    createEnquiry = ({ enquiry_amount, enquiry_date, team_id, member_id }) => {
        return this.post('/api/v1/enquiry', {
            enquiry_amount,
            enquiry_date,
            team_id,
            member_id,
        });
    };
    updateEnquiry = ({ id, enquiry_amount, enquiry_date, team_id, member_id }) => {
        return this.patch('/api/v1/enquiry', {
            id,
            enquiry_amount,
            enquiry_date,
            team_id,
            member_id,
        });
    };
    deleteEnquiryById = ({ id }) => {
        return this.delete('/api/v1/enquiry', { data: { id } });
    };
    deleteAdsById = ({ id }) => {
        return this.delete('/api/v1/advertisement', { data: { id } });
    };
    createAdsManager = ({ username, password, firstName, lastName, phoneNumber }) => {
        return this.post('/api/v1/user/register/ads_manager', {
            username,
            password,
            first_name: firstName,
            last_name: lastName,
            phone_number: phoneNumber,
        });
    };
}

const _ = new VipAPIService();
export default VipAPIService;
