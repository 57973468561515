import React, { Component } from 'react';
import { Form, Input, Button, notification, Row, Col } from 'antd';
import { Container, Card } from './index.style';

import { Utility } from '../../helpers';

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};
class SignIn extends Component {
    state = {
        isLoading: false,
    };
    componentDidMount() {
        if (Utility.isAdmin()) {
            return this.props.history.push('/agent');
        }
        if (Utility.isAgent()) {
            return this.props.history.push('/vip-link');
        }
        if (Utility.isAccountant()) {
            return this.props.history.push('/phone-records');
        }
    }
    componentDidUpdate = (prevProps) => {
        if (this.props.session.error && this.props.session.error !== prevProps.session.error) {
            this.openNotificationWithIcon({
                type: 'error',
                title: this.props.session.error.frontend_type,
                message: this.props.session.error.message,
            });
            this.setState({
                isLoading: this.props.session.isLoading,
            });
            return;
        }
        if (prevProps.session.permissions !== this.props.session.permissions) {
            if (this.props.session.permissions.length > 0) {
                if (Utility.isAdmin()) {
                    this.openNotificationWithIcon({
                        type: 'success',
                        title: `Welcome Admin ${this.props.session.firstName} !`,
                    });
                    return this.props.history.push('/report');
                }
                if (Utility.isAgent()) {
                    this.openNotificationWithIcon({
                        type: 'success',
                        title: `Welcome Agent ${this.props.session.firstName} !`,
                    });
                    return this.props.history.push('/report');
                }
                if (Utility.isMember()) {
                    this.openNotificationWithIcon({
                        type: 'success',
                        title: `Welcome Member ${this.props.session.firstName} !`,
                    });
                    return this.props.history.push('/sale');
                }
                if (Utility.isAccountant()) {
                    this.openNotificationWithIcon({
                        type: 'success',
                        title: `Welcome Accountant ${this.props.session.firstName} !`,
                    });
                    return this.props.history.push('/report');
                }
                if (Utility.isAdsManager()) {
                    this.openNotificationWithIcon({
                        type: 'success',
                        title: `Welcome Ads Manager ${this.props.session.firstName} !`,
                    });
                    return this.props.history.push('/report');
                }
                return this.props.history.push('/404');
            }
        }
    };
    onFinish = async (values) => {
        this.setState({
            isLoading: true,
        });
        await this.props.postSignIn({
            username: values.username,
            password: values.password,
        });
        await this.props.getProfile();
    };
    onFinishFailed = (errorInfo) => {
        this.openNotificationWithIcon({
            type: 'error',
            title: errorInfo.errorFields[0].name[0],
            message: errorInfo.errorFields[0].errors[0],
        });
    };
    openNotificationWithIcon = ({ type, title, message }) => {
        notification[type]({
            message: title,
            description: message,
        });
    };
    render() {
        return (
            <Container imgUrl={process.env.PUBLIC_URL + '/images/background.png'}>
                <img src={process.env.PUBLIC_URL + '/images/logo.png'} alt="logo" width={350} />
                <Row align={'middle'}>
                    <Col span={24}>
                        <Card>
                            <Form
                                {...layout}
                                name="basic"
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={this.onFinish}
                                onFinishFailed={this.onFinishFailed}
                            >
                                <Row>
                                    <Col span={24}>
                                        <Form.Item
                                            label="Username"
                                            name="username"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your username!',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <Form.Item
                                            label="Password"
                                            name="password"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your password!',
                                                },
                                            ]}
                                        >
                                            <Input.Password />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <Button
                                            block
                                            type="primary"
                                            htmlType="submit"
                                            loading={this.state.isLoading}
                                        >
                                            <b>Sign In</b>
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default SignIn;
