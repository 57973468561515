import React, { Component } from 'react';
import { Button, Input, Divider, message, notification, Select, Tooltip } from 'antd';
import {
    LeftOutlined,
    PlusOutlined,
    SaveOutlined,
    BankOutlined,
    DeleteOutlined,
    BorderlessTableOutlined,
    CalculatorOutlined,
    PercentageOutlined,
} from '@ant-design/icons';
import {
    Container,
    ButtonContainer,
    FormContainer,
    ButtonsContainer,
    SelectContainer,
} from './index.style';
import { Constant, Utility } from '../../../helpers';

const { Option } = Select;
class BankDashboard extends Component {
    state = {
        mode: null,
        bankAccountName: '',
        bankAccountNumber: '',
        bankAccountQuota: null,
        bank: null,
        bankList: [],
        isLoading: false,
        percentage: 0,
    };
    componentDidMount = async () => {
        const mode =
            Object.keys(this.props.match.params).length > 0
                ? Constant.PAGE_MODE.edit
                : Constant.PAGE_MODE.create;
        await this.setState({ mode });
        await this.props.getBankList();
        if (mode === Constant.PAGE_MODE.edit) {
            const id = this.props.match.params.id;
            await this.props.getBankAccountById({ id });
        }
    };
    componentDidUpdate = (prevProps) => {
        if (
            this.props.bankAccount.error &&
            this.props.bankAccount.error !== prevProps.bankAccount.error
        ) {
            this.openNotificationWithIcon({
                type: 'error',
                title: this.props.bankAccount.error.frontend_type,
                message: this.props.bankAccount.error.message,
            });
            this.setState({
                isLoading: this.props.bankAccount.isLoading,
            });
        }
        if (prevProps.bankAccount.current !== this.props.bankAccount.current) {
            const bankAccount = this.props.bankAccount.current;
            this.setState({
                bankAccountName: bankAccount.name,
                bankAccountNumber: bankAccount.accountNumber,
                bankAccountQuota: bankAccount.amountLimit,
                bank: bankAccount.bankId,
                percentage: ((bankAccount.percentage || 0) * 100).toFixed(2),
            });
        }
        if (prevProps.bankAccount.bankList !== this.props.bankAccount.bankList) {
            this.setState({
                bankList: this.props.bankAccount.bankList,
                isLoading: false,
            });
        }
    };
    openNotificationWithIcon = ({ type, title, message }) => {
        notification[type]({
            message: title,
            description: message,
        });
    };
    onBackButtonPressed = () => {
        this.props.history.replace('/bank');
    };
    onbankAccountNameChanged = ({ text }) => {
        this.setState({ bankAccountName: text });
    };
    onbankAccountNumberChanged = ({ text }) => {
        this.setState({ bankAccountNumber: text });
    };
    onbankAccountQuotaChanged = ({ text }) => {
        if (Utility.numberRegexCheck(text) || Utility.decimalNumberRegexCheck(text)) {
            this.setState({ bankAccountQuota: text });
            return;
        }
        this.setState({ bankAccountQuota: null });
        return;
    };
    onPercentageChanged = ({ text }) => {
        if (Utility.numberRegexCheck(text) || Utility.decimalNumberRegexCheck(text)) {
            this.setState({ percentage: text });
            return;
        }
        this.setState({ percentage: null });
        return;
    };
    onFormValidation = () => {
        if (!this.state.bank) {
            message.error('Bank is required');
            return false;
        }
        if (!this.state.bankAccountQuota) {
            message.error('Quota cannot be empty or zero');
            return false;
        }
        if (this.state.bankAccountNumber.trim().length === 0) {
            message.error('Account Number cannot be empty');
            return false;
        }
        if (this.state.bankAccountName.trim().length === 0) {
            message.error('Account Name cannot be empty');
            return false;
        }
        return true;
    };
    onSaveButtonPressed = async () => {
        if (this.onFormValidation()) {
            this.setState({ isLoading: true });
            await this.props.updateBankAccount({
                id: this.props.bankAccount.current.id,
                bank_id: this.state.bank,
                name: this.state.bankAccountName,
                account_number: this.state.bankAccountNumber,
                amount_limit: this.state.bankAccountQuota,
                percentage: (parseFloat(this.state.percentage) / 100).toFixed(2),
            });
            await this.props.getBankAccountList();
            message.success('Bank Account updated!');
            this.onBackButtonPressed();
        }
    };
    onCreateButtonPressed = async () => {
        if (this.onFormValidation()) {
            this.setState({ isLoading: true });
            await this.props.createBankAccount({
                bank_id: this.state.bank,
                name: this.state.bankAccountName,
                account_number: this.state.bankAccountNumber,
                amount_limit: this.state.bankAccountQuota,
                percentage: (parseFloat(this.state.percentage) / 100).toFixed(2),
            });
            await this.props.getBankAccountList();
            message.success('Bank Account created!');
            this.onBackButtonPressed();
        }
    };
    onDeleteButtonPressed = async () => {
        this.setState({ isLoading: true });
        await this.props.deleteBankAccountById({
            id: this.props.bankAccount.current.id,
        });
        await this.props.getBankAccountList();
        message.success('Bank Account deleted!');
        this.onBackButtonPressed();
    };
    onBankChanged = (selectedBank) => {
        const bank = this.state.bankList.find((bank) => bank.id === selectedBank);
        this.setState({
            bank: bank.id,
        });
    };
    render() {
        return (
            <Container>
                <ButtonContainer>
                    <Button type="link" size={'large'} onClick={this.onBackButtonPressed}>
                        <LeftOutlined style={{ paddingRight: '10px' }} />
                        <b>Back</b>
                    </Button>
                </ButtonContainer>
                <FormContainer>
                    <Divider orientation="left">Bank Account Details</Divider>
                    <br />
                    <SelectContainer>
                        <Select
                            showSearch
                            value={this.state.bank || 'Select a Bank'}
                            size={'large'}
                            style={{ width: 200 }}
                            optionFilterProp="children"
                            onChange={this.onBankChanged}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {this.state.bankList.map((bank) => {
                                return (
                                    <Option key={bank.id} value={bank.id}>
                                        {bank.name}
                                    </Option>
                                );
                            })}
                        </Select>
                    </SelectContainer>
                    <br />
                    <Tooltip placement="left" title="Account name">
                        <Input
                            maxLength={30}
                            value={this.state.bankAccountName}
                            onChange={(event) =>
                                this.onbankAccountNameChanged({ text: event.target.value })
                            }
                            size="large"
                            placeholder="Account name"
                            prefix={<BankOutlined />}
                        />
                    </Tooltip>

                    <br />
                    <Tooltip placement="left" title="Account Number">
                        <Input
                            maxLength={30}
                            value={this.state.bankAccountNumber}
                            onChange={(event) =>
                                this.onbankAccountNumberChanged({ text: event.target.value })
                            }
                            size="large"
                            placeholder="Account Number"
                            prefix={<BorderlessTableOutlined />}
                        />
                    </Tooltip>

                    <br />
                    <Tooltip placement="left" title="Account Quota">
                        <Input
                            maxLength={30}
                            value={this.state.bankAccountQuota}
                            onChange={(event) =>
                                this.onbankAccountQuotaChanged({ text: event.target.value })
                            }
                            size="large"
                            placeholder="Account Quota"
                            prefix={<CalculatorOutlined />}
                        />
                    </Tooltip>

                    <br />
                    <Tooltip placement="left" title="Percentage">
                        <Input
                            maxLength={30}
                            value={this.state.percentage}
                            onChange={(event) =>
                                this.onPercentageChanged({ text: event.target.value })
                            }
                            size="large"
                            placeholder="Percentage"
                            suffix={<PercentageOutlined />}
                        />
                    </Tooltip>

                    <br />
                    <Divider />
                    {this.state.mode === Constant.PAGE_MODE.create ? (
                        <Button
                            type="primary"
                            size={'large'}
                            onClick={this.onCreateButtonPressed}
                            loading={this.state.isLoading}
                        >
                            <PlusOutlined style={{ paddingRight: '10px' }} />
                            <b>CREATE</b>
                        </Button>
                    ) : (
                        <ButtonsContainer>
                            <Button
                                type="primary"
                                size={'large'}
                                onClick={this.onSaveButtonPressed}
                                loading={this.state.isLoading}
                                style={{ marginRight: 10 }}
                            >
                                <SaveOutlined style={{ paddingRight: '10px' }} />
                                <b>SAVE</b>
                            </Button>
                            <Button
                                type="danger"
                                size={'large'}
                                onClick={this.onDeleteButtonPressed}
                                loading={this.state.isLoading}
                            >
                                <DeleteOutlined style={{ paddingRight: '10px' }} />
                                <b>DELETE</b>
                            </Button>
                        </ButtonsContainer>
                    )}
                </FormContainer>
            </Container>
        );
    }
}

export default BankDashboard;
