import { connect } from 'react-redux';
import AdsDashboard from './AdsDashboard';
import {
    createAds,
    getAdvertisementList,
    getAdvertisementById,
    updateAds,
    deleteAdsById,
} from '../../../ducks/ads';
import { getUserList } from '../../../ducks/user';
import { getTeamList } from '../../../ducks/team';

const mapStateToProps = (state) => {
    return {
        session: state.session,
        ads: state.ads,
        team: state.team,
        user: state.user,
    };
};

const AdsDashboardContainer = connect(mapStateToProps, {
    createAds,
    getAdvertisementList,
    getAdvertisementById,
    updateAds,
    deleteAdsById,
    getTeamList,
    getUserList,
})(AdsDashboard);
export default AdsDashboardContainer;
