const Constant = {
    TRANSFER_TYPE: {
        instant: 'instant',
        ibg: 'ibg',
    },
    REPORT_TYPE: {
        today: 'today',
        yesterday: 'yesterday',
        weekly: 'weekly',
        lastWeek: 'lastWeek',
        monthly: 'monthly',
        lastMonth: 'lastMonth',
        ytd: 'ytd',
        range: 'range',
    },
    SALES_STATUS: {
        approved: 'approved',
        rejected: 'rejected',
        pending: 'pending',
        accountant_approved: 'accountant_approved',
        accountant_rejected: 'accountant_rejected',
    },
    USER_PERMISSIONS: {
        manage_user_permission: 'MANAGE_USER_PERMISSION',
        manage_admin: 'MANAGE_ADMIN',
        manage_agent: 'MANAGE_AGENT',
        manage_accountant: 'MANAGE_ACCOUNTANT',
        manage_member: 'MANAGE_MEMBER',
        view_members: 'VIEW_MEMBERS',
        manage_team_member: 'MANAGE_TEAM_MEMBER',
        manage_team: 'MANAGE_TEAM',
        view_teams: 'VIEW_TEAMS',
        manage_sales: 'MANAGE_SALES',
        manage_link: 'MANAGE_LINK',
        manage_report: 'MANAGE_REPORT',
        view_link_numbers: 'VIEW_LINK_NUMBERS',
        create_sales: 'CREATE_SALES',
        verify_sale: 'VERIFY_SALE',
        manage_bank_account: 'MANAGE_BANK_ACCOUNT',
        manage_announcement: 'MANAGE_ANNOUNCEMENT',
        manage_user_clock: 'MANAGE_USER_CLOCK',
        manage_enquiry: 'MANAGE_ENQUIRY',
        create_enquiry: 'CREATE_ENQUIRY',
    },
    USER_TYPE: {
        admin: 'admin',
        agent: 'agent',
        accountant: 'accountant',
        member: 'member',
        ads_manager: 'ads_manager',
    },
    USER_STATUS: {
        active: 'active',
        suspended: 'suspended',
    },
    LINK_STATUS: {
        active: 'active',
        suspended: 'suspended',
    },
    LINK_TYPE: {
        whatsapp: 'whatsapp',
        link: 'link',
    },
    PAGE_MODE: {
        edit: 'edit',
        create: 'create',
    },
    CUSTOMER_REPORT_STEP: {
        newDeposit: 'new_deposit',
        step1: 'step_1',
        step2: 'step_2',
        step3: 'step_3',
        step4: 'step_4',
        step5: 'step_5',
        step6: 'step_6',
    },
    CUSTOMER_REPORT_STATUS: {
        active: 'active',
        pending: 'pending',
        reject: 'reject',
    },
    EXPENSE_CATEGORY: {
        sim_topup: 'SIM Topup',
        phone_laptop: 'Phone and Laptop',
        um_shares: 'UM Shares',
        hostel: 'Hostel',
        flight_ticket: 'Flight Ticket',
        facebook: 'Facebook',
        cash_out: 'Cash Out',
        proxy: '代操作',
    },
    ADS_TYPE: {
        ads: 'Ads',
        expense: 'Expense',
    },
};

export { Constant };
