import SessionService from '../services/sessionService';
import { Utility } from '../helpers';
// Actions
const POST_SIGN_IN_REQUEST = 'vip/session/POST_SIGN_IN_REQUEST';
const POST_SIGN_IN_SUCCESS = 'vip/session/POST_SIGN_IN_SUCCESS';
const POST_SIGN_IN_FAIL = 'vip/session/POST_SIGN_IN_FAIL';
const GET_PROFILE_REQUEST = 'vip/session/GET_PROFILE_REQUEST';
const GET_PROFILE_SUCCESS = 'vip/session/GET_PROFILE_SUCCESS';
const GET_PROFILE_FAIL = 'vip/session/GET_PROFILE_FAIL';
const SESSION_TOKEN_EXPIRED = 'vip/session/SESSION_TOKEN_EXPIRED';

// Reducer
const INITIAL_STATE = {
    userToken: null,
    isLoggedIn: false,
    username: null,
    userType: null,
    id: null,
    status: null,
    firstName: null,
    lastName: null,
    phoneNumber: null,
    error: null,
    isLoading: false,
    permissions: [],
    teamList: [],
};

export default function reducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SESSION_TOKEN_EXPIRED: {
            return INITIAL_STATE;
        }
        case POST_SIGN_IN_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case POST_SIGN_IN_SUCCESS: {
            return {
                ...state,
                userToken: action.payload.userToken,
                isLoggedIn: true,
                username: action.payload.username,
                id: action.payload.id,
                status: action.payload.status,
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
                userType: action.payload.userType,
                phoneNumber: action.payload.phoneNumber,
                isLoading: false,
                error: null,
            };
        }
        case POST_SIGN_IN_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case GET_PROFILE_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case GET_PROFILE_SUCCESS: {
            return {
                ...state,
                permissions: action.payload.permissions,
                teamList: action.payload.team_members,
                isLoading: false,
                error: null,
            };
        }
        case GET_PROFILE_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        default:
            return state;
    }
}

// Action Creators
export function postSignInRequest() {
    return {
        type: POST_SIGN_IN_REQUEST,
    };
}
export function postSignInSuccess(payload) {
    return {
        type: POST_SIGN_IN_SUCCESS,
        payload,
    };
}
export function postSignInFail(error) {
    return {
        type: POST_SIGN_IN_FAIL,
        error,
    };
}
export function getProfileRequest() {
    return {
        type: GET_PROFILE_REQUEST,
    };
}
export function getProfileSuccess(payload) {
    return {
        type: GET_PROFILE_SUCCESS,
        payload,
    };
}
export function getProfileFail(error) {
    return {
        type: GET_PROFILE_FAIL,
        error,
    };
}
export const sessionExpiredAction = () => {
    return (dispatch) => {
        Utility.logoutSession();
        dispatch({ type: SESSION_TOKEN_EXPIRED });
    };
};

// side effects, only as applicable
// e.g. thunks, epics, etc
export const postSignIn = ({ username, password } = {}) => async (dispatch) => {
    dispatch(postSignInRequest());
    try {
        const result = await SessionService.postSignIn({ username, password });
        const userData = result.user;
        const userObject = {
            userToken: result.token,
            username: userData.username,
            id: userData.id,
            status: userData.status,
            firstName: userData.firstName,
            lastName: userData.lastName,
            userType: userData.userType,
            phoneNumber: userData.phoneNumber,
            isLoggedIn: true,
        };
        Utility.setItem({
            key: 'session',
            storeObject: userObject,
        });
        dispatch(postSignInSuccess(userObject));
    } catch (error) {
        Utility.setItem({
            key: 'session',
            storeObject: null,
        });
        dispatch(postSignInFail(error));
        throw error;
    }
};
export const getProfile = () => async (dispatch) => {
    dispatch(getProfileRequest());
    try {
        const result = await SessionService.getProfile();
        dispatch(getProfileSuccess(result));
    } catch (error) {
        Utility.setItem({
            key: 'session',
            storeObject: null,
        });
        dispatch(getProfileFail(error));
        throw error;
    }
};
