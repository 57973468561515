import React, { Component } from 'react';
import { Table, notification, Tooltip, Col, Row, Select, DatePicker } from 'antd';
import moment from 'moment';

import { Constant, Utility } from '../../helpers';
import { Container, FilterContainer } from './index.style';

const { Option } = Select;
const { RangePicker } = DatePicker;

class ClockIn extends Component {
    state = {
        loading: false,
        teamId: 'all',
        memberId: 'all',
        teamList: [],
        reportType: Constant.REPORT_TYPE.ytd,
        dateRange: [moment().startOf('year'), moment()],
        data: [],
        pagination: {
            current: 1,
            total: 0,
            showSizeChanger: false,
        },
    };
    constructor(props) {
        super(props);
        this.div = React.createRef();
        this.columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: 'Date',
                dataIndex: 'inAt',
                key: 'inAt',
                render: (text) => {
                    return <b>{moment(text, 'DD/MM/YYYY').format('Do MMMM YYYY')}</b>;
                },
            },
            {
                title: 'Clock In',
                dataIndex: 'inAt',
                key: 'inAt',
                render: (text, all) => {
                    let isLate = false;
                    const clockInTime = moment(text, 'DD/MM/YYYY hh:mm:ss A');
                    if (clockInTime.hours() >= 12) {
                        isLate = true;
                        if (clockInTime.hours() === 12) {
                            isLate = false;
                            if (clockInTime.minutes() > 30) {
                                isLate = true;
                            }
                        }
                    }
                    if (all.offDay) {
                        return <b style={{ color: isLate ? '#f50' : '#87d068' }}>OFF DAY</b>;
                    }
                    return (
                        <b style={{ color: isLate ? '#f50' : '#87d068' }}>
                            {moment(text, 'DD/MM/YYYY hh:mm:ss A').format('h:mm:ss A')}
                        </b>
                    );
                },
            },
            {
                title: 'Clock Out',
                dataIndex: 'outAt',
                key: 'outAt',
                render: (text, all) => {
                    let validDate = true;
                    if (!text || text === 'Invalid date') {
                        validDate = false;
                    }
                    if (all.offDay) {
                        return <b>-</b>;
                    }
                    return (
                        <b>
                            {validDate
                                ? moment(text, 'DD/MM/YYYY hh:mm:ss A').format('h:mm:ss A')
                                : '-'}
                        </b>
                    );
                },
            },
            {
                title: 'Duration',
                render: (text, all) => {
                    let duration;
                    if (!text.outAt || text.outAt !== 'Invalid date') {
                        duration = moment.duration(
                            moment(text.outAt, 'DD/MM/YYYY hh:mm:ss A').diff(
                                moment(text.inAt, 'DD/MM/YYYY hh:mm:ss A')
                            )
                        );
                    }
                    if (all.offDay) {
                        return <b>-</b>;
                    }
                    return (
                        <b>
                            {duration ? (
                                <>
                                    {duration.hours()}h {duration.minutes()}m {duration.seconds()}s{' '}
                                </>
                            ) : (
                                '-'
                            )}
                        </b>
                    );
                },
            },
            {
                title: 'Team',
                dataIndex: 'team',
                key: 'team',
                render: (text) => {
                    return <b>{text ? text.name : '-'}</b>;
                },
            },
            {
                title: 'Username',
                dataIndex: 'user',
                key: 'user',
                render: (text) => {
                    return <b>{text.username}</b>;
                },
            },
        ];
    }
    componentDidMount = async () => {
        this.div.current.scrollIntoView({ behavior: 'smooth' });
        if (Utility.isMember()) {
            this.setState({ memberId: this.props.session.id });
        }
        this.setState({ loading: true });
        const payload = {
            team_id: this.state.teamId,
            member_id: this.state.memberId,
            start_date_time: moment(this.state.dateRange[0]).format('YYYY-MM-DD hh:mm A'),
            end_date_time: moment(this.state.dateRange[1]).format('YYYY-MM-DD hh:mm A'),
        };
        await this.props.getClockList(payload);
        await this.props.getUserList({
            user_type: [Constant.USER_TYPE.member, Constant.USER_TYPE.agent],
            page_size: 1000,
        });
        if (this.props.session.permissions.includes(Constant.USER_PERMISSIONS.manage_team)) {
            await this.props.getTeamList({ page_size: 50 });
        } else {
            let teamList = [];
            this.props.session.teamList.map((teamObj) => {
                return teamList.push(teamObj.team);
            });
            this.setState({ teamList });
        }
    };
    componentDidUpdate = (prevProps) => {
        if (this.props.clock.error && this.props.clock.error !== prevProps.clock.error) {
            this.openNotificationWithIcon({
                type: 'error',
                title: this.props.clock.error.frontend_type,
                message: this.props.clock.error.message,
            });
            this.setState({
                isLoading: this.props.clock.isLoading,
            });
        }
        if (this.props.team.error && this.props.team.error !== prevProps.team.error) {
            this.openNotificationWithIcon({
                type: 'error',
                title: this.props.team.error.frontend_type,
                message: this.props.team.error.message,
            });
            this.setState({
                isLoading: this.props.team.isLoading,
            });
        }
        if (this.props.user.error && this.props.user.error !== prevProps.user.error) {
            this.openNotificationWithIcon({
                type: 'error',
                title: this.props.user.error.frontend_type,
                message: this.props.user.error.message,
            });
            this.setState({
                isLoading: this.props.user.isLoading,
            });
        }
        if (prevProps.clock.list !== this.props.clock.list) {
            this.setState({
                data: this.props.clock.list,
                loading: false,
                pagination: {
                    ...this.state.pagination,
                    current: this.props.clock.pagination.page,
                    total: this.props.clock.pagination.row_count,
                },
            });
        }
        if (prevProps.team.list !== this.props.team.list) {
            this.setState({
                teamList: this.props.team.list,
                loading: false,
            });
        }
    };
    openNotificationWithIcon = ({ type, title, message }) => {
        notification[type]({
            message: title,
            description: message,
        });
    };
    onChangeTable = async ({ nextPage }) => {
        this.setState({ loading: true });
        const payload = {
            team_id: this.state.teamId,
            member_id: this.state.memberId,
            page_number: nextPage,
            start_date_time: moment(this.state.dateRange[0]).format('YYYY-MM-DD hh:mm A'),
            end_date_time: moment(this.state.dateRange[1]).format('YYYY-MM-DD hh:mm A'),
        };
        await this.props.getClockList(payload);
    };
    onTeamChanged = async (team) => {
        await this.setState({ teamId: team, memberId: 'all' });
        await this.props.getUserList({
            team_ids: [team],
            user_type: [Constant.USER_TYPE.member, Constant.USER_TYPE.agent],
            page_size: 1000,
        });
        this.generateReport();
    };
    onMemberChanged = async (member) => {
        await this.setState({ memberId: member });
        this.generateReport();
    };
    onReportTypeChanged = async (report) => {
        this.setState({ reportType: report });
        switch (report) {
            case Constant.REPORT_TYPE.today: {
                await this.setState({
                    dateRange: [moment().startOf('day'), moment().endOf('day')],
                });
                this.generateReport();
                break;
            }
            case Constant.REPORT_TYPE.yesterday: {
                await this.setState({
                    dateRange: [
                        moment().subtract(1, 'days').startOf('day'),
                        moment().subtract(1, 'days').endOf('day'),
                    ],
                });
                this.generateReport();
                break;
            }
            case Constant.REPORT_TYPE.weekly: {
                await this.setState({
                    dateRange: [
                        moment().startOf('week').add(1, 'days'),
                        moment().endOf('week').add(1, 'days'),
                    ],
                });
                this.generateReport();
                break;
            }
            case Constant.REPORT_TYPE.lastWeek: {
                await this.setState({
                    dateRange: [
                        moment().subtract(1, 'week').startOf('week').add(1, 'days'),
                        moment().subtract(1, 'week').endOf('week').add(1, 'days'),
                    ],
                });
                this.generateReport();
                break;
            }
            case Constant.REPORT_TYPE.monthly: {
                await this.setState({
                    dateRange: [moment().startOf('month'), moment().endOf('month')],
                });
                this.generateReport();
                break;
            }
            case Constant.REPORT_TYPE.lastMonth: {
                await this.setState({
                    dateRange: [
                        moment().subtract(1, 'months').startOf('month'),
                        moment().subtract(1, 'months').endOf('month'),
                    ],
                });
                this.generateReport();
                break;
            }
            case Constant.REPORT_TYPE.ytd: {
                await this.setState({
                    dateRange: [moment().startOf('year'), moment()],
                });
                this.generateReport();
                break;
            }
            case Constant.REPORT_TYPE.range: {
                await this.setState({
                    dateRange: [],
                });
                break;
            }
            default:
                break;
        }
    };
    generateReport = async () => {
        this.setState({ loading: true });
        const payload = {
            team_id: this.state.teamId,
            member_id: this.state.memberId,
            start_date_time: moment(this.state.dateRange[0]).format('YYYY-MM-DD hh:mm A'),
            end_date_time: moment(this.state.dateRange[1]).format('YYYY-MM-DD hh:mm A'),
        };
        await this.props.getClockList(payload);
    };
    onDateChanged = async (date) => {
        await this.setState({
            dateRange: [moment(date[0]).startOf('day'), moment(date[1]).endOf('day')],
        });
        this.generateReport();
    };
    render() {
        const authorisedFilter = Utility.isAdmin() || Utility.isAgent() || Utility.isAccountant();
        return (
            <Container ref={this.div}>
                <FilterContainer>
                    <Row align={'middle'} gutter={[12, 12]}>
                        {authorisedFilter && (
                            <Col xs={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }}>
                                <Tooltip placement="left" title="Select a Team">
                                    <Select
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >= 0
                                        }
                                        value={this.state.teamId}
                                        size={'large'}
                                        style={{ width: 200 }}
                                        optionFilterProp="children"
                                        placeholder={'Select a Team'}
                                        onChange={this.onTeamChanged}
                                    >
                                        {this.state.teamList.map((team) => {
                                            return (
                                                <Option key={team.id} value={team.id}>
                                                    {team.name}
                                                </Option>
                                            );
                                        })}
                                        <Option key={'all'} value={'all'}>
                                            All
                                        </Option>
                                    </Select>
                                </Tooltip>
                            </Col>
                        )}
                        {authorisedFilter && (
                            <Col xs={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }}>
                                <Tooltip placement="left" title="Select a Member">
                                    <Select
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >= 0
                                        }
                                        value={this.state.memberId}
                                        size={'large'}
                                        style={{ width: 200 }}
                                        optionFilterProp="children"
                                        placeholder={'Select a Member'}
                                        onChange={this.onMemberChanged}
                                    >
                                        {this.props.user.list.map((member) => {
                                            return (
                                                <Option key={member.id} value={member.id}>
                                                    {member.username}
                                                </Option>
                                            );
                                        })}
                                        <Option key={'all'} value={'all'}>
                                            All
                                        </Option>
                                    </Select>
                                </Tooltip>
                            </Col>
                        )}
                        <Col xs={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }}>
                            <Tooltip placement="left" title="Report Type">
                                <Select
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                    value={this.state.reportType}
                                    size={'large'}
                                    style={{ width: 200 }}
                                    optionFilterProp="children"
                                    placeholder={'Report Type'}
                                    onChange={this.onReportTypeChanged}
                                >
                                    <Option
                                        key={Constant.REPORT_TYPE.today}
                                        value={Constant.REPORT_TYPE.today}
                                    >
                                        Today
                                    </Option>
                                    <Option
                                        key={Constant.REPORT_TYPE.yesterday}
                                        value={Constant.REPORT_TYPE.yesterday}
                                    >
                                        Yesterday
                                    </Option>
                                    <Option
                                        key={Constant.REPORT_TYPE.weekly}
                                        value={Constant.REPORT_TYPE.weekly}
                                    >
                                        This Week
                                    </Option>
                                    <Option
                                        key={Constant.REPORT_TYPE.lastWeek}
                                        value={Constant.REPORT_TYPE.lastWeek}
                                    >
                                        Last Week
                                    </Option>
                                    <Option
                                        key={Constant.REPORT_TYPE.monthly}
                                        value={Constant.REPORT_TYPE.monthly}
                                    >
                                        This Month
                                    </Option>
                                    <Option
                                        key={Constant.REPORT_TYPE.lastMonth}
                                        value={Constant.REPORT_TYPE.lastMonth}
                                    >
                                        Last Month
                                    </Option>
                                    <Option
                                        key={Constant.REPORT_TYPE.ytd}
                                        value={Constant.REPORT_TYPE.ytd}
                                    >
                                        YTD
                                    </Option>
                                    <Option
                                        key={Constant.REPORT_TYPE.range}
                                        value={Constant.REPORT_TYPE.range}
                                    >
                                        Range
                                    </Option>
                                </Select>
                            </Tooltip>
                            <br />
                            <br />
                            <RangePicker
                                value={this.state.dateRange}
                                disabledDate={this.disabledDate}
                                onChange={this.onDateChanged}
                                size={'large'}
                                format={'DD/MM/YYYY'}
                                disabled={this.state.reportType !== Constant.REPORT_TYPE.range}
                            />
                        </Col>
                    </Row>
                </FilterContainer>
                <Table
                    loading={this.state.loading}
                    rowKey={(record) => record.id}
                    columns={this.columns}
                    dataSource={this.state.data}
                    scroll={{ x: 500 }}
                    pagination={this.state.pagination}
                    onChange={(event) => this.onChangeTable({ nextPage: event.current })}
                />
            </Container>
        );
    }
}

export default ClockIn;
