import styled from 'styled-components';
export const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 20%;
    margin-right: 20%;
    margin-top: 5%;
`;
export const CardContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: 5%;
`;
export const InnerCardInfoContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
`;
export const DateLabel = styled.p`
    font-weight: bold;
    font-size: 1em;
`;
export const Titlelabel = styled.p`
    font-weight: bold;
    font-size: 0.8em;
`;
