import React, { Component } from 'react';
import { Button, Input, Divider, Tooltip, message, Select, notification } from 'antd';
import {
    LeftOutlined,
    LinkOutlined,
    PhoneOutlined,
    PlusOutlined,
    DeleteOutlined,
    MessageOutlined,
    SaveOutlined,
} from '@ant-design/icons';
import {
    Container,
    ButtonContainer,
    FormContainer,
    AddPhoneButtonContainer,
    StatusContainer,
    ButtonsContainer,
} from './index.style';
import { Constant, Utility } from '../../../helpers';

const { Option } = Select;

class VipLinkDashboard extends Component {
    state = {
        mode: null,
        linkName: '',
        linkMessage: null,
        linkStatus: Constant.LINK_STATUS.active,
        linkItems: ['01'],
        linkType: Constant.LINK_TYPE.whatsapp,
        isLoading: false,
    };
    componentDidMount = async () => {
        const mode =
            Object.keys(this.props.match.params).length > 0
                ? Constant.PAGE_MODE.edit
                : Constant.PAGE_MODE.create;
        await this.setState({ mode });
        if (mode === Constant.PAGE_MODE.edit) {
            const id = this.props.match.params.id;
            await this.props.getVipLinkById({ id });
        }
    };
    componentDidUpdate = (prevProps) => {
        if (this.props.vipLink.error && this.props.vipLink.error !== prevProps.vipLink.error) {
            this.openNotificationWithIcon({
                type: 'error',
                title: this.props.vipLink.error.frontend_type,
                message: this.props.vipLink.error.message,
            });
            this.setState({
                isLoading: this.props.vipLink.isLoading,
            });
        }
        if (prevProps.vipLink.current !== this.props.vipLink.current) {
            const link = this.props.vipLink.current;
            this.setState({
                linkName: link.name,
                linkMessage: link.message,
                linkStatus: link.status,
                linkItems: link.numbers,
                linkType: link.type,
                isLoading: false,
            });
        }
    };
    openNotificationWithIcon = ({ type, title, message }) => {
        notification[type]({
            message: title,
            description: message,
        });
    };
    onBackButtonPressed = () => {
        this.props.history.replace('/vip-link');
    };
    onDeleteLinkItemPressed = ({ selectedItem, selectedIndex }) => {
        if (this.state.linkItems.length > 1) {
            this.setState({
                linkItems: this.state.linkItems.filter(
                    (number, index) => number !== selectedItem && index !== selectedIndex
                ),
            });
            return;
        }
        if (this.state.linkType === Constant.LINK_TYPE.whatsapp) {
            message.error('You must have at least 1 phone number!');
            return;
        }
        message.error('You must have at least 1 URL!');
    };
    phoneNumberCheck = ({ phoneNumber }) => {
        if (!Utility.malaysiaPhoneNumberRegexCheck(phoneNumber)) {
            message.error('Please insert a valid phone number, must contain 01 (e.g 0163948576)!');
            return false;
        }
        return true;
    };
    urlCheck = ({ url }) => {
        if (!Utility.urlRegexCheck(url)) {
            message.error('Please insert a valid URL');
            return false;
        }
        return true;
    };
    onLinkNameChanged = ({ text }) => {
        this.setState({ linkName: text });
    };
    onMessageChanged = ({ text }) => {
        this.setState({ linkMessage: text });
    };
    onNewLinkItemPressed = () => {
        const lastInsertItem = this.state.linkItems[this.state.linkItems.length - 1];
        if (this.state.linkType === Constant.LINK_TYPE.whatsapp) {
            if (!this.phoneNumberCheck({ phoneNumber: lastInsertItem })) {
                return;
            }
            this.setState({ linkItems: [...this.state.linkItems, '01'] });
            return;
        }
        if (!this.urlCheck({ url: lastInsertItem })) {
            return;
        }
        this.setState({ linkItems: [...this.state.linkItems, ''] });
    };
    onStatusChanged = (status) => {
        this.setState({ linkStatus: status });
    };
    onTypeChanged = (type) => {
        if (type === Constant.LINK_TYPE.whatsapp) {
            this.setState({
                linkType: type,
                linkItems: ['01'],
            });
            return;
        }
        this.setState({
            linkType: type,
            linkItems: [''],
        });
    };
    onInputChange = ({ text, editedIndex }) => {
        const tempArray = [...this.state.linkItems];
        tempArray[editedIndex] = text;
        this.setState({
            linkItems: tempArray,
        });
    };
    onDeleteButtonPressed = async () => {
        this.setState({ isLoading: true });
        await this.props.deleteVipLinkById({
            id: this.props.vipLink.current.id,
        });
        await this.props.getVipLinkList();
        message.success('VIP link deleted!');
        this.onBackButtonPressed();
    };
    onFormValidation = () => {
        let canSubmit = true;
        if (this.state.linkName.trim().length === 0) {
            message.error('Link name cannot be empty');
            return false;
        }
        if (!this.state.linkStatus) {
            message.error('Please select a status');
            return false;
        }
        this.state.linkItems.map((item) => {
            if (this.state.linkType === Constant.LINK_TYPE.whatsapp) {
                if (!this.phoneNumberCheck({ phoneNumber: item })) {
                    canSubmit = false;
                }
            } else {
                if (!this.urlCheck({ url: item })) {
                    canSubmit = false;
                }
            }
            return canSubmit;
        });
        return canSubmit;
    };
    onSaveButtonPressed = async () => {
        if (this.onFormValidation()) {
            this.setState({ isLoading: true });
            await this.props.updateVipLink({
                id: this.props.vipLink.current.id,
                name: this.state.linkName,
                numbers: this.state.linkItems,
                message: this.state.linkMessage,
                status: this.state.linkStatus,
                type: this.state.linkType,
            });
            await this.props.getVipLinkList();
            message.success('VIP link updated!');
            this.onBackButtonPressed();
        }
    };
    onCreateButtonPressed = async () => {
        if (this.onFormValidation()) {
            this.setState({ isLoading: true });
            await this.props.createVipLink({
                name: this.state.linkName,
                numbers: this.state.linkItems,
                message: this.state.linkMessage,
                status: this.state.linkStatus,
                type: this.state.linkType,
            });
            await this.props.getVipLinkList();
            message.success('VIP link created!');
            this.onBackButtonPressed();
        }
    };
    render() {
        return (
            <Container>
                <ButtonContainer>
                    <Button type="link" size={'large'} onClick={this.onBackButtonPressed}>
                        <LeftOutlined style={{ paddingRight: '10px' }} />
                        <b>Back</b>
                    </Button>
                </ButtonContainer>
                <FormContainer>
                    <Divider orientation="left">Link Details</Divider>
                    <br />
                    <StatusContainer>
                        <Select
                            size={'large'}
                            placeholder={'Select a Type'}
                            onChange={this.onTypeChanged}
                            style={{ width: 200 }}
                            value={this.state.linkType}
                        >
                            <Option
                                key={Constant.LINK_TYPE.whatsapp}
                                value={Constant.LINK_TYPE.whatsapp}
                            >
                                Whatsapp
                            </Option>
                            <Option key={Constant.LINK_TYPE.link} value={Constant.LINK_TYPE.link}>
                                Link
                            </Option>
                        </Select>
                        <Select
                            size={'large'}
                            placeholder={'Select a status'}
                            onChange={this.onStatusChanged}
                            style={{ width: 200 }}
                            value={this.state.linkStatus}
                        >
                            <Option
                                key={Constant.LINK_STATUS.active}
                                value={Constant.LINK_STATUS.active}
                            >
                                Active
                            </Option>
                            <Option
                                key={Constant.LINK_STATUS.suspended}
                                value={Constant.LINK_STATUS.suspended}
                            >
                                Suspended
                            </Option>
                        </Select>
                    </StatusContainer>
                    <br />
                    <Input
                        maxLength={30}
                        value={this.state.linkName}
                        onChange={(event) => this.onLinkNameChanged({ text: event.target.value })}
                        size="large"
                        placeholder="Link name"
                        prefix={<LinkOutlined />}
                    />
                    <br />
                    {this.state.linkType === Constant.LINK_TYPE.whatsapp && (
                        <>
                            <Input
                                maxLength={150}
                                value={this.state.linkMessage}
                                onChange={(event) =>
                                    this.onMessageChanged({ text: event.target.value })
                                }
                                size="large"
                                placeholder="Pre-filled message"
                                prefix={<MessageOutlined />}
                            />
                            <br />
                        </>
                    )}
                    <Divider orientation="left">
                        {this.state.linkType === Constant.LINK_TYPE.whatsapp
                            ? 'Customer Service Numbers'
                            : 'URLs'}
                    </Divider>
                    <br />
                    {this.state.linkItems.map((number, index) => {
                        return (
                            <Input
                                key={index}
                                autoFocus
                                style={{ marginBottom: 15 }}
                                onPressEnter={this.onNewLinkItemPressed}
                                onChange={(event) =>
                                    this.onInputChange({
                                        text: event.target.value,
                                        editedIndex: index,
                                    })
                                }
                                value={this.state.linkItems[index]}
                                size="large"
                                placeholder={`${
                                    this.state.linkType === Constant.LINK_TYPE.whatsapp
                                        ? 'Phone Number'
                                        : 'URL'
                                } (press enter for new field)`}
                                prefix={
                                    this.state.linkType === Constant.LINK_TYPE.whatsapp ? (
                                        <PhoneOutlined />
                                    ) : (
                                        <LinkOutlined />
                                    )
                                }
                                suffix={
                                    <Tooltip title="Delete this number" placement={'right'}>
                                        <Button
                                            size={'small'}
                                            danger
                                            onClick={() =>
                                                this.onDeleteLinkItemPressed({
                                                    selectedItem: number,
                                                    selectedIndex: index,
                                                })
                                            }
                                            type="primary"
                                            shape="circle"
                                            icon={<DeleteOutlined style={{ fontSize: '12pt' }} />}
                                        />
                                    </Tooltip>
                                }
                            />
                        );
                    })}
                    <br />
                    <AddPhoneButtonContainer>
                        <Tooltip
                            title={`${
                                this.state.linkType === Constant.LINK_TYPE.whatsapp
                                    ? 'Add new phone number'
                                    : 'Add new URL'
                            }`}
                            placement={'right'}
                        >
                            <Button
                                size={'large'}
                                onClick={this.onNewLinkItemPressed}
                                type="primary"
                                shape="circle"
                                icon={<PlusOutlined style={{ fontSize: '15pt' }} />}
                            />
                        </Tooltip>
                    </AddPhoneButtonContainer>
                    <Divider />
                    {this.state.mode === Constant.PAGE_MODE.create ? (
                        <Button
                            type="primary"
                            size={'large'}
                            onClick={this.onCreateButtonPressed}
                            loading={this.state.isLoading}
                        >
                            <PlusOutlined style={{ paddingRight: '10px' }} />
                            <b>CREATE</b>
                        </Button>
                    ) : (
                        <ButtonsContainer>
                            <Button
                                type="primary"
                                size={'large'}
                                onClick={this.onSaveButtonPressed}
                                loading={this.state.isLoading}
                                style={{ marginRight: 10 }}
                            >
                                <SaveOutlined style={{ paddingRight: '10px' }} />
                                <b>SAVE</b>
                            </Button>
                            <Button
                                type="danger"
                                size={'large'}
                                onClick={this.onDeleteButtonPressed}
                                loading={this.state.isLoading}
                            >
                                <DeleteOutlined style={{ paddingRight: '10px' }} />
                                <b>DELETE</b>
                            </Button>
                        </ButtonsContainer>
                    )}
                </FormContainer>
            </Container>
        );
    }
}

export default VipLinkDashboard;
