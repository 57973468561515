import { connect } from 'react-redux';
import AccountantDashboard from './AccountantDashboard';
import { createAccountant, getUserById, updateUser, getUserList } from '../../../ducks/user';

const mapStateToProps = (state) => {
    return {
        session: state.session,
        user: state.user,
    };
};

const AccountantDashboardContainer = connect(mapStateToProps, {
    createAccountant,
    getUserById,
    updateUser,
    getUserList,
})(AccountantDashboard);
export default AccountantDashboardContainer;
