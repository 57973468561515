import { connect } from 'react-redux';
import ClockIn from './ClockIn';
import { getClockList } from '../../ducks/clock';
import { getTeamList } from '../../ducks/team';
import { getUserList } from '../../ducks/user';

const mapStateToProps = (state) => {
    return {
        session: state.session,
        clock: state.clock,
        team: state.team,
        user: state.user,
    };
};

const ClockInContainer = connect(mapStateToProps, {
    getClockList,
    getTeamList,
    getUserList,
})(ClockIn);
export default ClockInContainer;
