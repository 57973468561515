import { NetworkError, HttpErrorHandler } from './service';
import { VipAPIService } from '../services';
import Sale from '../models/sale';

export default class SaleService {
    static async getSalesList({
        page_size,
        page_number,
        team_ids,
        bank_id,
        member_ids,
        start_date,
        end_date,
        filter_status,
    }) {
        const [error, response] = await VipAPIService.shared.getSalesList({
            page_size,
            page_number,
            team_ids,
            bank_id,
            member_ids,
            start_date,
            end_date,
            filter_status,
        });
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return {
                results: response.data.results.map((vipLink) => new Sale(vipLink)),
                pagination: response.data.pagination,
                reportSummary: {
                    totalSalesAmount: response.data.total_sales_amount,
                    totalAdsAmount: response.data.total_ads_amount,
                    totalExpensesAmount: response.data.total_expenses_amount,
                    totalProfit: response.data.total_profit,
                    totalCompanyAmount: response.data.total_company_amount,
                    totalAgentAmount: response.data.total_agent_amount,
                    totalMemberAmount: response.data.total_member_amount,
                    profitMargin:
                        (response.data.total_profit / response.data.total_sales_amount) * 100,
                },
            };
        }
    }
    static async deleteSaleById({ id }) {
        const [error, response] = await VipAPIService.shared.deleteSaleById({
            id,
        });
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return {};
        }
    }
    static async getSaleById({ id }) {
        const [error, response] = await VipAPIService.shared.getSaleById({
            id,
        });
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return new Sale(response.data);
        }
    }
    static async updateSale({
        id,
        sales_amount,
        ads_amount,
        expenses_amount,
        sales_date,
        transfer_type,
        team_id,
        bank_account_id,
        expense_category,
    }) {
        const [error, response] = await VipAPIService.shared.updateSale({
            id,
            sales_amount,
            ads_amount,
            expenses_amount,
            sales_date,
            transfer_type,
            team_id,
            bank_account_id,
            expense_category,
        });
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return new Sale(response.data);
        }
    }
    static async createSale({
        sales_amount,
        ads_amount,
        expenses_amount,
        sales_date,
        team_id,
        bank_account_id,
        transfer_type,
        expense_category,
    }) {
        const [error, response] = await VipAPIService.shared.createSale({
            sales_amount,
            ads_amount,
            expenses_amount,
            sales_date,
            team_id,
            bank_account_id,
            transfer_type,
            expense_category,
        });
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return new Sale(response.data);
        }
    }
    static async approveSaleById({ id }) {
        const [error, response] = await VipAPIService.shared.approveSaleById({
            id,
        });
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return {};
        }
    }
    static async rejectSaleById({ id }) {
        const [error, response] = await VipAPIService.shared.rejectSaleById({
            id,
        });
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return {};
        }
    }
    static async getSalesStatus() {
        const [error, response] = await VipAPIService.shared.getSalesStatus();
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return response.data;
        }
    }
}
