import { connect } from 'react-redux';
import CSReportDashboard from './CSReportDashboard';
import {
    createCustomerReport,
    getCustomerReportList,
    getCustomerReportById,
    updateCustomerReport,
    deleteCustomerReportById,
} from '../../../ducks/customerReport';

const mapStateToProps = (state) => {
    return {
        session: state.session,
        customerReport: state.customerReport,
    };
};

const CSReportDashboardContainer = connect(mapStateToProps, {
    createCustomerReport,
    getCustomerReportList,
    getCustomerReportById,
    updateCustomerReport,
    deleteCustomerReportById,
})(CSReportDashboard);
export default CSReportDashboardContainer;
