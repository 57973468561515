import { NetworkError, HttpErrorHandler } from './service';
import { VipAPIService } from '../services';
import CustomerReport from '../models/customerReport';

export default class TeamService {
    static async getCustomerReportList({ page_size, page_number, team_id, member_id }) {
        const [error, response] = await VipAPIService.shared.getCustomerReportList({
            page_size,
            page_number,
            team_id,
            member_id,
        });
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return {
                results: response.data.results.map(
                    (customerReport) => new CustomerReport(customerReport)
                ),
                pagination: response.data.pagination,
            };
        }
    }
    static async deleteCustomerReportById({ id }) {
        const [error, response] = await VipAPIService.shared.deleteCustomerReportById({
            id,
        });
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return {};
        }
    }
    static async getCustomerReportById({ id }) {
        const [error, response] = await VipAPIService.shared.getCustomerReportById({
            id,
        });
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return new CustomerReport(response.data);
        }
    }
    static async updateCustomerReport({ id, code, step, status, reason }) {
        const [error, response] = await VipAPIService.shared.updateCustomerReport({
            id,
            code,
            step,
            status,
            reason,
        });
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return new CustomerReport(response.data);
        }
    }
    static async createCustomerReport({ code, step, status, reason }) {
        const [error, response] = await VipAPIService.shared.createCustomerReport({
            code,
            step,
            status,
            reason,
        });
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return new CustomerReport(response.data);
        }
    }
}
