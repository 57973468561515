import styled from 'styled-components';
export const Container = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
`;
export const ButtonContainer = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
`;
