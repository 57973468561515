import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    min-height: 100vh;
    height: 100vh;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: url(${(props) => props.imgUrl});
`;

export const Card = styled.div`
    flex: 1;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    width: 400px;
    height: 300px;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
