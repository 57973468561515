import React, { Component } from 'react';
import { Button, Input, Divider, message, notification, Select } from 'antd';
import { LeftOutlined, PlusOutlined, SaveOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import {
    Container,
    ButtonContainer,
    FormContainer,
    ButtonsContainer,
    SelectContainer,
} from './index.style';
import { Constant } from '../../../helpers';

const { Option } = Select;
class TeamDashboard extends Component {
    state = {
        mode: null,
        name: '',
        leader: null,
        leadersList: [],
        isLoading: false,
    };
    componentDidMount = async () => {
        const mode =
            Object.keys(this.props.match.params).length > 0
                ? Constant.PAGE_MODE.edit
                : Constant.PAGE_MODE.create;
        await this.setState({ mode });
        await this.props.getUserList({ user_type: Constant.USER_TYPE.agent });
        if (mode === Constant.PAGE_MODE.edit) {
            const id = this.props.match.params.id;
            await this.props.getTeamById({ id });
        }
    };
    componentDidUpdate = (prevProps) => {
        if (this.props.team.error && this.props.team.error !== prevProps.team.error) {
            this.openNotificationWithIcon({
                type: 'error',
                title: this.props.team.error.frontend_type,
                message: this.props.team.error.message,
            });
            this.setState({
                isLoading: this.props.team.isLoading,
            });
        }
        if (prevProps.team.current !== this.props.team.current) {
            const team = this.props.team.current;
            if (!this.state.leader) {
                this.setState({
                    name: team.name,
                    leader: team.leaders && team.leaders.length > 0 ? team.leaders[0].user : null,
                    isLoading: false,
                });
            }
        }
        if (prevProps.user.list !== this.props.user.list) {
            this.setState({
                leadersList: this.props.user.list,
                isLoading: false,
            });
        }
    };
    openNotificationWithIcon = ({ type, title, message }) => {
        notification[type]({
            message: title,
            description: message,
        });
    };
    onBackButtonPressed = () => {
        this.props.history.replace('/team');
    };
    onTeamNameChanged = ({ text }) => {
        this.setState({ name: text });
    };
    onFormValidation = () => {
        let canSubmit = true;
        if (this.state.name.trim().length === 0) {
            message.error('Team name cannot be empty');
            return false;
        }
        if (!this.state.leader) {
            message.error('Team Leader is required');
            return false;
        }
        return canSubmit;
    };
    onSaveButtonPressed = async () => {
        if (this.onFormValidation()) {
            this.setState({ isLoading: true });
            await this.props.updateTeam({
                id: this.props.team.current.id,
                name: this.state.name,
                user_id: this.state.leader.id,
            });
            await this.props.getTeamList({ page_size: 50 });
            message.success('Team updated!');
            this.onBackButtonPressed();
        }
    };
    onCreateButtonPressed = async () => {
        if (this.onFormValidation()) {
            this.setState({ isLoading: true });
            await this.props.createTeam({
                name: this.state.name,
                user_id: this.state.leader.id,
            });
            await this.props.getTeamList({ page_size: 50 });
            message.success('Team created!');
            this.onBackButtonPressed();
        }
    };
    onLeaderChanged = (selectedLeader) => {
        this.setState({
            leader: this.state.leadersList.find((leader) => leader.username === selectedLeader),
        });
    };
    render() {
        return (
            <Container>
                <ButtonContainer>
                    <Button type="link" size={'large'} onClick={this.onBackButtonPressed}>
                        <LeftOutlined style={{ paddingRight: '10px' }} />
                        <b>Back</b>
                    </Button>
                </ButtonContainer>
                <FormContainer>
                    <Divider orientation="left">Team Details</Divider>
                    <br />
                    <Input
                        maxLength={30}
                        value={this.state.name}
                        onChange={(event) => this.onTeamNameChanged({ text: event.target.value })}
                        size="large"
                        placeholder="Team name"
                        prefix={<UsergroupAddOutlined />}
                    />
                    <br />
                    <SelectContainer>
                        <Select
                            showSearch
                            value={
                                this.state.leader ? this.state.leader.username : 'Select a Leader'
                            }
                            size={'large'}
                            style={{ width: 200 }}
                            optionFilterProp="children"
                            onChange={this.onLeaderChanged}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {this.state.leadersList.map((leader) => {
                                return (
                                    <Option key={leader.username} value={leader.username}>
                                        {leader.username}
                                    </Option>
                                );
                            })}
                        </Select>
                    </SelectContainer>
                    <br />
                    <Divider />
                    {this.state.mode === Constant.PAGE_MODE.create ? (
                        <Button
                            type="primary"
                            size={'large'}
                            onClick={this.onCreateButtonPressed}
                            loading={this.state.isLoading}
                        >
                            <PlusOutlined style={{ paddingRight: '10px' }} />
                            <b>CREATE</b>
                        </Button>
                    ) : (
                        <ButtonsContainer>
                            <Button
                                type="primary"
                                size={'large'}
                                onClick={this.onSaveButtonPressed}
                                loading={this.state.isLoading}
                                style={{ marginRight: 10 }}
                            >
                                <SaveOutlined style={{ paddingRight: '10px' }} />
                                <b>SAVE</b>
                            </Button>
                        </ButtonsContainer>
                    )}
                </FormContainer>
            </Container>
        );
    }
}

export default TeamDashboard;
