import React, { Component } from 'react';
import { Result, Button } from 'antd';
import { connect } from 'react-redux';
import { sessionExpiredAction } from '../../ducks/session';
import { Container } from './index.style';
class NotFound extends Component {
    componentDidMount = async () => {
        await this.props.sessionExpiredAction();
    };
    onHomeButtonPressed = () => {
        this.props.history.replace('/signin');
    };
    render() {
        return (
            <Container>
                <Result status="404" title="404" subTitle={'Are you lost?'} />
                <Button type="primary" size={'large'} onClick={this.onHomeButtonPressed}>
                    <b>Home</b>
                </Button>
            </Container>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        session: state.session,
    };
};

export default connect(mapStateToProps, { sessionExpiredAction })(NotFound);
