import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import { createBrowserHistory } from 'history';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { connectRouter, routerMiddleware } from 'connected-react-router';

import allReducers from '../ducks';
import { VipAPIService } from '../services';
import { sessionExpiredAction } from '../ducks/session';

const ENVIRONMENT = String(process.env.REACT_APP_ENVIRONMENT);
const history = createBrowserHistory();
const logger = createLogger({});
const routeMiddleware = routerMiddleware(history);

let middlewares = [];

if (ENVIRONMENT === 'production') {
    middlewares = [thunk, routeMiddleware];
} else {
    middlewares = [thunk, logger, routeMiddleware];
}
const persistConfig = {
    key: 'root',
    storage: storage,
};
const persistedReducer = persistReducer(
    persistConfig,
    combineReducers({ ...allReducers, router: connectRouter(history) })
);
const store = createStore(persistedReducer, {}, compose(applyMiddleware(...middlewares)));

const getDeviceData = ({ locale }) => {
    return {
        'access-control-allow-origin': '*',
        'content-type': 'application/json; charset=utf-8',
        'accept-language': locale,
        Authorization: '',
    };
};

store.subscribe(() => {
    let locale = 'en-MY';
    const deviceData = getDeviceData({
        locale,
    });
    VipAPIService.shared.api.defaults.baseURL = String(process.env.REACT_APP_API_URL);
    VipAPIService.shared.api.defaults.headers = deviceData;
    if (store.getState().session && store.getState().session.userToken) {
        VipAPIService.shared.api.defaults.headers.Authorization = store.getState().session.userToken;
    } else {
        if (store.getState().session.isLoggedIn) {
            store.dispatch(sessionExpiredAction());
        }
        delete VipAPIService.shared.api.defaults.headers.Authorization;
    }
});

export { store, history };
