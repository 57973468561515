import { NetworkError, HttpErrorHandler } from './service';
import { VipAPIService } from '../services';
import Enquiry from '../models/enquiry';

export default class EnquiryService {
    static async getEnquiryList({
        page_size,
        page_number,
        team_ids,
        user_ids,
        start_date,
        end_date,
        filter_status,
    }) {
        const [error, response] = await VipAPIService.shared.getEnquiryList({
            page_size,
            page_number,
            team_ids,
            user_ids,
            start_date,
            end_date,
            filter_status,
        });
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return {
                results: response.data.results.map((result) => new Enquiry(result)),
                pagination: response.data.pagination,
            };
        }
    }
    static async deleteEnquiryById({ id }) {
        const [error, response] = await VipAPIService.shared.deleteEnquiryById({
            id,
        });
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return {};
        }
    }
    static async getEnquiryById({ id }) {
        const [error, response] = await VipAPIService.shared.getEnquiryById({
            id,
        });
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return new Enquiry(response.data);
        }
    }
    static async updateEnquiry({ id, enquiry_amount, enquiry_date, team_id, member_id }) {
        const [error, response] = await VipAPIService.shared.updateEnquiry({
            id,
            enquiry_amount,
            enquiry_date,
            team_id,
            member_id,
        });
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return new Enquiry(response.data);
        }
    }
    static async createEnquiry({ enquiry_amount, enquiry_date, team_id, member_id }) {
        const [error, response] = await VipAPIService.shared.createEnquiry({
            enquiry_amount,
            enquiry_date,
            team_id,
            member_id,
        });
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return new Enquiry(response.data);
        }
    }
}
