import { connect } from 'react-redux';
import SignIn from './SignIn';
import { postSignIn, getProfile } from '../../ducks/session';

const mapStateToProps = (state) => {
    return {
        session: state.session,
    };
};

const SignInContainer = connect(mapStateToProps, {
    postSignIn,
    getProfile,
})(SignIn);
export default SignInContainer;
