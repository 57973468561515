import { connect } from 'react-redux';
import AdsManagerDashboard from './AdsManagerDashboard';
import { createAdsManager, getUserById, updateUser, getUserList } from '../../../ducks/user';

const mapStateToProps = (state) => {
    return {
        session: state.session,
        user: state.user,
    };
};

const AdsManagerDashboardContainer = connect(mapStateToProps, {
    createAdsManager,
    getUserById,
    updateUser,
    getUserList,
})(AdsManagerDashboard);
export default AdsManagerDashboardContainer;
