import { connect } from 'react-redux';
import Enquiry from './Enquiry';
import { getEnquiryList } from '../../ducks/enquiry';
import { getTeamList } from '../../ducks/team';

const mapStateToProps = (state) => {
    return {
        session: state.session,
        enquiry: state.enquiry,
        team: state.team,
    };
};

const EnquiryContainer = connect(mapStateToProps, {
    getEnquiryList,
    getTeamList,
})(Enquiry);
export default EnquiryContainer;
