import { connect } from 'react-redux';
import PhoneRecords from './phoneRecords';
import { getVipLinkList } from '../../ducks/vipLink';

const mapStateToProps = (state) => {
    return {
        session: state.session,
        vipLink: state.vipLink,
    };
};

const PhoneRecordsContainer = connect(mapStateToProps, {
    getVipLinkList,
})(PhoneRecords);
export default PhoneRecordsContainer;
