import { connect } from 'react-redux';
import VipLink from './VipLink';
import { getVipLinkList, regenerateVipLink } from '../../ducks/vipLink';

const mapStateToProps = (state) => {
    return {
        session: state.session,
        vipLink: state.vipLink,
    };
};

const VipLinkContainer = connect(mapStateToProps, {
    getVipLinkList,
    regenerateVipLink,
})(VipLink);
export default VipLinkContainer;
