export default class Enquiry {
    constructor(enquiry) {
        this.id = enquiry.id;
        this.enquiryAmount = enquiry.enquiry_amount;
        this.createdBy = enquiry.created_by_user;
        this.createdAt = enquiry.created_at;
        this.enquiryDate = enquiry.enquiry_date;
        this.updatedAt = enquiry.updated_at;
        this.team = enquiry.team;
        this.teamId = enquiry.team_id;
        this.memberId = enquiry.user_id;
        this.member = enquiry.user;
    }
}
