import TeamService from '../services/teamService';
// Actions
const GET_TEAM_LIST_REQUEST = 'vip/team/GET_TEAM_LIST_REQUEST';
const GET_TEAM_LIST_SUCCESS = 'vip/team/GET_TEAM_LIST_SUCCESS';
const GET_TEAM_LIST_FAIL = 'vip/team/GET_TEAM_LIST_FAIL';
const GET_TEAM_REQUEST = 'vip/team/GET_TEAM_REQUEST';
const GET_TEAM_SUCCESS = 'vip/team/GET_TEAM_SUCCESS';
const GET_TEAM_FAIL = 'vip/team/GET_TEAM_FAIL';
const DELETE_TEAM_REQUEST = 'vip/team/DELETE_TEAM_REQUEST';
const DELETE_TEAM_SUCCESS = 'vip/team/DELETE_TEAM_SUCCESS';
const DELETE_TEAM_FAIL = 'vip/team/DELETE_TEAM_FAIL';
const POST_CREATE_TEAM_REQUEST = 'vip/team/POST_CREATE_TEAM_REQUEST';
const POST_CREATE_TEAM_SUCCESS = 'vip/team/POST_CREATE_TEAM_SUCCESS';
const POST_CREATE_TEAM_FAIL = 'vip/team/POST_CREATE_TEAM_FAIL';
const PATCH_EDIT_TEAM_REQUEST = 'vip/team/PATCH_EDIT_TEAM_REQUEST';
const PATCH_EDIT_TEAM_SUCCESS = 'vip/team/PATCH_EDIT_TEAM_SUCCESS';
const PATCH_EDIT_TEAM_FAIL = 'vip/team/PATCH_EDIT_TEAM_FAIL';
const SESSION_TOKEN_EXPIRED = 'vip/session/SESSION_TOKEN_EXPIRED';

// Reducer
const INITIAL_STATE = {
    error: null,
    isLoading: false,
    current: null,
    list: [],
    pagination: null,
};

export default function reducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SESSION_TOKEN_EXPIRED: {
            return INITIAL_STATE;
        }
        case GET_TEAM_LIST_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case GET_TEAM_LIST_SUCCESS: {
            return {
                ...state,
                list: action.payload.results,
                pagination: action.payload.pagination,
                isLoading: false,
                error: null,
            };
        }
        case GET_TEAM_LIST_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case GET_TEAM_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case GET_TEAM_SUCCESS: {
            return {
                ...state,
                current: action.payload,
                isLoading: false,
                error: null,
            };
        }
        case GET_TEAM_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case DELETE_TEAM_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case DELETE_TEAM_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: null,
            };
        }
        case DELETE_TEAM_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case POST_CREATE_TEAM_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case POST_CREATE_TEAM_SUCCESS: {
            return {
                ...state,
                current: action.payload,
                isLoading: false,
                error: null,
            };
        }
        case POST_CREATE_TEAM_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case PATCH_EDIT_TEAM_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case PATCH_EDIT_TEAM_SUCCESS: {
            return {
                ...state,
                current: action.payload,
                isLoading: false,
                error: null,
            };
        }
        case PATCH_EDIT_TEAM_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        default:
            return state;
    }
}

// Action Creators
export function getTeamListRequest() {
    return {
        type: GET_TEAM_LIST_REQUEST,
    };
}
export function getTeamListSuccess(payload) {
    return {
        type: GET_TEAM_LIST_SUCCESS,
        payload,
    };
}
export function getTeamListFail(error) {
    return {
        type: GET_TEAM_LIST_FAIL,
        error,
    };
}
export function getTeamRequest() {
    return {
        type: GET_TEAM_REQUEST,
    };
}
export function getTeamSuccess(payload) {
    return {
        type: GET_TEAM_SUCCESS,
        payload,
    };
}
export function getTeamFail(error) {
    return {
        type: GET_TEAM_FAIL,
        error,
    };
}
export function deleteTeamRequest() {
    return {
        type: DELETE_TEAM_REQUEST,
    };
}
export function deleteTeamSuccess() {
    return {
        type: DELETE_TEAM_SUCCESS,
    };
}
export function deleteTeamFail(error) {
    return {
        type: DELETE_TEAM_FAIL,
        error,
    };
}
export function updateTeamRequest() {
    return {
        type: PATCH_EDIT_TEAM_REQUEST,
    };
}
export function updateTeamSuccess(payload) {
    return {
        type: PATCH_EDIT_TEAM_SUCCESS,
        payload,
    };
}
export function updateTeamFail(error) {
    return {
        type: PATCH_EDIT_TEAM_FAIL,
        error,
    };
}
export function createTeamRequest() {
    return {
        type: POST_CREATE_TEAM_REQUEST,
    };
}
export function createTeamSuccess(payload) {
    return {
        type: POST_CREATE_TEAM_SUCCESS,
        payload,
    };
}
export function createTeamFail(error) {
    return {
        type: POST_CREATE_TEAM_FAIL,
        error,
    };
}
// side effects, only as applicable
// e.g. thunks, epics, etc
export const getTeamList = ({ page_size, page_number } = {}) => async (dispatch) => {
    dispatch(getTeamListRequest());
    try {
        const list = await TeamService.getTeamList({ page_size, page_number });
        dispatch(getTeamListSuccess(list));
    } catch (error) {
        dispatch(getTeamListFail(error));
        throw error;
    }
};
export const createTeam = ({ name, user_id, commission_percentage }) => async (dispatch) => {
    dispatch(createTeamRequest());
    try {
        const result = await TeamService.createTeam({
            name,
            user_id,
            commission_percentage,
        });
        dispatch(createTeamSuccess(result));
    } catch (error) {
        dispatch(createTeamFail(error));
        throw error;
    }
};
export const getTeamById = ({ id }) => async (dispatch) => {
    dispatch(getTeamRequest());
    try {
        const result = await TeamService.getTeamById({
            id,
        });
        dispatch(getTeamSuccess(result));
    } catch (error) {
        dispatch(getTeamFail(error));
        throw error;
    }
};
export const updateTeam = ({ id, name, user_id, commission_percentage }) => async (dispatch) => {
    dispatch(updateTeamRequest());
    try {
        const result = await TeamService.updateTeam({
            id,
            name,
            user_id,
            commission_percentage,
        });
        dispatch(updateTeamSuccess(result));
    } catch (error) {
        dispatch(updateTeamFail(error));
        throw error;
    }
};
export const deleteTeamById = ({ id }) => async (dispatch) => {
    dispatch(deleteTeamRequest());
    try {
        await TeamService.deleteTeamById({
            id,
        });
        dispatch(deleteTeamSuccess());
    } catch (error) {
        dispatch(deleteTeamFail(error));
        throw error;
    }
};
