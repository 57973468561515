import ClockService from '../services/clockService';
// Actions
const GET_CLOCK_LIST_REQUEST = 'vip/clock/GET_CLOCK_LIST_REQUEST';
const GET_CLOCK_LIST_SUCCESS = 'vip/clock/GET_CLOCK_LIST_SUCCESS';
const GET_CLOCK_LIST_FAIL = 'vip/clock/GET_CLOCK_LIST_FAIL';
const POST_CLOCK_IN_REQUEST = 'vip/clock/POST_CLOCK_IN_REQUEST';
const POST_CLOCK_IN_SUCCESS = 'vip/clock/POST_CLOCK_IN_SUCCESS';
const POST_CLOCK_IN_FAIL = 'vip/clock/POST_CLOCK_IN_FAIL';
const PATCH_CLOCK_OUT_REQUEST = 'vip/clock/PATCH_CLOCK_OUT_REQUEST';
const PATCH_CLOCK_OUT_SUCCESS = 'vip/clock/PATCH_CLOCK_OUT_SUCCESS';
const PATCH_CLOCK_OUT_FAIL = 'vip/clock/PATCH_CLOCK_OUT_FAIL';
const SESSION_TOKEN_EXPIRED = 'vip/session/SESSION_TOKEN_EXPIRED';

// Reducer
const INITIAL_STATE = {
    error: null,
    isLoading: false,
    list: [],
};

export default function reducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SESSION_TOKEN_EXPIRED: {
            return INITIAL_STATE;
        }
        case GET_CLOCK_LIST_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case GET_CLOCK_LIST_SUCCESS: {
            return {
                ...state,
                list: action.payload.results,
                pagination: action.payload.pagination,
                isLoading: false,
                error: null,
            };
        }
        case GET_CLOCK_LIST_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case POST_CLOCK_IN_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case POST_CLOCK_IN_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: null,
            };
        }
        case POST_CLOCK_IN_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case PATCH_CLOCK_OUT_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case PATCH_CLOCK_OUT_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: null,
            };
        }
        case PATCH_CLOCK_OUT_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        default:
            return state;
    }
}

// Action Creators
export function getClockListRequest() {
    return {
        type: GET_CLOCK_LIST_REQUEST,
    };
}
export function getClockListSuccess(payload) {
    return {
        type: GET_CLOCK_LIST_SUCCESS,
        payload,
    };
}
export function getClockListFail(error) {
    return {
        type: GET_CLOCK_LIST_FAIL,
        error,
    };
}
export function postClockInRequest() {
    return {
        type: POST_CLOCK_IN_REQUEST,
    };
}
export function postClockInSuccess() {
    return {
        type: POST_CLOCK_IN_SUCCESS,
    };
}
export function postClockInFail(error) {
    return {
        type: POST_CLOCK_IN_FAIL,
        error,
    };
}
export function patchClockOutRequest() {
    return {
        type: PATCH_CLOCK_OUT_REQUEST,
    };
}
export function patchClockOutSuccess() {
    return {
        type: PATCH_CLOCK_OUT_SUCCESS,
    };
}
export function patchClockOutFail(error) {
    return {
        type: PATCH_CLOCK_OUT_FAIL,
        error,
    };
}
// side effects, only as applicable
// e.g. thunks, epics, etc
export const getClockList = ({
    page_size,
    page_number,
    team_id,
    member_id,
    start_date_time,
    end_date_time,
} = {}) => async (dispatch) => {
    dispatch(getClockListRequest());
    try {
        const list = await ClockService.getClockList({
            page_size,
            page_number,
            team_id,
            member_id,
            start_date_time,
            end_date_time,
        });
        dispatch(getClockListSuccess(list));
    } catch (error) {
        dispatch(getClockListFail(error));
        throw error;
    }
};
export const postClockIn = ({ lat, lng }) => async (dispatch) => {
    dispatch(postClockInRequest());
    try {
        await ClockService.postClockIn({ lat, lng });
        dispatch(postClockInSuccess());
    } catch (error) {
        dispatch(postClockInFail(error));
        throw error;
    }
};
export const patchClockOut = () => async (dispatch) => {
    dispatch(patchClockOutRequest());
    try {
        await ClockService.patchClockOut();
        dispatch(patchClockOutSuccess());
    } catch (error) {
        dispatch(patchClockOutFail(error));
        throw error;
    }
};
