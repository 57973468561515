import React, { Component } from 'react';
import { Button, Input, Divider, message, Select, notification } from 'antd';
import {
    LeftOutlined,
    PlusOutlined,
    UserOutlined,
    SaveOutlined,
    PhoneOutlined,
    SmileOutlined,
} from '@ant-design/icons';
import { Container, ButtonContainer, FormContainer, StatusContainer } from './index.style';
import { Constant, Utility } from '../../../helpers';

const { Option } = Select;

class AdsManagerDashboard extends Component {
    state = {
        mode: null,
        firstName: '',
        lastName: '',
        username: '',
        phoneNumber: '',
        password: '',
        confirmPassword: '',
        userType: Constant.USER_TYPE.ads_manager,
        userStatus: Constant.USER_STATUS.active,
    };
    componentDidMount = async () => {
        const mode =
            Object.keys(this.props.match.params).length > 0
                ? Constant.PAGE_MODE.edit
                : Constant.PAGE_MODE.create;
        this.setState({ mode });
        if (mode === Constant.PAGE_MODE.edit) {
            const id = this.props.match.params.id;
            await this.props.getUserById({ id });
        }
    };
    componentDidUpdate = (prevProps) => {
        if (this.props.user.error && this.props.user.error !== prevProps.user.error) {
            this.openNotificationWithIcon({
                type: 'error',
                title: this.props.user.error.frontend_type,
                message: this.props.user.error.message,
            });
            this.setState({
                isLoading: this.props.user.isLoading,
            });
        }
        if (prevProps.user.current !== this.props.user.current) {
            const user = this.props.user.current;
            this.setState({
                firstName: user.firstName,
                lastName: user.lastName,
                username: user.username,
                phoneNumber: user.phoneNumber,
                userStatus: user.status,
                userType: user.userType,
                password: null,
                isLoading: false,
            });
        }
    };
    openNotificationWithIcon = ({ type, title, message }) => {
        notification[type]({
            message: title,
            description: message,
        });
    };
    onBackButtonPressed = () => {
        this.props.history.replace('/ads_manager');
    };
    onPhoneNumberChanged = ({ text }) => {
        this.setState({ phoneNumber: text });
    };
    onUsernameChanged = ({ text }) => {
        this.setState({ username: text });
    };
    onFirstNameChanged = ({ text }) => {
        this.setState({ firstName: text });
    };
    onLastNameChanged = ({ text }) => {
        this.setState({ lastName: text });
    };
    onPasswordChanged = ({ text }) => {
        this.setState({ password: text });
    };
    onConfirmPasswordChanged = ({ text }) => {
        this.setState({ confirmPassword: text });
    };
    onStatusChanged = (status) => {
        this.setState({ userStatus: status });
    };
    formValidation = () => {
        if (!Utility.usernameRegexCheck(this.state.username)) {
            message.error(
                'Alphanumeric string that may include _ and – having a length of 3 to 16 characters'
            );
            return false;
        }
        if (!Utility.malaysiaPhoneNumberRegexCheck(this.state.phoneNumber)) {
            message.error('Please insert a valid phone number!');
            return false;
        }
        if (this.state.firstName.trim().length === 0) {
            message.error('First name cannot be empty');
            return false;
        }
        if (this.state.lastName.trim().length === 0) {
            message.error('Last name cannot be empty');
            return false;
        }
        if (!this.state.userStatus) {
            message.error('Please select a status');
            return false;
        }
        if (this.state.mode === Constant.PAGE_MODE.edit) {
            if (this.state.password && this.state.password.length < 8) {
                message.error('Password should be AT LEAST 8 characters long');
                return false;
            }
            if (this.state.password && this.state.confirmPassword !== this.state.password) {
                message.error('Password does not match');
                return false;
            }
        }
        if (this.state.mode === Constant.PAGE_MODE.create) {
            if (this.state.password.length === 0) {
                message.error('Password cannot be empty');
                return false;
            }
            if (this.state.password.length < 8) {
                message.error('Password should be AT LEAST 8 characters long');
                return false;
            }
            if (this.state.confirmPassword !== this.state.password) {
                message.error('Password does not match');
                return false;
            }
        }
        return true;
    };
    onSaveButtonPressed = async () => {
        // call API
        if (this.formValidation()) {
            this.setState({ isLoading: true });
            await this.props.updateUser({
                id: this.props.user.current.id,
                password: this.state.password,
                username: this.state.username,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                phoneNumber: this.state.phoneNumber,
                status: this.state.userStatus,
            });
            message.success('Ads manager account updated!');
            this.onBackButtonPressed();
        }
    };
    onCreateButtonPressed = async () => {
        // call API
        if (this.formValidation()) {
            this.setState({ isLoading: true });
            await this.props.createAdsManager({
                username: this.state.username,
                password: this.state.password,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                phoneNumber: this.state.phoneNumber,
            });
            await this.props.getUserList({ user_type: Constant.USER_TYPE.ads_manager });
            message.success('Ads manager account created!');
            this.onBackButtonPressed();
        }
    };
    render() {
        return (
            <Container>
                <ButtonContainer>
                    <Button type="link" size={'large'} onClick={this.onBackButtonPressed}>
                        <LeftOutlined style={{ paddingRight: '10px' }} />
                        <b>Back</b>
                    </Button>
                </ButtonContainer>
                <FormContainer>
                    <Divider orientation="left">Accountant Details</Divider>
                    <br />
                    <Input
                        maxLength={30}
                        value={this.state.username}
                        onChange={(event) => this.onUsernameChanged({ text: event.target.value })}
                        size="large"
                        placeholder="Username"
                        prefix={<SmileOutlined />}
                    />
                    <br />
                    <Input
                        maxLength={30}
                        value={this.state.firstName}
                        onChange={(event) => this.onFirstNameChanged({ text: event.target.value })}
                        size="large"
                        placeholder="First name"
                        prefix={<UserOutlined />}
                    />
                    <br />
                    <Input
                        maxLength={30}
                        value={this.state.lastName}
                        onChange={(event) => this.onLastNameChanged({ text: event.target.value })}
                        size="large"
                        placeholder="Last name"
                        prefix={<UserOutlined />}
                    />
                    <br />
                    <Input
                        maxLength={30}
                        value={this.state.phoneNumber}
                        onChange={(event) =>
                            this.onPhoneNumberChanged({ text: event.target.value })
                        }
                        size="large"
                        placeholder="Phone number"
                        prefix={<PhoneOutlined />}
                    />
                    <br />
                    {this.state.mode === Constant.PAGE_MODE.edit && (
                        <StatusContainer>
                            <Select
                                size={'large'}
                                placeholder={'Select a status'}
                                onChange={this.onStatusChanged}
                                style={{ width: 200 }}
                                value={this.state.userStatus}
                            >
                                <Option
                                    key={Constant.USER_STATUS.active}
                                    value={Constant.USER_STATUS.active}
                                >
                                    Active
                                </Option>
                                <Option
                                    key={Constant.USER_STATUS.suspended}
                                    value={Constant.USER_STATUS.suspended}
                                >
                                    Suspended
                                </Option>
                            </Select>
                        </StatusContainer>
                    )}
                    <Divider orientation="left">
                        {this.state.mode === Constant.PAGE_MODE.create
                            ? 'Password'
                            : 'New Password'}
                    </Divider>
                    <br />
                    <Input.Password
                        placeholder="Password"
                        onChange={(event) => this.onPasswordChanged({ text: event.target.value })}
                    />
                    <br />
                    <Input.Password
                        placeholder="Confirm password"
                        onChange={(event) =>
                            this.onConfirmPasswordChanged({
                                text: event.target.value,
                            })
                        }
                    />
                    <br />
                    <Divider />
                    {this.state.mode === Constant.PAGE_MODE.create ? (
                        <Button type="primary" size={'large'} onClick={this.onCreateButtonPressed}>
                            <PlusOutlined style={{ paddingRight: '10px' }} />
                            <b>CREATE</b>
                        </Button>
                    ) : (
                        <Button
                            type="primary"
                            size={'large'}
                            onClick={this.onSaveButtonPressed}
                            loading={this.state.isLoading}
                            style={{ marginRight: 10 }}
                        >
                            <SaveOutlined style={{ paddingRight: '10px' }} />
                            <b>SAVE</b>
                        </Button>
                    )}
                </FormContainer>
            </Container>
        );
    }
}

export default AdsManagerDashboard;
