import { connect } from 'react-redux';
import CSReportManagement from './CSReportManagement';
import { getCustomerReportList } from '../../ducks/customerReport';
import { getTeamList } from '../../ducks/team';
import { getUserList } from '../../ducks/user';

const mapStateToProps = (state) => {
    return {
        session: state.session,
        customerReport: state.customerReport,
        team: state.team,
        user: state.user,
    };
};

const CSReportManagementContainer = connect(mapStateToProps, {
    getCustomerReportList,
    getTeamList,
    getUserList,
})(CSReportManagement);
export default CSReportManagementContainer;
