export default class Team {
    constructor(team) {
        this.id = team.id;
        this.name = team.name;
        this.createdBy = team.created_by_user;
        this.leaders = team.leaders;
        this.members = team.members;
        this.commissionPercentage =
            team.leaders && team.leaders[0] ? team.leaders[0].commission_percentage : null;
    }
}
