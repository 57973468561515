import { connect } from 'react-redux';
import AnnouncementDashboard from './AnnouncementDashboard';
import {
    createAnnouncement,
    getAnnouncementList,
    getAnnouncementById,
    updateAnnouncement,
    deleteAnnouncementById,
} from '../../../ducks/announcement';

const mapStateToProps = (state) => {
    return {
        session: state.session,
        announcement: state.announcement,
        user: state.user,
    };
};

const AnnouncementDashboardContainer = connect(mapStateToProps, {
    createAnnouncement,
    getAnnouncementList,
    getAnnouncementById,
    updateAnnouncement,
    deleteAnnouncementById,
})(AnnouncementDashboard);
export default AnnouncementDashboardContainer;
