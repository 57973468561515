import { NetworkError, HttpErrorHandler } from './service';
import { VipAPIService } from '../services';
import VipLink from '../models/vipLink';

export default class VipLinkService {
    static async getVipLinkList({ page_size, page_number }) {
        const [error, response] = await VipAPIService.shared.getVipLinkList({
            page_size,
            page_number,
        });
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return {
                results: response.data.results.map((vipLink) => new VipLink(vipLink)),
                pagination: response.data.pagination,
            };
        }
    }
    static async getClientLinkById({ id }) {
        const [error, response] = await VipAPIService.shared.getClientLinkById({
            id,
        });
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return response.data;
        }
    }
    static async deleteVipLinkById({ id }) {
        const [error, response] = await VipAPIService.shared.deleteVipLinkById({
            id,
        });
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return {};
        }
    }
    static async regenerateVipLink({ id }) {
        const [error, response] = await VipAPIService.shared.regenerateVipLink({
            id,
        });
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return new VipLink(response.data);
        }
    }
    static async getVipLinkById({ id }) {
        const [error, response] = await VipAPIService.shared.getVipLinkById({
            id,
        });
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return new VipLink(response.data);
        }
    }
    static async updateVipLink({ id, name, numbers, message, status, type }) {
        const [error, response] = await VipAPIService.shared.updateVipLink({
            id,
            name,
            numbers,
            message,
            status,
            type,
        });
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return new VipLink(response.data);
        }
    }
    static async createVipLink({ name, numbers, message, status, type }) {
        const [error, response] = await VipAPIService.shared.createVipLink({
            name,
            numbers,
            message,
            status,
            type,
        });
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return new VipLink(response.data);
        }
    }
}
