export default class Clock {
    constructor(clock) {
        this.id = clock.id;
        this.userId = clock.user_id;
        this.inAt = clock.in_at;
        this.outAt = clock.out_at;
        this.user = clock.user;
        this.team = clock.team;
        this.offDay = clock.off_day;
    }
}
