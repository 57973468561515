import React, { Component } from 'react';
import { Button, Input, Divider, message, notification, Select } from 'antd';
import { LeftOutlined, PlusOutlined, SaveOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import {
    Container,
    ButtonContainer,
    FormContainer,
    ButtonsContainer,
    SelectContainer,
} from './index.style';
import { Constant } from '../../../helpers';

const { Option } = Select;
const { TextArea } = Input;
class CSReportDashboard extends Component {
    state = {
        mode: null,
        code: '',
        status: Constant.CUSTOMER_REPORT_STATUS.active,
        reason: '',
        step: Constant.CUSTOMER_REPORT_STEP.newDeposit,
        isLoading: false,
    };
    componentDidMount = async () => {
        const mode =
            Object.keys(this.props.match.params).length > 0
                ? Constant.PAGE_MODE.edit
                : Constant.PAGE_MODE.create;
        await this.setState({ mode });
        if (mode === Constant.PAGE_MODE.edit) {
            const id = this.props.match.params.id;
            await this.props.getCustomerReportById({ id });
        }
    };
    componentDidUpdate = (prevProps) => {
        if (
            this.props.customerReport.error &&
            this.props.customerReport.error !== prevProps.customerReport.error
        ) {
            this.openNotificationWithIcon({
                type: 'error',
                title: this.props.customerReport.error.frontend_type,
                message: this.props.customerReport.error.message,
            });
            this.setState({
                isLoading: this.props.customerReport.isLoading,
            });
        }
        if (prevProps.customerReport.current !== this.props.customerReport.current) {
            const customerReport = this.props.customerReport.current;
            this.setState({
                code: customerReport.code,
                status: customerReport.status,
                reason: customerReport.reason,
                step: customerReport.step,
                isLoading: false,
            });
        }
    };
    openNotificationWithIcon = ({ type, title, message }) => {
        notification[type]({
            message: title,
            description: message,
        });
    };
    onBackButtonPressed = () => {
        this.props.history.replace('/cs-report');
    };
    onCodeChanged = ({ text }) => {
        this.setState({ code: text });
    };
    onReasonChanged = ({ text }) => {
        this.setState({ reason: text });
    };
    onFormValidation = () => {
        let canSubmit = true;
        if (this.state.code.trim().length === 0) {
            message.error('Code cannot be empty');
            return false;
        }
        if (this.state.step.trim().length === 0) {
            message.error('Please Select a Step');
            return false;
        }
        if (this.state.status.trim().length === 0) {
            message.error('Please Select a Status');
            return false;
        }
        return canSubmit;
    };
    onSaveButtonPressed = async () => {
        if (this.onFormValidation()) {
            this.setState({ isLoading: true });
            await this.props.updateCustomerReport({
                id: this.props.customerReport.current.id,
                code: this.state.code,
                status: this.state.status,
                reason: this.state.reason,
                step: this.state.step,
            });
            await this.props.getCustomerReportList();
            message.success('CS Report updated!');
            this.onBackButtonPressed();
        }
    };
    onCreateButtonPressed = async () => {
        if (this.onFormValidation()) {
            this.setState({ isLoading: true });
            await this.props.createCustomerReport({
                code: this.state.code,
                status: this.state.status,
                reason: this.state.reason,
                step: this.state.step,
            });
            await this.props.getCustomerReportList();
            message.success('CS Report created!');
            this.onBackButtonPressed();
        }
    };
    onStepChanged = (step) => {
        this.setState({
            step,
        });
    };
    onStatusChanged = (status) => {
        this.setState({
            status,
        });
    };
    render() {
        return (
            <Container>
                <ButtonContainer>
                    <Button type="link" size={'large'} onClick={this.onBackButtonPressed}>
                        <LeftOutlined style={{ paddingRight: '10px' }} />
                        <b>Back</b>
                    </Button>
                </ButtonContainer>
                <FormContainer>
                    <Divider orientation="left">Report Details</Divider>
                    <br />
                    <Input
                        maxLength={30}
                        value={this.state.code}
                        onChange={(event) => this.onCodeChanged({ text: event.target.value })}
                        size="large"
                        placeholder="Code"
                        prefix={<UsergroupAddOutlined />}
                    />
                    <br />
                    <SelectContainer>
                        <Select
                            placeholder="Step"
                            showSearch
                            value={this.state.step}
                            size={'large'}
                            style={{ width: 200, textTransform: 'capitalize' }}
                            optionFilterProp="children"
                            onChange={this.onStepChanged}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {Object.values(Constant.CUSTOMER_REPORT_STEP).map((step) => {
                                return (
                                    <Option
                                        key={step}
                                        value={step}
                                        style={{ textTransform: 'capitalize' }}
                                    >
                                        {step.replace('_', ' ')}
                                    </Option>
                                );
                            })}
                        </Select>
                    </SelectContainer>
                    <br />
                    <SelectContainer>
                        <Select
                            placeholder="Status"
                            showSearch
                            value={this.state.status}
                            size={'large'}
                            style={{ width: 200, textTransform: 'capitalize' }}
                            optionFilterProp="children"
                            onChange={this.onStatusChanged}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {Object.values(Constant.CUSTOMER_REPORT_STATUS).map((step) => {
                                return (
                                    <Option
                                        key={step}
                                        value={step}
                                        style={{ textTransform: 'capitalize' }}
                                    >
                                        {step}
                                    </Option>
                                );
                            })}
                        </Select>
                    </SelectContainer>
                    <br />
                    <TextArea
                        value={this.state.reason}
                        onChange={(event) => this.onReasonChanged({ text: event.target.value })}
                        placeholder="Reason/Feedback"
                        autoSize={{ minRows: 3, maxRows: 5 }}
                    />
                    <br />
                    <Divider />
                    {this.state.mode === Constant.PAGE_MODE.create ? (
                        <Button
                            type="primary"
                            size={'large'}
                            onClick={this.onCreateButtonPressed}
                            loading={this.state.isLoading}
                        >
                            <PlusOutlined style={{ paddingRight: '10px' }} />
                            <b>CREATE</b>
                        </Button>
                    ) : (
                        <ButtonsContainer>
                            <Button
                                type="primary"
                                size={'large'}
                                onClick={this.onSaveButtonPressed}
                                loading={this.state.isLoading}
                                style={{ marginRight: 10 }}
                            >
                                <SaveOutlined style={{ paddingRight: '10px' }} />
                                <b>SAVE</b>
                            </Button>
                        </ButtonsContainer>
                    )}
                </FormContainer>
            </Container>
        );
    }
}

export default CSReportDashboard;
