import { NetworkError, HttpErrorHandler } from './service';
import { VipAPIService } from '.';
import User from '../models/user';

export default class UserService {
    static async getUserList({ page_size, page_number, user_type, team_ids }) {
        const [error, response] = await VipAPIService.shared.getUserList({
            page_size,
            page_number,
            user_type,
            team_ids,
        });
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return {
                results: response.data.results.map((agent) => new User(agent)),
                pagination: response.data.pagination,
            };
        }
    }
    static async getUserById({ id }) {
        const [error, response] = await VipAPIService.shared.getUserById({
            id,
        });
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return new User(response.data);
        }
    }
    static async updateUser({
        id,
        username,
        password,
        firstName,
        lastName,
        phoneNumber,
        status,
        commissionPercentage,
        commissionsTier,
    }) {
        const [error, response] = await VipAPIService.shared.updateUser({
            id,
            username,
            password,
            firstName,
            lastName,
            phoneNumber,
            status,
            commissionPercentage,
            commissionsTier,
        });
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return new User(response.data);
        }
    }
    static async createAgent({ username, password, firstName, lastName, phoneNumber }) {
        const [error, response] = await VipAPIService.shared.createAgent({
            username,
            password,
            firstName,
            lastName,
            phoneNumber,
        });
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return new User(response.data);
        }
    }
    static async createAccountant({ username, password, firstName, lastName, phoneNumber }) {
        const [error, response] = await VipAPIService.shared.createAccountant({
            username,
            password,
            firstName,
            lastName,
            phoneNumber,
        });
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return new User(response.data);
        }
    }
    static async createMember({
        username,
        password,
        firstName,
        lastName,
        phoneNumber,
        teamId,
        commissionPercentage,
        commissionsTier,
    }) {
        const [error, response] = await VipAPIService.shared.createMember({
            username,
            password,
            firstName,
            lastName,
            phoneNumber,
            teamId,
            commissionPercentage,
            commissionsTier,
        });
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return new User(response.data);
        }
    }
    static async createAdsManager({ username, password, firstName, lastName, phoneNumber }) {
        const [error, response] = await VipAPIService.shared.createAdsManager({
            username,
            password,
            firstName,
            lastName,
            phoneNumber,
        });
        if (error) {
            if (error.response.data.message === 'Network Error') {
                throw new NetworkError(error.response);
            } else {
                throw new HttpErrorHandler(error.response.data);
            }
        }
        if (response.status === 200) {
            return new User(response.data);
        }
    }
}
