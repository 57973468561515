import VipLinkService from '../services/vipLinkService';
// Actions
const GET_CLIENT_LINK_BY_ID_REQUEST = 'vip/vipLink/GET_CLIENT_LINK_BY_ID_REQUEST';
const GET_CLIENT_LINK_BY_ID_SUCCESS = 'vip/vipLink/GET_CLIENT_LINK_BY_ID_SUCCESS';
const GET_CLIENT_LINK_BY_ID_FAIL = 'vip/vipLink/GET_CLIENT_LINK_BY_ID_FAIL';
const GET_VIP_LINK_LIST_REQUEST = 'vip/vipLink/GET_VIP_LINK_LIST_REQUEST';
const GET_VIP_LINK_LIST_SUCCESS = 'vip/vipLink/GET_VIP_LINK_LIST_SUCCESS';
const GET_VIP_LINK_LIST_FAIL = 'vip/vipLink/GET_VIP_LINK_LIST_FAIL';
const GET_VIP_LINK_REQUEST = 'vip/vipLink/GET_VIP_LINK_REQUEST';
const GET_VIP_LINK_SUCCESS = 'vip/vipLink/GET_VIP_LINK_SUCCESS';
const GET_VIP_LINK_FAIL = 'vip/vipLink/GET_VIP_LINK_FAIL';
const DELETE_VIP_LINK_REQUEST = 'vip/vipLink/DELETE_VIP_LINK_REQUEST';
const DELETE_VIP_LINK_SUCCESS = 'vip/vipLink/DELETE_VIP_LINK_SUCCESS';
const DELETE_VIP_LINK_FAIL = 'vip/vipLink/DELETE_VIP_LINK_FAIL';
const POST_CREATE_VIP_LINK_REQUEST = 'vip/vipLink/POST_CREATE_VIP_LINK_REQUEST';
const POST_CREATE_VIP_LINK_SUCCESS = 'vip/vipLink/POST_CREATE_VIP_LINK_SUCCESS';
const POST_CREATE_VIP_LINK_FAIL = 'vip/vipLink/POST_CREATE_VIP_LINK_FAIL';
const PATCH_EDIT_VIP_LINK_REQUEST = 'vip/vipLink/PATCH_EDIT_VIP_LINK_REQUEST';
const PATCH_EDIT_VIP_LINK_SUCCESS = 'vip/vipLink/PATCH_EDIT_VIP_LINK_SUCCESS';
const PATCH_EDIT_VIP_LINK_FAIL = 'vip/vipLink/PATCH_EDIT_VIP_LINK_FAIL';
const PATCH_REGENERATE_VIP_LINK_REQUEST = 'vip/vipLink/PATCH_REGENERATE_VIP_LINK_REQUEST';
const PATCH_REGENERATE_VIP_LINK_SUCCESS = 'vip/vipLink/PATCH_REGENERATE_VIP_LINK_SUCCESS';
const PATCH_REGENERATE_VIP_LINK_FAIL = 'vip/vipLink/PATCH_REGENERATE_VIP_LINK_FAIL';
const SESSION_TOKEN_EXPIRED = 'vip/session/SESSION_TOKEN_EXPIRED';

// Reducer
const INITIAL_STATE = {
    error: null,
    isLoading: false,
    current: null,
    list: [],
    clientLink: null,
    pagination: null,
};

export default function reducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SESSION_TOKEN_EXPIRED: {
            return INITIAL_STATE;
        }
        case GET_VIP_LINK_LIST_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case GET_VIP_LINK_LIST_SUCCESS: {
            return {
                ...state,
                list: action.payload.results,
                pagination: action.payload.pagination,
                isLoading: false,
                error: null,
            };
        }
        case GET_VIP_LINK_LIST_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case GET_VIP_LINK_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case GET_VIP_LINK_SUCCESS: {
            return {
                ...state,
                current: action.payload,
                isLoading: false,
                error: null,
            };
        }
        case GET_VIP_LINK_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case GET_CLIENT_LINK_BY_ID_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case GET_CLIENT_LINK_BY_ID_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                clientLink: action.payload.url,
                error: null,
            };
        }
        case GET_CLIENT_LINK_BY_ID_FAIL: {
            return {
                ...state,
                isLoading: false,
                clientLink: null,
                error: action.error,
            };
        }
        case DELETE_VIP_LINK_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case DELETE_VIP_LINK_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: null,
            };
        }
        case DELETE_VIP_LINK_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case POST_CREATE_VIP_LINK_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case POST_CREATE_VIP_LINK_SUCCESS: {
            return {
                ...state,
                current: action.payload,
                isLoading: false,
                error: null,
            };
        }
        case POST_CREATE_VIP_LINK_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case PATCH_EDIT_VIP_LINK_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case PATCH_EDIT_VIP_LINK_SUCCESS: {
            return {
                ...state,
                current: action.payload,
                isLoading: false,
                error: null,
            };
        }
        case PATCH_EDIT_VIP_LINK_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case PATCH_REGENERATE_VIP_LINK_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case PATCH_REGENERATE_VIP_LINK_SUCCESS: {
            return {
                ...state,
                current: action.payload,
                isLoading: false,
                error: null,
            };
        }
        case PATCH_REGENERATE_VIP_LINK_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        default:
            return state;
    }
}

// Action Creators
export function getVipLinkListRequest() {
    return {
        type: GET_VIP_LINK_LIST_REQUEST,
    };
}
export function getVipLinkListSuccess(payload) {
    return {
        type: GET_VIP_LINK_LIST_SUCCESS,
        payload,
    };
}
export function getVipLinkListFail(error) {
    return {
        type: GET_VIP_LINK_LIST_FAIL,
        error,
    };
}
export function getVipLinkRequest() {
    return {
        type: GET_VIP_LINK_REQUEST,
    };
}
export function getVipLinkSuccess(payload) {
    return {
        type: GET_VIP_LINK_SUCCESS,
        payload,
    };
}
export function getVipLinkFail(error) {
    return {
        type: GET_VIP_LINK_FAIL,
        error,
    };
}
export function getClientLinkByIdRequest() {
    return {
        type: GET_CLIENT_LINK_BY_ID_REQUEST,
    };
}
export function getClientLinkByIdSuccess(payload) {
    return {
        type: GET_CLIENT_LINK_BY_ID_SUCCESS,
        payload,
    };
}
export function getClientLinkByIdFail(error) {
    return {
        type: GET_CLIENT_LINK_BY_ID_FAIL,
        error,
    };
}
export function deleteVipLinkRequest() {
    return {
        type: DELETE_VIP_LINK_REQUEST,
    };
}
export function deleteVipLinkSuccess() {
    return {
        type: DELETE_VIP_LINK_SUCCESS,
    };
}
export function deleteVipLinkFail(error) {
    return {
        type: DELETE_VIP_LINK_FAIL,
        error,
    };
}
export function updateVipLinkRequest() {
    return {
        type: PATCH_EDIT_VIP_LINK_REQUEST,
    };
}
export function updateVipLinkSuccess(payload) {
    return {
        type: PATCH_EDIT_VIP_LINK_SUCCESS,
        payload,
    };
}
export function updateVipLinkFail(error) {
    return {
        type: PATCH_EDIT_VIP_LINK_FAIL,
        error,
    };
}
export function createVipLinkRequest() {
    return {
        type: POST_CREATE_VIP_LINK_REQUEST,
    };
}
export function createVipLinkSuccess(payload) {
    return {
        type: POST_CREATE_VIP_LINK_SUCCESS,
        payload,
    };
}
export function createVipLinkFail(error) {
    return {
        type: POST_CREATE_VIP_LINK_FAIL,
        error,
    };
}
export function regenerateVipLinkRequest() {
    return {
        type: PATCH_REGENERATE_VIP_LINK_REQUEST,
    };
}
export function regenerateVipLinkSuccess(payload) {
    return {
        type: PATCH_REGENERATE_VIP_LINK_SUCCESS,
        payload,
    };
}
export function regenerateVipLinkFail(error) {
    return {
        type: PATCH_REGENERATE_VIP_LINK_FAIL,
        error,
    };
}

// side effects, only as applicable
// e.g. thunks, epics, etc
export const getClientLinkById = ({ id } = {}) => async (dispatch) => {
    dispatch(getClientLinkByIdRequest());
    try {
        const link = await VipLinkService.getClientLinkById({ id });
        dispatch(getClientLinkByIdSuccess(link));
    } catch (error) {
        dispatch(getClientLinkByIdFail(error));
        throw error;
    }
};
export const getVipLinkList = ({ page_size, page_number } = {}) => async (dispatch) => {
    dispatch(getVipLinkListRequest());
    try {
        const list = await VipLinkService.getVipLinkList({ page_size, page_number });
        dispatch(getVipLinkListSuccess(list));
    } catch (error) {
        dispatch(getVipLinkListFail(error));
        throw error;
    }
};
export const regenerateVipLink = ({ id } = {}) => async (dispatch) => {
    dispatch(regenerateVipLinkRequest());
    try {
        const newLink = await VipLinkService.regenerateVipLink({ id });
        dispatch(regenerateVipLinkSuccess(newLink));
    } catch (error) {
        dispatch(regenerateVipLinkFail(error));
        throw error;
    }
};
export const createVipLink = ({ name, numbers, message, status, type }) => async (dispatch) => {
    dispatch(createVipLinkRequest());
    try {
        const result = await VipLinkService.createVipLink({
            name,
            numbers,
            message,
            status,
            type,
        });
        dispatch(createVipLinkSuccess(result));
    } catch (error) {
        dispatch(createVipLinkFail(error));
        throw error;
    }
};
export const getVipLinkById = ({ id }) => async (dispatch) => {
    dispatch(getVipLinkRequest());
    try {
        const result = await VipLinkService.getVipLinkById({
            id,
        });
        dispatch(getVipLinkSuccess(result));
    } catch (error) {
        dispatch(getVipLinkFail(error));
        throw error;
    }
};
export const updateVipLink = ({ id, name, numbers, message, status, type }) => async (dispatch) => {
    dispatch(updateVipLinkRequest());
    try {
        const result = await VipLinkService.updateVipLink({
            id,
            name,
            numbers,
            message,
            status,
            type,
        });
        dispatch(updateVipLinkSuccess(result));
    } catch (error) {
        dispatch(updateVipLinkFail(error));
        throw error;
    }
};
export const deleteVipLinkById = ({ id }) => async (dispatch) => {
    dispatch(deleteVipLinkRequest());
    try {
        await VipLinkService.deleteVipLinkById({
            id,
        });
        dispatch(deleteVipLinkSuccess());
    } catch (error) {
        dispatch(deleteVipLinkFail(error));
        throw error;
    }
};
